export default {
    settingsKeyFob_add: "Create new Key Fob",
    settingsKeyFobs_createTitle:"Create new Key Fob",
    settingsKeyFobs_editTitle:"Edit Key Fob",
    settingsKeyFobs_columTitle:"Key Fob",
    settingsKeyFobs_columTitleDriver:"Driver ",
    settingsKeyFobs_minLengthMessage:'The field must contain minimum chars: ',
    settingsKeyFobs_maxLengthMessage:'The field must contain maximum chars: ',
    settingsKeyFobs_success:'Key Fob saved',
    settingsKeyFobs_deleteWarning:'Are you sure you want to delete the key Fob?',
    settingsKeyFobs_deleteTitle:'Delete Key Fob',
    settingsKeyFobs_deleted:'Key Fob deleted',
    settingsKeyFob_search:'Search by Key Fob and Driver',
    settingsKeyFobs_driverTitle:'Driver',
    settingsKeyFobs_infoBluetooth:'Geometris Bluetooth KeyFob IDs cannot be edited'
};