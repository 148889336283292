// Action types.
import { 
  ACCOUNT_SETTINGS_SIDERBAR,
  ACCOUNT_SETTINGS_SAVE,
  ACCOUNT_SETTINGS_SAVE_RESPONSE,
  GET_PREFERENCE,
  GET_PREFERENCE_RESULT
} from "Redux/actionTypes";

export const showAccountSettings = (data) => {
  return {
    type: ACCOUNT_SETTINGS_SIDERBAR,
    payload: data,
  };
};


export const saveAccountSettings = (data) => {
  return {
    type: ACCOUNT_SETTINGS_SAVE,
    payload: data,
  };
};

export const saveAccountSettingsResponse = (data) => {
  return {
    type: ACCOUNT_SETTINGS_SAVE_RESPONSE,
    payload: data,
  };
};

export const getPreference = (data) => {
  return {
    type: GET_PREFERENCE,
    payload: data,
  };
};

export const getPreferenceResult = (data) => {
  return {
    type: GET_PREFERENCE_RESULT,
    payload: data,
  };
};

