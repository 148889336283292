//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { clientGET } from 'Core/data/RestApi';
//Action Types
import {
	EDIT_UNIT_OPEN,
	EDIT_UNIT_UPDATE,
	EDIT_UNIT_SEND_OTA,
	EDIT_UNIT_SEND_PING,
	EDIT_UNIT_DECODE_VIN
} from 'Redux/actionTypes';

//Actions
import {
	unitEditUnitOpenResult,
	unitEditUpdateResult,
	sendOtaResult,
	sendPingResult,
	AlertsCreateSystemAlert,
	AlertsUpdateSystemAlert,
	decodeVinResponse,
	deleteAlerts,
	unitEditUnitClose
} from 'Redux/actions';
import { config } from 'react-transition-group';

export function* requesGetUnit() {
	yield takeEvery(EDIT_UNIT_OPEN, function* ({ payload }) {
		const { id } = payload;
		try {

			let data = yield call(
				clientQuery,
				`
				query {
					unit: getUnit (id: ${id}) {
						id
						label
						type
						hid
						esn
						imei
						driverId
						driverName
						unitVersionId
						deviceCategory
						odometerEnable
						hoursEnable
						tags {
							id
							label
							color
							parentId
							path
							hasChildren
						}
						lastReading {
							accountId
							deviceId
							unitId
							unitType
							deviceLabel
							driverId
							driverName
							make
							model
							vin
							year
							deviceNote
							unitTime
							serverTime
							LastMessageTime
							eventCode
							eventName
							heading
							latitude
							longitude
							address {
								address
								city
								formatted
								state
								zip
							}
							distance
							speed
							speedLimit
							speedLimitRequest
							engineHours
							engineOdometer
							satelliteCount
							datacenter
							messageSource
							region
							republish
							rowId
							sequenceNumber
							unitDiagnostic
							starterSupported
							starterDisabled
							temperatures
							landmark_id
							lm
							landmarkEntered
							landmarkgroup_id
							landmarkname
							landmarkExited
							jobsite_id
							jobsiteEntered
							jobsiteExited
							jobsitename
							dtc
							desiredStarterState
							desiredStarterUpdated 
							ecuEngineHours
							ecuEngineOdometer
							ecuVin
							ecuSpeed
						}
						locationId
						location {
							id
							title
							active
						}
						useEcuOdometer
						offsetEngineOdometer
						offsetEcuEngineOdometer
						useEcuEngineHours
						offsetEngineHours
						offsetEcuEngineHours
						useEcuSpeed
						timeZoneId
						timeZone {
							id
							name
						}
						useEcuVin
						inputs {
							unitId
							position
							labelId
							onLabel
							offLabel
						}
						notes
						offsetEngineOdometerUpdatedOn
						offsetEcuEngineOdometerUpdatedOn
						offsetEngineHoursUpdatedOn
						offsetEcuEngineHoursUpdatedOn
						supportedOutputs
						supportedInputs
						outputs {
							unitId
							position
							name
							type
							state
						}
						vehicleInformation {
							unitId
							vin
							make
							model
							year
							engine
							mpgHighway
							mpgCity
						}
						alertContacts {
							id
							title
							address
							type
						}
						vehicleStatusId
						vbus
						jbus
						manufacturerId
						tow
						lowBattery
						motion
						ttu
						driverBehavior
						unitVersionDriverBehavior
						consistentSpeed
						consistentDuration
						noGps
						noGpsTimes
						noGpsDays
						obd2alerts
						vbusConfiguration
						starter
					}

					vbus: getUnitVBusAlert(unitId: ${id}) {
						id
						unitId
						contacts {
							id
							title
							address
							type
						}
						dtc
						vehicleSpeed
						engineSpeed
						throttlePosition
						odometer
						fuelPercent
						fuelRemain
						transgear
						coolantTemp
						fuelRate
						batteryVoltage
						turnSignal
						tripOdometer
						tripFuel
						ignitionStatus
						milStatus
						airbagIndicator
						abs
						pto
						seatbelt
						brake
						absActiveLamp
						cruiseControl
						oilPressure
						brakeIndicator
						coolantLight
					}

					jbus: getUnitJBusAlert(unitId: ${id}) {
						id
						unitId
						contacts {
							id
							title
							address
							type
						}
						dtc
						batteryVoltage
						engineCrankCasePressure
						odometer
						rpm
						seatbeltSwitch
						hazard
						acceleratorPedalPosition
						brakePedalPosition
						brakeSystemSwitchStatus
						serviceBrakeSwitchStatus
						parkingBrakeSwitch
						engineCoolantLevel
						engineCoolantPressure
						engineCoolantTemp
						engineOilLevel
						engineOilPressure
						engineOilTemp
						redStopLampStatus
						amberWarningLamp
						protectLampStatus
						flashMalfunctionIndicatorLamp
						flashRedStopLamp
						flashAmberWarningLamp
						flashProtectLamp
						engineFuelRate
						fuelLevelOne
						fuelLevelTwo
					}
				}`,
				{
					id: id
				},
				endpoints.GRAPHQL_GENERAL,
			);

			if (data) {
				if(!data?.unit){
					//if no have data, dont show the modal
					yield put(unitEditUnitClose())
				}else{
					yield put(unitEditUnitOpenResult({
						...data.unit,
						vbusAlerts: data.vbus,
						jbusAlerts: data.jbus,
						driverBehaviorAlert: data.driverBehavior,
						towAlert: data.tow,
						motionAlert: data.motion,
						consistentSpeedAlert: data.consistentSpeed,
					}));
				}
			} else {
				yield put(unitEditUnitOpenResult());
			}
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/units/components/editUnit/redux/saga',
				'requesGetUnit',
			);
			yield put(unitEditUnitOpenResult());
		}
	});
}

export function* requesUpdateUnit() {
	yield takeEvery(EDIT_UNIT_UPDATE, function* ({ payload }) {
		try {
			const { unit, inputs, outputs, systemAlerts, systemAlertsToDelete } = payload;
			const {
				id, label, type, hid, esn, imei, driverId, unitVersionId, deviceCategory, locationId,
				useEcuOdometer, offsetEngineOdometer, offsetEcuEngineOdometer, useEcuEngineHours, offsetEngineHours,
				offsetEcuEngineHours, useEcuSpeed, timeZoneId, useEcuVin, mpgHighway, mpgCity, notes,
				offsetEngineOdometerUpdatedOn, offsetEcuEngineOdometerUpdatedOn, offsetEngineHoursUpdatedOn,
				offsetEcuEngineHoursUpdatedOn, vehicleInformation, vehicleStatusId,

				lowBattery, noGps, noGpsTimes, noGpsDays, consistentSpeed, tagIds, alertContacts,

				obd2alerts, vbus, vbusAlerts,
				jbus, jbusAlerts
			} = unit;

			let data = yield call(
				clientMutation,
				` 
				mutation updateUnit (
					$id: Int!, 
					$label: String, 
					$type: String, 
					$hid: String, 
					$esn: String, 
					$imei: String, 
					$driverId: Int, 
					$unitVersionId: Int, 
					$deviceCategory: enumDeviceCategory, 
					$locationId: Int, 
					$useEcuOdometer: Boolean!, 
					$offsetEngineOdometer: Float, 
					$offsetEcuEngineOdometer: Float, 
					$useEcuEngineHours: Boolean!, 
					$offsetEngineHours: Float, 
					$offsetEcuEngineHours: Float, 
					$useEcuSpeed: Boolean!, 
					$timeZoneId: Int, 
					$useEcuVin: Boolean!, 
					$notes: String, 
					$offsetEngineOdometerUpdatedOn: AWSDateTime, 
					$offsetEcuEngineOdometerUpdatedOn: AWSDateTime, 
					$offsetEngineHoursUpdatedOn: AWSDateTime, 
					$offsetEcuEngineHoursUpdatedOn: AWSDateTime, 
					$vehicleInformation: VehicleInformationInput,
					$vehicleStatusId: Int,
					$consistentSpeed: Int
					$consistentDuration: Int
					$lowBattery: Int
					$noGps: Int
					$noGpsTimes: Int
					$noGpsDays: Int 
					$tagIds: [Int]
					$alertContacts: [Int]
					$driverTransitionFrom: String
				) {
					updateUnit (
						id: $id, 
						label: $label, 
						type: $type, 
						hid: $hid, 
						esn: $esn, 
						imei: $imei, 
						driverId: $driverId, 
						unitVersionId: $unitVersionId, 
						deviceCategory: $deviceCategory, 
						locationId: $locationId, 
						useEcuOdometer: $useEcuOdometer, 
						offsetEngineOdometer: $offsetEngineOdometer, 
						offsetEcuEngineOdometer: $offsetEcuEngineOdometer, 
						useEcuEngineHours: $useEcuEngineHours, 
						offsetEngineHours: $offsetEngineHours, 
						offsetEcuEngineHours: $offsetEcuEngineHours, 
						useEcuSpeed: $useEcuSpeed, 
						timeZoneId: $timeZoneId, 
						useEcuVin: $useEcuVin, 
						notes: $notes, 
						offsetEngineOdometerUpdatedOn: $offsetEngineOdometerUpdatedOn, 
						offsetEcuEngineOdometerUpdatedOn: $offsetEcuEngineOdometerUpdatedOn, 
						offsetEngineHoursUpdatedOn: $offsetEngineHoursUpdatedOn, 
						offsetEcuEngineHoursUpdatedOn: $offsetEcuEngineHoursUpdatedOn, 
						vehicleInformation: $vehicleInformation,
						vehicleStatusId: $vehicleStatusId,
						consistentSpeed: $consistentSpeed,
						consistentDuration: $consistentDuration,
						lowBattery: $lowBattery,
						noGps: $noGps,
						noGpsTimes: $noGpsTimes,
						noGpsDays: $noGpsDays, 
						tagIds: $tagIds,
						alertContacts: $alertContacts,
						driverTransitionFrom:$driverTransitionFrom
					) {
						id
					}
				}`,
				{
					id,
					label,
					type,
					hid,
					esn,
					imei,
					driverId,
					unitVersionId,
					deviceCategory,
					locationId,
					useEcuOdometer,
					offsetEngineOdometer,
					offsetEcuEngineOdometer,
					useEcuEngineHours,
					offsetEngineHours,
					offsetEcuEngineHours,
					useEcuSpeed,
					timeZoneId,
					useEcuVin,
					mpgHighway,
					mpgCity,
					notes,
					offsetEngineOdometerUpdatedOn,
					offsetEcuEngineOdometerUpdatedOn,
					offsetEngineHoursUpdatedOn,
					offsetEcuEngineHoursUpdatedOn,
					vehicleInformation,
					tagIds,
					vehicleStatusId,
					consistentSpeed: consistentSpeed || 0,
					consistentDuration: consistentSpeed || 0,
					lowBattery: lowBattery ? 1 : 0,
					noGps: noGps ? 1 : 0,
					noGpsTimes: noGpsTimes || 0,
					noGpsDays: noGpsDays || 0,
					alertContacts: alertContacts?.length ? alertContacts : [],
					driverTransitionFrom:"CloudWebSite"
				},
				endpoints.GRAPHQL_GENERAL,
			);

			if (data && data.updateUnit) {
				for (let input of inputs?.d) {
					yield call(
						clientMutation,
						` mutation deleteUnitInput ($unitId: Int!, $position: Int!) {
							deleteUnitInput (unitId: $unitId, position: $position)
						}`,
						{
							unitId: input.unitId,
							position: input.position
						},
						endpoints.GRAPHQL_GENERAL,
					);
				}

				for (let input of inputs?.c) {
					yield call(
						clientMutation,
						` mutation createUnitInput ($unitId: Int!, $position: Int!, $labelId: Int!) {
							createUnitInput (unitId: $unitId, position: $position, labelId: $labelId) {
								unitId
							}
						}`,
						{
							unitId: input.unitId,
							position: input.position,
							labelId: input.labelId
						},
						endpoints.GRAPHQL_GENERAL,
					);
				}

				for (let input of inputs?.u) {
					yield call(
						clientMutation,
						` mutation updateUnitInput ($unitId: Int!, $position: Int!, $labelId: Int!) {
							updateUnitInput (unitId: $unitId, position: $position, labelId: $labelId) {
								unitId
							}
						}`,
						{
							unitId: input.unitId,
							position: input.position,
							labelId: input.labelId
						},
						endpoints.GRAPHQL_GENERAL,
					);
				}

				for (let output of outputs?.c) {
					yield call(
						clientMutation,
						` mutation createUnitOutput ($unitId: Int!, $position: Int!, $name: String!, $type: enumOutputType!, $state: enumOutputState!) {
							createUnitOutput (unitId: $unitId, position: $position, name: $name, type: $type, state: $state) {
								unitId
							}
						}`,
						{
							unitId: output.unitId,
							position: output.position,
							name: output.name,
							type: output.type,
							state: output.state
						},
						endpoints.GRAPHQL_GENERAL,
					);
				}

				for (let output of outputs?.u) {
					yield call(
						clientMutation,
						` mutation updateUnitOutput ($unitId: Int!, $position: Int!, $name: String!, $type: enumOutputType!, $state: enumOutputState!) {
							updateUnitOutput (unitId: $unitId, position: $position, name: $name, type: $type, state: $state) {
								unitId
							}
						}`,
						{
							unitId: output.unitId,
							position: output.position,
							name: output.name,
							type: output.type,
							state: output.state
						},
						endpoints.GRAPHQL_GENERAL,
					);
				}

				if (systemAlertsToDelete?.length) {
					yield put(deleteAlerts(systemAlertsToDelete, "", ""));
				}

				for (let systemAlert of systemAlerts) {
					if (systemAlert?.id) {
						yield put(AlertsUpdateSystemAlert(systemAlert));
					} else {
						yield put(AlertsCreateSystemAlert(systemAlert));
					}
				}

				if (obd2alerts && vbus) {
					yield call(
						clientMutation,
						` 
							mutation saveUnitVBusAlert(
								$unitId: Int!
								$contactIds: [Int]
								$dtc: Int
								$vehicleSpeed: String
								$engineSpeed: Int
								$throttlePosition: Int
								$odometer: Int
								$fuelPercent: Int
								$fuelRemain: String
								$transgear: String
								$coolantTemp: String
								$fuelRate: String
								$batteryVoltage: String
								$turnSignal: String
								$tripOdometer: Int
								$tripFuel: String
								$ignitionStatus: Int
								$milStatus: Int
								$airbagIndicator: Int
								$abs: Int
								$pto: Int
								$seatbelt: Float
								$brake: Int
								$absActiveLamp: Int
								$cruiseControl: Int
								$oilPressure: Int
								$brakeIndicator: Int
								$coolantLight: Int
							) {
								saveUnitVBusAlert(
									unitId: $unitId
									contactIds: $contactIds,
									dtc: $dtc,
									vehicleSpeed: $vehicleSpeed,
									engineSpeed: $engineSpeed,
									throttlePosition: $throttlePosition,
									odometer: $odometer,
									fuelPercent: $fuelPercent,
									fuelRemain: $fuelRemain,
									transgear: $transgear,
									coolantTemp: $coolantTemp,
									fuelRate: $fuelRate,
									batteryVoltage: $batteryVoltage,
									turnSignal: $turnSignal,
									tripOdometer: $tripOdometer,
									tripFuel: $tripFuel,
									ignitionStatus: $ignitionStatus,
									milStatus: $milStatus,
									airbagIndicator: $airbagIndicator,
									abs: $abs,
									pto: $pto,
									seatbelt: $seatbelt,
									brake: $brake,
									absActiveLamp: $absActiveLamp,
									cruiseControl: $cruiseControl,
									oilPressure: $oilPressure,
									brakeIndicator: $brakeIndicator,
									coolantLight: $coolantLight
								) {
									id
								}
							}
						`,
						{
							...vbusAlerts,
							unitId: id,
							contactIds: alertContacts || [],
							// checkbox
							dtc: vbusAlerts?.dtc ? 1 : 0,
							brakeIndicator: vbusAlerts?.brakeIndicator ? 1 : 0,
							milStatus: vbusAlerts?.milStatus ? 1 : 0,
							airbagIndicator: vbusAlerts?.airbagIndicator ? 1 : 0,
							abs: vbusAlerts?.abs ? 1 : 0,
							pto: vbusAlerts?.pto ? 1 : 0,
							coolantLight: vbusAlerts?.coolantLight ? 1 : 0,
							absActiveLamp: vbusAlerts?.absActiveLamp ? 1 : 0,
							cruiseControl: vbusAlerts?.cruiseControl ? 1 : 0,
							oilPressure: vbusAlerts?.oilPressure ? 1 : 0,
							// input / selector
							vehicleSpeed: ( vbusAlerts?.vehicleSpeed > 0 ? vbusAlerts.vehicleSpeed : null ),
							batteryVoltage: ( vbusAlerts?.batteryVoltage > 0 ? vbusAlerts.batteryVoltage : null ),
							coolantTemp: ( vbusAlerts?.coolantTemp > 0 ? vbusAlerts.coolantTemp : null ),
							engineSpeed: ( vbusAlerts?.engineSpeed > 0 ? vbusAlerts.engineSpeed : null ),
							fuelPercent: ( vbusAlerts?.fuelPercent > 0 ? vbusAlerts.fuelPercent : null ),
							fuelRemain: ( vbusAlerts?.fuelRemain > 0 ? vbusAlerts.fuelRemain : null ),
							tripFuel: ( vbusAlerts?.tripFuel > 0 ? vbusAlerts.tripFuel : null ),
							odometer: ( vbusAlerts?.odometer > 0 ? vbusAlerts.odometer : null ),
							fuelRate: ( vbusAlerts?.fuelRate > 0 ? vbusAlerts.fuelRate : null ),
							throttlePosition: ( vbusAlerts?.throttlePosition > 0 ? vbusAlerts.throttlePosition : null ),
							tripOdometer: ( vbusAlerts?.tripOdometer > 0 ? vbusAlerts.tripOdometer : null ),
							seatbelt: ( vbusAlerts?.seatbelt > 0 ? vbusAlerts.seatbelt : null ),
							// select on-off
							brake: ( vbusAlerts?.brake != undefined ? vbusAlerts.brake : null ),
							ignitionStatus: ( vbusAlerts?.ignitionStatus != undefined ? vbusAlerts.ignitionStatus : null )
						},
						endpoints.GRAPHQL_GENERAL,
					);
				}

				if (jbus) {
					yield call(
						clientMutation,
						` 
							mutation saveUnitJBusAlert(
								$unitId: Int!
								$contactIds: [Int]

								$dtc: Int
								$engineCrankCasePressure: Int
								$batteryVoltage: String
								$odometer: Int
								$rpm: Int
								$seatbeltSwitch: String
								$hazard: String
								$acceleratorPedalPosition: Int
								$brakePedalPosition: Int
								$brakeSystemSwitchStatus: String
								$serviceBrakeSwitchStatus: String
								$parkingBrakeSwitch: String
								$engineCoolantLevel: Int
								$engineCoolantPressure: Int
								$engineCoolantTemp: Int
								$engineOilLevel: Int
								$engineOilPressure: Int
								$engineOilTemp: Int
								
								$redStopLampStatus: Int
								$amberWarningLamp: Int
								$protectLampStatus: Int
								$flashMalfunctionIndicatorLamp: Int
								$flashRedStopLamp: Int
								$flashAmberWarningLamp: Int
								$flashProtectLamp: Int
								
								$engineFuelRate: String
								$fuelLevelOne: Int
								$fuelLevelTwo: Int
							) {
								saveUnitJBusAlert(
									unitId: $unitId
									contactIds: $contactIds,
									dtc: $dtc,
									engineCrankCasePressure: $engineCrankCasePressure,
									batteryVoltage: $batteryVoltage,
									odometer: $odometer,
									rpm: $rpm,
									seatbeltSwitch: $seatbeltSwitch,
									hazard: $hazard,
									acceleratorPedalPosition: $acceleratorPedalPosition,
									brakePedalPosition: $brakePedalPosition,
									brakeSystemSwitchStatus: $brakeSystemSwitchStatus,
									serviceBrakeSwitchStatus: $serviceBrakeSwitchStatus,
									parkingBrakeSwitch: $parkingBrakeSwitch,
									engineCoolantLevel: $engineCoolantLevel,
									engineCoolantPressure: $engineCoolantPressure,
									engineCoolantTemp: $engineCoolantTemp,
									engineOilLevel: $engineOilLevel,
									engineOilPressure: $engineOilPressure,
									engineOilTemp: $engineOilTemp,
									redStopLampStatus: $redStopLampStatus,
									amberWarningLamp: $amberWarningLamp,
									protectLampStatus: $protectLampStatus,
									flashMalfunctionIndicatorLamp: $flashMalfunctionIndicatorLamp,
									flashRedStopLamp: $flashRedStopLamp,
									flashAmberWarningLamp: $flashAmberWarningLamp,
									flashProtectLamp: $flashProtectLamp,
									
									engineFuelRate: $engineFuelRate,
									fuelLevelOne: $fuelLevelOne,
									fuelLevelTwo: $fuelLevelTwo
								) {
									id
								}
							}
						`,
						{
							...jbusAlerts,
							unitId: id,
							contactIds: alertContacts || [],
							dtc: jbusAlerts?.dtc ? 1 : 0,
							seatbeltSwitch: jbusAlerts?.seatbeltSwitch || "",
							hazard: jbusAlerts?.hazard || "",
							redStopLampStatus: jbusAlerts?.redStopLampStatus ? 1 : 0,
							amberWarningLamp: jbusAlerts?.amberWarningLamp ? 1 : 0,
							protectLampStatus: jbusAlerts?.protectLampStatus ? 1 : 0,
							flashMalfunctionIndicatorLamp: jbusAlerts?.flashMalfunctionIndicatorLamp ? 1 : 0,
							flashRedStopLamp: jbusAlerts?.flashRedStopLamp ? 1 : 0,
							flashAmberWarningLamp: jbusAlerts?.flashAmberWarningLamp ? 1 : 0,
							flashProtectLamp: jbusAlerts?.flashProtectLamp ? 1 : 0,
						},
						endpoints.GRAPHQL_GENERAL,
					);
				}

				yield put(unitEditUpdateResult(true));
			} else throw 'could not update unit';
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/units/components/editUnit/redux/saga',
				'requesUpdateUnit',
			);
			yield put(unitEditUpdateResult(false, exc));
		}
	});
}

export function* sendOtaRequest() {
	yield takeEvery(EDIT_UNIT_SEND_OTA, function* ({ payload }) {
		try {
			const response = yield call(
				clientMutation,
				` 
					mutation sendOta(
						$units: [UnitInfoInput]
        				$command: String!
						$noUserId: Boolean
					) {
						ota: sendOta(
							units: $units
							command: $command
							noUserId: $noUserId
						) {
							otaId
							command
							errorMessage
						}
					}
				`,
				{ ...payload, noUserId: true },
				endpoints.GRAPHQL_GENERAL,
			);

			if (response?.ota) {
				yield put(sendOtaResult({ ...response?.ota, error: false }));
			}
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/units/components/editUnit/redux/saga',
				'sendOtaRequest',
			);
			yield put(sendOtaResult({ error: true }));
		}
	});
}

export function* sendPingRequest() {
	yield takeEvery(EDIT_UNIT_SEND_PING, function* ({ payload }) {
		try {
			const response = yield call(
				clientMutation,
				` 
					mutation sendPingBySMS(
						$unitIds: [Int]!,
        				$message: String
					) {
						ping: sendPingBySMS(
							unitIds: $unitIds
							message: $message
						) {
							unitId,
							outId,
							errorMessage
						}
					}
				`,
				payload,
				endpoints.GRAPHQL_GENERAL,
			);

			if (response?.ping) {
				yield put(sendPingResult({ ...response?.ping, error: false }));
			}
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/units/components/editUnit/redux/saga',
				'sendPingRequest',
			);
			yield put(sendPingResult({ error: true }));
		}
	});
}

export function* decodeVinRequest() {
	yield takeEvery(EDIT_UNIT_DECODE_VIN, function* ({ payload }) {
		try {
			const { data } = payload;
			const url = `${endpoints.NHTSA_URL}/api/vehicles/decodevinvaluesextended/${data?.vin}?format=json`;
			const result = yield call(clientGET, '', {}, url, false);

			let vehicleInformation = null
			if (result && result?.Results && result?.Results?.length) {
				vehicleInformation = result?.Results[0];
			}

			if (vehicleInformation.ErrorCode != "0") {
				yield put(decodeVinResponse({ error: true }));
			} else {
				yield put(decodeVinResponse(vehicleInformation));
			}
		} catch (exc) {
			yield put(decodeVinResponse({ error: true }));
			ExceptionManager(
				exc,
				"modules/units/components/editUnit/redux/saga",
				"decodeVinRequest"
			);
		}
	});
}

//Saga Export
export default function* rootSaga() {
	yield all([
		fork(requesGetUnit),
		fork(requesUpdateUnit),
		fork(sendOtaRequest),
		fork(sendPingRequest),
		fork(decodeVinRequest)
	]);
}
