//View Keys
export default {
  alertsNewAlert_optionsTab: 'Opciones',
  alertsNewAlert_title: 'Nueva alerta',
  alertsNewAlert_EditTitle: 'Guardar alerta',
  alertsNewAlert_placeholderEnterLabel: 'Ingresar texto',
  alertsNewAlert_enterLabel: 'Titulo de la Alerta',
  alertsNewAlert_buttonBack: 'Atrás',
  alertsNewAlert_buttonNext: 'Siguiente',
  alertsNewAlert_triggersLabel: 'Activador',
  alertsNewAlert_minutesLabel: 'Minutos',
  alertsNewAlert_hoursLabel: 'Horas',
  alertsNewAlert_allowIdlingWhenPTO: "Permitir el ralentí cuando PTO/entradas están activadas",
  alertsNewAlert_impactDetectedLabel: 'Impacto Detectado',
  alertsNewAlert_panicButtonLabel: 'Botón de pánico',
  alertsNewAlert_searchLabel: "Búsqueda",
  alertsNewAlert_startTimeLabel: "Hora de inicio",
  alertsNewAlert_endTimeLabel: "Hora de finalización",
  alertsNewAlert_schedulingFrequencyTab: 'Programación y frecuencia',
  alertsNewAlert_applyModaysTimesToAll: 'Aplicar los horarios de los lunes a todos los días',
  alertsNewAlert_aletTriggerOptions: 'Opciones de disparo de alertas',
  alertsNewAlert_applyEveryEvent: 'Cada evento',
  alertsNewAlert_hourlySummary: 'Resumen por horas',
  alertsNewAlert_hourlySummary_message: "Esta alerta se activará inmediatamente para cualquier unidad asignada que informe de los eventos seleccionados durante las horas supervisadas. Todos los contactos recibirán el evento inicial y la ubicación, seguidos de una alerta de resumen enviada cada hora hasta que cese el comportamiento.",
  alertsNewAlert_alertAfter: 'Despues de la Alerta',
  alertsNewAlert_alertAfterConsecutive: '¿Enviar alerta despues de cuantos eventos consecutivos?',
  alertsNewAlert_totalDailyLimit: 'Colocar un límite diario total',
  alertsNewAlert_maxAlertPerDay: 'Maximas Alertas Por Dia',
  alertsNewAlert_alertAfter: 'Alerta despues',
  alertsNewAlert_receiveTheseAlertLabel: '¿Con qué frecuencia le gustaría recibir estas alertas?',
  alertsNewAlert_buttonCancel: 'Cancelar',
  alertsNewAlert_batteryVoltage: 'El voltaje de la batería es menor que',
  alertsNewAlert_coolantTempIs: 'La temperatura del refrigerante es',
  alertsNewAlert_engineSpeedIsGreaterThan: 'La velocidad del motor es mayor que',
  alertsNewAlert_fuelLevelIsLessThan: 'El nivel de combustible es menor que',
  alertsNewAlert_fuelRemainingIsLessThan: 'El combustible restante es menor que',
  alertsNewAlert_throttlePositionIsGreaterThan: 'La posición del acelerador es mayor que',
  alertsNewAlert_seatbeltFastenedAtSpeedsGreaterThan: 'Cinturón de seguridad abrochado a velocidades superiores a',
  alertsNewAlert_odometer: 'Odómetro',
  alertsNewAlert_triggerAlertWhen: 'Enviar alerta cuando:',
  alertsNewAlert_immediateLabel: 'De inmediato',
  alertsNewAlert_daysLabel: 'Días',
  alertsNewAlert_subscribersTab: 'Suscriptores',
  alertsNewAlert_drivingHoursExceededLabel: 'de conducción excedidas',
  alertsNewAlert_whoShouldReceiveTheseAlertsLabel: '¿Quién debería recibir estas alertas?',
  alertsNewAlert_selectPlaceholder: 'Seleccionar',
  alertsNewAlert_smsLabel: 'SMS',
  alertsNewAlert_emailLabel: 'Email',
  alertsNewAlert_webLabel: 'Web',
  alertsNewAlert_howWouldYouLikeToReceiveTheseAlertLabel: '¿Cómo le gustaría recibir estas alertas?',
  alertsNewAlert_selectAllContactsLabel: 'Seleccionar todos los contactos',
  alertsNewAlert_everydayLabel: 'Todos los días',
  alertsNewAlert_weekdaysLabel: 'Días entre semana',
  alertsNewAlert_weekendsLabel: 'Fines de semana',
  alertsNewAlert_customLabel: 'Personalizado',
  alertsNewAlert_whenShouldThisAlertTriggerLabel: 'Cuando deberia ser lanzada la alerta?',
  alertsNewAlert_assignUnitsTab: 'Asignar unidades',
  alertsNewAlert_selectUnitsOrDrivers: 'Seleccionar unidades o conductores',
  alertsNewAlert_selectUnits: 'Seleccionar unidades',
  alertsNewAlert_all: 'Todo',
  alertsNewAlert_units: 'Unidades',
  alertsNewAlert_unitGroup: 'Grupos de unidades',
  alertsNewAlert_drivers: 'Conductores',
  alertsNewAlert_driverGroup: 'Grupos de conductores',
  alertsNewAlert_singleUnits: 'Unidades individuales',
  alertsNewAlert_addNewButton: 'Agregar nuevo',
  alertsNewAlert_editButton: 'Guardar',
  alertsNewAlert_minutes: 'Minutos',
  alertsNewAlert_enter: 'Entra',
  alertsNewAlert_exit: 'Sale',
  alertsNewAlert_both: 'Ambos',
  alertsNewAlert_insideDuration: 'Duracion dentro',
  alertsNewAlert_immediate: 'De inmediato',
  alertsNewAlert_hours: 'Horas',
  alertsNewAlert_days: 'Dias',
  alertsNewAlert_recurring: 'Recurrente',
  alertsNewAlert_onlyOnce: 'Sólo una vez',
  alertsNewAlert_vehicleSpeed: 'Velocidad del vehículo',
  alertsNewAlert_postedSpeed: 'Velocidad superior a la indicada (sobrepasar el límite de velocidad indicado por)',
  alertsNewAlert_postedSpeedOem: 'OEM Velocidad',
  alertsNewAlert_postedSpeed_operator_And: "y en",
  alertsNewAlert_postedSpeed_operator_Or: "o en",
  alertsNewAlert_whileWhithinALandmark: 'Mientras está dentro de un punto de referencia',
  alertsNewAlert_createdMessage: 'Alerta creada',
  alertsNewAlert_editedMessage: 'Alerta Editada',
  alertsNewAlert_titleErrorMessage: 'Error',
  alertsNewAlert_ErrorMessage: 'Hubo un error creando la alerta.',
  alertsNewAlert_LowTitle: 'Baja',
  alertsNewAlert_MediumTitle: 'Media',
  alertsNewAlert_HighTitle: 'Alta',
  alertsNewAlert_AllTitle: 'Todos',
  alertsNewAlert_SetSeverity: 'Seleccionar la importancia de la alerta',
  alertsNewAlert_DriveTimeValidation: 'la hora de finalización debe ser posterior a la hora de inicio',
  alertsNewAlert_SpeedUnitMph: "MPH",
  alertsNewAlert_Parameters: "Parametros",
  alertsNewAlert_Over: "Sobre",
  alertsNewAlert_SelectEventValidation: "Por favor ingrese un evento",
  alertsNewAlert_MediaTriggersValidation: 'Debes seleccionar al menos un activador',
  alertsNewAlert_TotalDriverTimeTriggersValidation: 'La hora de inicio no debe ser mayor que la hora de finalización',
  alertsNewAlert_Selected: "Seleccionados",
  alertsNewAlert_SelectLandmarks: "Seleccionar puntos de referencia",
  alert_numberOfUnitsAllowedLandmarkLabel: "Número de unidades permitidas en el mismo punto de referencia",
  alertsNewAlert_unitsValidation: "Por favor seleccione al menos una unidad",
  alertsNewAlert_driversValidation: "Por favor seleccione al menos un driver",
  alertsNewAlert_itemValidation: "Por favor seleccione al menos un Item",
  alertsNewAlert_triggerValidation: "Por favor, seleccione uno o más activadores",
  alertsNewAlert_triggerValidationOem: "Activadores OEM",
  alertsNewAlert_landmarkValidation: "Seleccionar 1 o más puntos de referencia",
  alertsNewAlert_timeValidation: "La hora de inicio debe ser mayor que la hora de finalización",
  alertsNewAlert_expandButtonLabel: "Mostrar todo",
  alertsNewAlert_collapseButtonLabel: "Colapsar",
  alertsNewAlert_generalTab: 'General',
  alertsNewAlert_customScheduleValidation: "Por favor, seleccione al menos un día del horario",
  alertsNewAlert_unassignedDriver: 'Conductor no asignado',

  alertsNewAlert_M: 'L',
  alertsNewAlert_T: 'M',
  alertsNewAlert_W: 'Mi',
  alertsNewAlert_Th: 'J',
  alertsNewAlert_F: 'V',
  alertsNewAlert_S: 'S',
  alertsNewAlert_Su: 'D',

  alertsNewAlert_warning: 'Advertencia!',
  alertsNewAlert_noRangeSelected: 'Es necesario seleccionar una frecuencia de tiempo para esta alerta en el paso de programacion y frecuencia.',


  // Driver behavior Alert
  alertsNewAlert_SelectEvents: "Seleccionar eventos que activarán la alerta",
  alertsNewAlert_EventValidation: "Seleccione uno o mas eventos",




  // Fuel Card Alert
  alertsNewAlert_Event: "Evento",
  alertsNewAlert_Overfill: "Overfill",
  alertsNewAlert_NotAtLocation: "Not At Location",
  alertsNewAlert_SelectPlaceholder: "Seleccionar",

  // Driver Behavior
  alertsNewAlert_SuddenStop: "Parada repentina",
  alertsNewAlert_PotentialIncident: "Potencial Incidente",
  alertsNewAlert_HardStop: "Parada brusca",
  alertsNewAlert_HardTurn: "Giro brusco",
  alertsNewAlert_RapidAcceleration: "Aceleración rápida",

  // Off Hour
  alertsNewAlert_Ignition: "Encendido",
  alertsNewAlert_Input: "Input",
  alertsNewAlert_Geofence: "Geocerca",
  alertsNewAlert_Landmark: "Puntos de referencia",
  alertsNewAlert_OverlappingDatesErrorMessage: "Por favor comprueba que los tiempos no se superpongan",
  alertsNewAlert_HoursCompletationValidation: "Por favor complete los rangos de horas seleccionando la hora final mayor que la inicial",
  alertsNewAlert_RequiredAtLeastADay: "Por favor selecciona al menos un rango.",

  // On Event
  alertsNewAlert_MainPowerOff: "Apagado principal",
  alertsNewAlert_MainPowerOn: "Encendido principal",
  alertsNewAlert_MainPowerDisconnect: "Desconectado principal",
  alertsNewAlert_AntennaDisconnect: "Desconexión de antena",
  alertsNewAlert_AntennaConnect: "Conexión de antena",
  alertsNewAlert_InternalBatteryDisconnect: "Desconexión de batería interna",
  alertsNewAlert_InternalBatteryConnect: "Conexión de batería interna",
  alertsNewAlert_Input1: "Entrada 1",
  alertsNewAlert_Input2: "Entrada 2",
  alertsNewAlert_Input3: "Entrada 3",
  alertsNewAlert_Input4: "Entrada 4",
  alertsNewAlert_InputLabel: "Entrada",
  alertsNewAlert_State: "Estado",
  alertsNewAlert_On: "Encendido",
  alertsNewAlert_Off: "Apagado",
  alertsNewAlert_Driving: "Esta conduciendo",
  alertsNewAlert_NotDriving: "no conducir",
  alertsNewAlert_Both: "Ambos",
  alertsNewAlert_ValidationInputEvent: "Por favor seleccione al menos una entrada.",
  //new
  alertsNewAlert_Update: "Actualización",
  alertsNewAlert_IgnitionOn: "Encendido conectado",
  alertsNewAlert_IgnitionOff: "Encendido apagado",
  alertsNewAlert_Ping: "Ping",
  alertsNewAlert_Motion: "Movimiento",
  alertsNewAlert_Drive: "Conducir",
  alertsNewAlert_LowBattery: "Batería baja",
  alertsNewAlert_TamperSensor: "Sensor de manipulación",
  alertsNewAlert_UserUpdate: "Actualización del usuario",
  alertsNewAlert_LightSensor: "Sensor de luz",
  // Alerts summaries
  alertsNewAlert_ConsistentSpeedSummary: "Sostiene {speed} {units} durante 4 min",
  alertsNewAlert_StoppedReportingOverSummary: "Sobre",
  alertsNewAlert_SpeedSummaryInsideLandmark: "Dentro de puntos de referencia",
  alertsNewAlert_OnEventSummaryEventWithInputs: "Entradas seleccionadas: {inputs}",
  alertsNewAlert_StopSummary: "Activador: {trigger}",
  alertsNewAlert_StopReportingSummary: "Activador: {trigger} - Camara Activador:{cameraTrigger}",
  alertsNewAlert_MediaSummary: "Se activa cuando: {trigger}",
  alertsNewAlert_MediaSummaryBothTriggers: "{triggerOne} o {triggerTwo}",
  alertsNewAlert_IdleSummary: "Activador: {trigger}{IdlingAllowed}",
  alertsNewAlert_IdleSummaryAllowedIdling: ", Se permite el ralentí cuando PTO/entradas están activadas",
  alertsNewAlert_IdleSummaryNotAllowedIdling: ", NO se permite el ralentí cuando PTO/entradas están ENCENDIDAS",
  alertsNewAlert_TotalDriveTimeSummary: "Desde {startTime} hasta {endTime}, {drivingHoursExceeded} horas de conducción excedidas.",
  alertsNewAlert_LandmarksSummary: "{landmarkEntity} Seleccionado(s): {landmarks}, Activador {trigger}",
  alertsNewAlert_LandmarksSummaryNoLandmarks: "No hay puntos de referencia seleccionados",
  alertsNewAlert_SpeedValidation: 'La velocidad del vehiculo es requerida',
  alertsNewAlert_SpeedTriggersValidation: 'Por favor selecciona al menos un punto de referencia',
  alertsNewAlert_VehicleCongregationAllowed: "{allowedUnits} unidades",
  alertsNewAlert_VehicleCongregationLandmark: "permitidas en {landmarkEntityTitle} {landmarks}",
  alertsNewAlert_LandmarksSummaryAllLandmarks: "Todos los puntos de referencia (Landmarks) seleccionados.",
  alertsNewAlert_SpeedSummarySpeedMessage: "Velocidad sobre {speed} {speedMeasure}",
  alertsNewAlert_SpeedSummarySpeedMessageAnd: "y",
  alertsNewAlert_SpeedSummarySpeedMessageOr: "o",
  alertsNewAlert_SpeedSummaryPostedSpeedMessage: "velocidad publicada sobre {postedSpeed} {speedMeasure}",
  alertsNewAlert_LandmarkGroup: "Puntos de referencia",
  alertsNewAlert_Landmark_name: "Punto de Referencia",
  alertsNewAlert_Landmark_all: "Todos",
  alertsNewAlert_GeofencesSummaryNoLandmarks: "No hay geocercas seleccionadas",
  alertsNewAlert_GeofencesSummaryAllLandmarks: "Todas las geocercas seleccionadas",
  alertsNewAlert_Geofence: "Geocerca",
  alertsNewAlert_Tag: "Tag",
  alertsNewAlert_GeofencesSummary: "Selected {geofenceEntity}(s): {geofences}, Trigger {trigger}",

  // Alerts Landmarks
  alertsNewAlert_landmarkTriggersValidation: 'Seleccione al menos un activador de referencia',
  alertsNewAlert_landmarkValidation: 'Por favor selecciona al menos un punto de referencia',

  // Camera Event
  alertsNewAlert_crash: 'Colisión',
  alertsNewAlert_hardAccel: 'Aceleración brusca',
  alertsNewAlert_hardBrake: 'Freno brusco',
  alertsNewAlert_hardTurn: 'Giro brusco',
  alertsNewAlert_noDriver: 'Cámara obstruida',
  alertsNewAlert_asleep: 'Dormido',
  alertsNewAlert_drowsy: 'Adormecido',
  alertsNewAlert_yawn: 'Bostezo',
  alertsNewAlert_dayDreaming: 'Soñar despierto',
  alertsNewAlert_usingPhone: 'Usando el teléfono',
  alertsNewAlert_distracted: 'Distraído',
  alertsNewAlert_smoking: 'Fumando',
  alertsNewAlert_noSeatbelt: 'Sin cinturón de seguridad',
  alertsNewAlert_manual: 'Manual',
  alertsNewAlert_collisionWarning: 'Aviso de colisión',
  alertsNewAlert_closeFollowing: 'Aviso de proximidad',
  alertsNewAlert_tailgating: 'Emergencia de Proximidad',
  alertsNewAlert_powerOff: 'Apagado',
  alertsNewAlert_stopSignViolation: 'Violación de Señal de Pare',

  //events for new alert
  alertsNewAlert_CameraEvent: 'Evento de cámara',
  alertsNewAlert_OnEvent: 'En Evento',
  alertsNewAlert_ConsecutiveDriving: 'Conducción Consecutiva',
  alertsNewAlert_ConsistentSpeed: 'Velocidad Constante',
  alertsNewAlert_DriverBehavior: 'Comportamiento del Controlador',
  alertsNewAlert_FuelCard: 'Tarjeta de Combustible',
  alertsNewAlert_HeavyDutyDTC: 'DTC de servicio pesado',
  alertsNewAlert_Idle: 'Inactivo',
  alertsNewAlert_LightDutyDTC: 'DTC de servicio ligero',
  alertsNewAlert_OBDII: 'OBDII',
  alertsNewAlert_Media: 'Medios',
  alertsNewAlert_OffHour: 'Fuera de hora',
  alertsNewAlert_SmartOneCLowBattery: 'Batería baja',
  alertsNewAlert_Speed: 'Velocidad',
  alertsNewAlert_Stationary: 'Estacionario',
  alertsNewAlert_Stop: 'Detener',
  alertsNewAlert_StoppedReporting: 'Informes detenidos',
  alertsNewAlert_TotalDriveTime: 'Tiempo total de conducción',
  alertsNewAlert_Tow: 'Remolque',
  alertsNewAlert_UnassignedDriver: 'Conductor no asignado',
  alertsNewAlert_VehicleCongregation: 'Congregación de vehículos',
  alertsNewAlert_FSMMealBreak: 'FSM Descanso para comer',
  alertsNewAlert_OBDIIAlerts: 'OBDII Alertas',
  alertsNewAlert_OnLandmark: 'en punto de referencia',
  alertsNewAlert_OnLandmarkEnter: 'En punto de referencia Entrar',
  alertsNewAlert_OnLandmarkExit: 'En la salida de referencia',
  alertsNewAlert_stop: 'en parada',
  alertsNewAlert_OverPostedSpeed: 'Sobrevelocidad publicada',
  alertsNewAlert_currentDriver: 'Conductor actual',

  alertsNewAlert_tab_unit_units: "Unidades",
  alertsNewAlert_tab_unit_drivers: "Conductores",
  alertsNewAlert_tab_unit_all: "Todos",

  alertsNewAlert_notifyAt: "Notificar",
  alertsNewAlert_breakDuration: "Duración del descanso",
};
