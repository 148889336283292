export default {
    settingsCameraManage_title: "Camera Management",
    settingsCameraManage_recordId: "Record Id",
    settingsCameraManage_imei: "IMEI",
    settingsCameraManage_esn: "ESN",
    settingsCameraManage_pairedUnit: "Paired Unit",
    settingsCameraManage_label: "Label",
    settingsCameraManage_unpaired: "Unpaired",
    settingsCameraManage_editCameraPairing: "Edit Camera Pairing",
    settingsCameraManage_showUnpairedOnly: "Show unpaired only",
    settingsCameraManage_editCamera: "Edit",
};