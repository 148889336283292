//View Keys
export default {
  alertsNewAlert_optionsTab: 'Options',
  alertsNewAlert_title: 'New Alert',
  alertsNewAlert_EditTitle: 'Save Alert',
  alertsNewAlert_placeholderEnterLabel: 'Enter Label',
  alertsNewAlert_enterLabel: 'Alert Label',
  alertsNewAlert_buttonBack: 'Back',
  alertsNewAlert_buttonNext: 'Next',
  alertsNewAlert_triggersLabel: 'Triggers',
  alertsNewAlert_minutesLabel: 'Minutes',
  alertsNewAlert_hoursLabel: 'Hours',
  alertsNewAlert_allowIdlingWhenPTO: "Allow idling when PTO/inputs are ON",
  alertsNewAlert_impactDetectedLabel: 'Impact Detected',
  alertsNewAlert_panicButtonLabel: 'Panic Button',
  alertsNewAlert_searchLabel: "Search",
  alertsNewAlert_startTimeLabel: "Start Time",
  alertsNewAlert_endTimeLabel: "End Time",
  alertsNewAlert_schedulingFrequencyTab: 'Scheduling & Frequency',
  alertsNewAlert_applyModaysTimesToAll: 'Apply Monday\'s times to all',
  alertsNewAlert_aletTriggerOptions: 'Alert Trigger Options',
  alertsNewAlert_applyEveryEvent: 'Every Event',
  alertsNewAlert_hourlySummary: 'Hourly Summary',
  alertsNewAlert_hourlySummary_message: "This alert will trigger immediately for any assigned unit that reports the selected events during the monitored hours. All contacts will receive the initial event and location followed by a summary alert sent every hour until the behavior stops.",
  alertsNewAlert_alertAfter: 'Alert After',
  alertsNewAlert_alertAfterConsecutive: 'Send alert after how many consecutive events?',
  alertsNewAlert_totalDailyLimit: 'Set a total daily limit?',
  alertsNewAlert_maxAlertPerDay: 'Max Alert Per Day',
  alertsNewAlert_receiveTheseAlertLabel: 'How often would you like to receive these alerts?',
  alertsNewAlert_buttonCancel: 'Cancel',
  alertsNewAlert_batteryVoltage: 'Battery voltage is less than',
  alertsNewAlert_coolantTempIs: 'Coolant Temp is',
  alertsNewAlert_engineSpeedIsGreaterThan: 'Engine speed is greater than',
  alertsNewAlert_fuelLevelIsLessThan: 'Fuel level is less than',
  alertsNewAlert_fuelRemainingIsLessThan: 'Fuel remaining is less than',
  alertsNewAlert_throttlePositionIsGreaterThan: 'Throttle position is greater than',
  alertsNewAlert_seatbeltFastenedAtSpeedsGreaterThan: 'Seatbelt fastened at speeds greater than',
  alertsNewAlert_odometer: 'Odometer',
  alertsNewAlert_triggerAlertWhen: 'Trigger alert when:',
  alertsNewAlert_immediateLabel: 'Immediate',
  alertsNewAlert_daysLabel: 'Days',
  alertsNewAlert_subscribersTab: 'Subscribers',
  alertsNewAlert_drivingHoursExceededLabel: 'Driving exceeded',
  alertsNewAlert_whoShouldReceiveTheseAlertsLabel: 'Who should receive these alerts',
  alertsNewAlert_selectPlaceholder: 'Select',
  alertsNewAlert_smsLabel: 'SMS',
  alertsNewAlert_emailLabel: 'Email',
  alertsNewAlert_webLabel: 'Web',
  alertsNewAlert_howWouldYouLikeToReceiveTheseAlertLabel: 'How would you like to receive these alerts?',
  alertsNewAlert_selectAllContactsLabel: 'Select All Contacts',
  alertsNewAlert_everydayLabel: 'Everyday',
  alertsNewAlert_weekdaysLabel: 'Weekdays',
  alertsNewAlert_weekendsLabel: 'Weekends',
  alertsNewAlert_customLabel: 'Custom',
  alertsNewAlert_whenShouldThisAlertTriggerLabel: 'When should this alert trigger?',
  alertsNewAlert_assignUnitsTab: 'Assign Units',
  alertsNewAlert_selectUnitsOrDrivers: 'Select Units or Drivers',
  alertsNewAlert_selectUnits: 'Select Units',
  alertsNewAlert_all: 'All',
  alertsNewAlert_units: 'Units',
  alertsNewAlert_unitGroup: 'Unit Groups',
  alertsNewAlert_drivers: 'Drivers',
  alertsNewAlert_driverGroup: 'Driver Groups',
  alertsNewAlert_singleUnits: 'Single Units',
  alertsNewAlert_addNewButton: 'Add New',
  alertsNewAlert_editButton: 'Save',
  alertsNewAlert_minutes: 'Minutes',
  alertsNewAlert_enter: 'Enter',
  alertsNewAlert_exit: 'Exit',
  alertsNewAlert_both: 'Both',
  alertsNewAlert_insideDuration: 'Inside Duration',
  alertsNewAlert_immediate: 'Immediate',
  alertsNewAlert_hours: 'Hours',
  alertsNewAlert_days: 'Days',
  alertsNewAlert_recurring: 'Recurring',
  alertsNewAlert_onlyOnce: 'Only Once',
  alertsNewAlert_vehicleSpeed: 'Vehicle Speed',
  alertsNewAlert_postedSpeed: 'Over Posted Speed (over posted speed limit by)',
  alertsNewAlert_postedSpeedOem: 'OEM Speeding',
  alertsNewAlert_postedSpeed_operator_And: "AND",
  alertsNewAlert_postedSpeed_operator_Or: "OR",
  alertsNewAlert_whileWhithinALandmark: 'While within a landmark',
  alertsNewAlert_createdMessage: 'Alert Created',
  alertsNewAlert_editedMessage: 'Alert Edited',
  alertsNewAlert_titleErrorMessage: 'Error',
  alertsNewAlert_ErrorMessage: 'Could not create the alert',
  alertsNewAlert_LowTitle: 'Low',
  alertsNewAlert_MediumTitle: 'Medium',
  alertsNewAlert_HighTitle: 'High',
  alertsNewAlert_AllTitle: 'ALL',
  alertsNewAlert_SetSeverity: 'Set Alert Severity',
  alertsNewAlert_SpeedValidation: 'Vehicle speed trigger required',
  alertsNewAlert_DriveTimeValidation: 'end time must be after start time',
  alertsNewAlert_SpeedTriggersValidation: 'Please select at least one landmark trigger',
  alertsNewAlert_SpeedUnitMph: "MPH",
  alertsNewAlert_Parameters: "Parameters",
  alertsNewAlert_Over: "Over",
  alertsNewAlert_SelectEventValidation: "Please select an event",
  alertsNewAlert_MediaTriggersValidation: 'Must Select at least one trigger',
  alertsNewAlert_TotalDriverTimeTriggersValidation: 'The start time should not be greater than the end time',
  alertsNewAlert_Selected: "Selected",
  alertsNewAlert_SelectLandmarks: "Select Landmark(s)",
  alert_numberOfUnitsAllowedLandmarkLabel: "Number of units allowed in the same landmark",
  alertsNewAlert_unitsValidation: "Please select at least a unit",
  alertsNewAlert_driversValidation: "Please select at least a driver",
  alertsNewAlert_itemValidation: "Please select at least a item",
  alertsNewAlert_triggerValidation: "Please select one or more triggers",
  alertsNewAlert_triggerValidationOem: "Triggers OEM",
  alertsNewAlert_landmarkValidation: "Select 1 or more Landmarks",
  alertsNewAlert_timeValidation: "Start Time must be greater than End Time",
  alertsNewAlert_expandButtonLabel: "Show All",
  alertsNewAlert_collapseButtonLabel: "Collapse",
  alertsNewAlert_generalTab: 'General',
  alertsNewAlert_customScheduleValidation: "Please select at least a schedule day",
  alertsNewAlert_unassignedDriver: 'Unassigned Driver',
  alertsNewAlert_M: 'M',
  alertsNewAlert_T: 'T',
  alertsNewAlert_W: 'W',
  alertsNewAlert_Th: 'Th',
  alertsNewAlert_F: 'F',
  alertsNewAlert_S: 'S',
  alertsNewAlert_Su: 'Su',

  alertsNewAlert_warning: 'Warning!',
  alertsNewAlert_noRangeSelected: 'It is necessary to select a time frequency for this alert in the scheduling & frequency step',
  



  // Driver behavior Alert
  alertsNewAlert_SelectEvents: "Select events that will trigger the alert",
  alertsNewAlert_EventValidation: "Please select one or more events",


  // Fuel Card Alert
  alertsNewAlert_Event: "Event",
  alertsNewAlert_Overfill: "Overfill",
  alertsNewAlert_NotAtLocation: "Not At Location",
  alertsNewAlert_SelectPlaceholder: "Select",

  // Driver Behavior
  alertsNewAlert_SuddenStop: "Sudden Stop",
  alertsNewAlert_PotentialIncident: "Potential Incident",
  alertsNewAlert_HardStop: "Hard Stop",
  alertsNewAlert_HardTurn: "Hard Turn",
  alertsNewAlert_RapidAcceleration: "Rapid Acceleration",

  // Off Hour
  alertsNewAlert_Ignition: "Ignition",
  alertsNewAlert_Drive: "Drive",
  alertsNewAlert_Input: "Input",
  alertsNewAlert_Geofence: "Geofence",
  alertsNewAlert_Landmark: "Landmark",
  alertsNewAlert_OverlappingDatesErrorMessage: "Please check, the times shouldn't be overlapped",
  alertsNewAlert_HoursCompletationValidation: "Please complete the ranges of hours by selecting the final time greater than the initial one.",
  alertsNewAlert_RequiredAtLeastADay: "Please select at least one range.",

  // On Event
  alertsNewAlert_MainPowerOff: "Main Power Off",
  alertsNewAlert_MainPowerOn: "Main Power On",
  alertsNewAlert_MainPowerDisconnect: "Main Power Disconnect",
  alertsNewAlert_AntennaDisconnect: "Antenna Disconnect",
  alertsNewAlert_AntennaConnect: "Antenna Connect",
  alertsNewAlert_InternalBatteryDisconnect: "Internal Battery Disconnect",
  alertsNewAlert_InternalBatteryConnect: "Internal Battery Connect",
  alertsNewAlert_Input1: "Input 1",
  alertsNewAlert_Input2: "Input 2",
  alertsNewAlert_Input3: "Input 3",
  alertsNewAlert_Input4: "Input 4",
  alertsNewAlert_InputLabel: "Input",
  alertsNewAlert_State: "State",
  alertsNewAlert_On: "On",
  alertsNewAlert_Off: "Off",
  alertsNewAlert_Driving: "Is Driving",
  alertsNewAlert_NotDriving: "Not Driving",
  alertsNewAlert_Both: "Both",
  alertsNewAlert_ValidationInputEvent: "Please select at least an Input.",
  //new
  alertsNewAlert_Update: "Update",
  alertsNewAlert_IgnitionOn: "Ignition On",
  alertsNewAlert_IgnitionOff: "Ignition Off",
  alertsNewAlert_Ping: "Ping",
  alertsNewAlert_Motion: "Motion",
  alertsNewAlert_LowBattery: "Low Battery",
  alertsNewAlert_TamperSensor: "Tamper Sensor",
  alertsNewAlert_UserUpdate: "User Update",
  alertsNewAlert_LightSensor: "Light Sensor",

  // Alerts summaries
  alertsNewAlert_ConsistentSpeedSummary: "Holds {speed} {units} for 4 min",
  alertsNewAlert_StoppedReportingOverSummary: "Over",
  alertsNewAlert_SpeedSummarySpeedMessage: "Speed over {speed} {speedMeasure}",
  alertsNewAlert_SpeedSummarySpeedMessageAnd: "and",
  alertsNewAlert_SpeedSummarySpeedMessageOr: "or",
  alertsNewAlert_SpeedSummaryPostedSpeedMessage: "Posted Speed over {postedSpeed} {speedMeasure}",
  alertsNewAlert_SpeedSummaryInsideLandmark: "Inside landmark(s)",
  alertsNewAlert_OnEventSummaryEventWithInputs: "Selected Inputs: {inputs}",
  alertsNewAlert_StopSummary: "Trigger: {trigger}",
  alertsNewAlert_StopReportingSummary: "Trigger: {trigger} - Camera Trigger:{cameraTrigger}",
  alertsNewAlert_VehicleCongregationAllowed: "{allowedUnits} unit(s)",
  alertsNewAlert_VehicleCongregationLandmark: "allowed in {landmarkEntityTitle} {landmarks}",
  alertsNewAlert_MediaSummary: "Trigger when: {trigger}",
  alertsNewAlert_MediaSummaryBothTriggers: "{triggerOne} or {triggerTwo}",
  alertsNewAlert_IdleSummary: "Trigger: {trigger}{IdlingAllowed}",
  alertsNewAlert_IdleSummaryAllowedIdling: ", Allowed idling when PTO/inputs are ON",
  alertsNewAlert_IdleSummaryNotAllowedIdling: ", NOT allowed idling when PTO/inputs are ON",
  alertsNewAlert_TotalDriveTimeSummary: "From {startTime} to {endTime}, {drivingHoursExceeded} driving exceeded hours.",
  alertsNewAlert_LandmarksSummary: "Selected {landmarkEntity}(s): {landmarks}, Trigger {trigger}",
  alertsNewAlert_LandmarksSummaryNoLandmarks: "No landmarks selected",
  alertsNewAlert_LandmarksSummaryAllLandmarks: "All landmarks selected",
  alertsNewAlert_LandmarkGroup: "Landmark group",
  alertsNewAlert_Landmark_name: "Landmark",
  alertsNewAlert_Landmark_all: "All",
  alertsNewAlert_GeofencesSummaryNoLandmarks: "No Geofences selected",
  alertsNewAlert_GeofencesSummaryAllLandmarks: "All Geofences selected",
  alertsNewAlert_Geofence: "Geofence",
  alertsNewAlert_Tag: "Tag",
  alertsNewAlert_GeofencesSummary: "Selected {geofenceEntity}(s): {geofences}, Trigger {trigger}",

  // Alerts Landmarks
  alertsNewAlert_landmarkTriggersValidation: 'Please select at least one landmark trigger',
  alertsNewAlert_landmarkValidation: 'Please select at least one landmark',

  // Camera Event
  alertsNewAlert_crash: 'Crash',
  alertsNewAlert_hardAccel: 'Hard Acceleration',
  alertsNewAlert_hardBrake: 'Hard Brake',
  alertsNewAlert_hardTurn: 'Hard Turn',
  alertsNewAlert_noDriver: 'Camera Obstructed',
  alertsNewAlert_asleep: 'Asleep',
  alertsNewAlert_drowsy: 'Drowsy',
  alertsNewAlert_yawn: 'Yawn',
  alertsNewAlert_dayDreaming: 'Day Dreaming',
  alertsNewAlert_usingPhone: 'Using Phone',
  alertsNewAlert_distracted: 'Distracted',
  alertsNewAlert_smoking: 'Smoking',
  alertsNewAlert_noSeatbelt: 'No Seatbelt',
  alertsNewAlert_manual: 'Manual',
  alertsNewAlert_collisionWarning: 'Collision Warning',
  alertsNewAlert_closeFollowing: 'Close Following',
  alertsNewAlert_tailgating: 'Tailgating',
  alertsNewAlert_powerOff: 'Power Off',
  alertsNewAlert_stopSignViolation: 'Stop Sign Violation',

  //events for new alert
  alertsNewAlert_CameraEvent: 'Camera Event',
  alertsNewAlert_OnEvent: 'On Event',
  alertsNewAlert_ConsecutiveDriving: 'Consecutive Driving',
  alertsNewAlert_ConsistentSpeed: 'Consistent Speed',
  alertsNewAlert_DriverBehavior: 'Driver Behavior',
  alertsNewAlert_FuelCard: 'Fuel Card',
  alertsNewAlert_HeavyDutyDTC: 'Heavy Duty DTC',
  alertsNewAlert_Idle: 'Idle',
  alertsNewAlert_LightDutyDTC: 'Light Duty DTC',
  alertsNewAlert_OBDII: 'OBDII',
  alertsNewAlert_Media: 'Media',
  alertsNewAlert_OffHour: 'Off Hour',
  alertsNewAlert_SmartOneCLowBattery: 'Smart One CLow Battery',
  alertsNewAlert_Speed: 'Speed',
  alertsNewAlert_Stationary: 'Stationary',
  alertsNewAlert_Stop: 'Stop',
  alertsNewAlert_StoppedReporting: 'Stopped Reporting',
  alertsNewAlert_TotalDriveTime: 'Total Drive Time',
  alertsNewAlert_Tow: 'Tow',
  alertsNewAlert_UnassignedDriver: 'Unassigned Driver',
  alertsNewAlert_VehicleCongregation: 'Vehicle Congregation',
  alertsNewAlert_FSMMealBreak: 'FSM Meal Break',
  alertsNewAlert_OBDIIAlerts: 'OBDII Alerts',
  alertsNewAlert_OnLandmark: 'On Landmark',
  alertsNewAlert_OnLandmarkEnter: 'On Landmark Enter',
  alertsNewAlert_OnLandmarkExit: 'On Landmark Exit',
  alertsNewAlert_stop: 'On Stop',
  alertsNewAlert_OverPostedSpeed: 'Over Posted Speed',
  alertsNewAlert_OverPostedSpeed: 'On Stop',

  alertsNewAlert_currentDriver: 'Current driver',

  alertsNewAlert_tab_unit_units: "Units",
  alertsNewAlert_tab_unit_drivers: "Drivers",
  alertsNewAlert_tab_unit_all: "All",

  alertsNewAlert_notifyAt: "Notify At",
  alertsNewAlert_breakDuration: "Break Duration",
};