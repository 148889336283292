// View Keys

export default {
  reportsMainRT_yes: 'Si',
  reportsMainRT_no: 'No',
  reportsMainRT_advancedSummaryReport: 'Reporte resumido avanzado',
  reportsMainRT_advancedSummaryReportInfo: 'Muestra el tiempo total de conducción, inactividad y parada, número de paradas, tiempo medio de parada, número de inactividad, tiempo medio de inactividad, cuentakilómetros inicial, cuentakilómetros final y distancia.',
  reportsMainRT_alertLogReport: 'Reporte de registro de alertas',
  reportsMainRT_alertLogReportInfo: 'Organizado por vehículo y día, muestra las alertas activadas, incluye hora y fecha, dirección, conductor, contactos, tipo de alerta y detalle de alerta.',
  reportsMainRT_deviceSummaryInfoReport: 'Informe de información resumida del dispositivo',
  reportsMainRT_deviceSummaryInfoReportInfo: 'Solo para unidades Smart One C. Etiqueta, etiqueta de la unidad, número de serie de la unidad, notas, total de horas del motor, tiempo de funcionamiento del motor (horas), dirección actual, cantidad de días en la dirección actual, dirección anterior, hora del último evento',
  reportsMainRT_temperatureReport: 'Reporte de Temperatura',
  reportsMainRT_temperatureReportInfo: 'Organizado por unidad, muestra datos históricos de temperatura para el rango de fechas seleccionado',
  reportsMainRT_detailedReport: 'Reporte detallado',
  reportsMainRT_detailed: 'Detallado',
  reportsMainRT_detailedReportInfo: 'Lista de las lecturas individuales del dispositivo para el rango de fechas y el lapso de tiempo especificado. Cada entrada muestra Hora y fecha, Conductor, Dirección, Velocidad, Velocidad publicada, Dirección, Evento, Señal (intensidad) y Odómetro.',
  reportsMainRT_driverSafetyReport: 'Reporte de seguridad del conductor',
  reportsMainRT_driverSafetyReportInfo: 'Enumera giros bruscos, frenos bruscos, aceleraciones rápidas. Cada entrada incluye fecha y hora, conductor, dirección, velocidad, velocidad publicada, dirección, evento y señal.',
  reportsMainRT_exclude: 'Excluir',
  reportsMainRT_excludeTables: 'Excluir tablas sin datos',
  reportsMainRT_idleMinutesFilter: "Filtro de minutos de inactividad",
  reportsMainRT_idleReport: 'Reporte de inactividad',
  reportsMainRT_idleReportInfo: 'Muestra eventos inactivos. Utilice el filtro de minutos de inactividad para mostrar eventos inactivos que duran un período de tiempo específico',
  reportsMainRT_ignitionReport: 'Reporte de encendido',
  reportsMainRT_ignitionReportInfo: 'Muestra cada evento de Encendido y Apagado. Incluye fechas, horas, direcciones y duración del evento, junto con un total diario de tiempo de encendido y apagado',
  reportsMainRT_itemsError: "Seleccione al menos una opción",
  reportsMainRT_landmarkReport: 'Reporte de punto de interés',
  reportsMainRT_landmarkReportInfo: 'Muestra los puntos de referencia visitados por cada vehículo, incluidos los tiempos de entrada y salida, la distancia recorrida dentro y entre los puntos de referencia, la duración dentro y entre los puntos de referencia.',
  reportsMainRT_mileageReport: 'Reporte de kilometraje',
  reportsMainRT_mileageReportInfo: 'Detalla cómo aumentó el kilometraje con cada lectura, muestra tanto el kilometraje acumulado entre lecturas como los aumentos de distancia total y el kilometraje acumulado para cada día.',
  reportsMainRT_mileageSummaryReport: 'Reporte de resumen de kilometraje',
  reportsMainRT_driverMileageSummaryReport: 'Reporte de resumen de kilometraje por conductor',
  reportsMainRT_mileageSummaryReportInfo: 'Kilometraje total acumulado cada día.',
  reportsMainRT_driverMileageSummaryReportInfo: 'Kilometraje total acumulado por conductor.',
  reportsMainRT_minutesFilter: "Filtro de minutos",
  reportsMainRT_noDataMessage: 'No se han encontrado datos. Por favor, afine su búsqueda e inténtelo de nuevo',
  reportsMainRT_loadingInitial: 'Cargando reporte',
  reportsMainRT_reportSetup: 'Configuración del reporte',
  reportsMainRT_reportType: 'Selecciona un Tipo de reporte',
  reportsMainRT_viewReportBy: 'Ver informe por',
  reportsMainRT_runReportFor: 'Ejecutar informe para',
  reportsMainRT_simpleReport: 'Reporte simple',
  reportsMainRT_actReport: 'Informe de actividad con contador de horas',
  reportsMainRT_hourOfUseReport: 'Informe de horas de uso',
  reportsMainRT_speedFilter: "Mostrar solo velocidades ≥ :",
  reportsMainRT_speedLimit: "Mostrar solo velocidades ≥ sobrepasada por:",
  reportsMainRT_actReportInfo: 'Horas de motor desglosadas por ralentí frente a movimiento, muestra la duración del evento junto con la ubicación y la fecha/hora de inicio, proporciona un contador de horas de funcionamiento que muestra el aumento de horas correspondiente al uso del motor.',
  reportsMainRT_hourOfUseReportInfo: 'Proporciona el primer encendido, el último apagado, las horas de máquina, las horas de trabajo, las horas de fin de semana, las horas de facturación y las horas extraordinarias.',
  reportsMainRT_simpleReportInfo: 'Lista de las lecturas individuales del dispositivo para el rango de fechas y el lapso de tiempo especificado. Cada entrada muestra la hora y la fecha, la dirección, la distancia, la velocidad, la velocidad publicada y el evento.',
  reportsMainRT_speedFilterInfo: "Detalla las fechas, ubicaciones y horas en que se infringió una velocidad específica y/o cuando se incumplió el límite de velocidad indicado en una cantidad específica. Establezca filtros para determinar qué eventos de exceso de velocidad se muestran.",
  reportsMainRT_speedReport: 'Reporte de velocidad',
  reportsMainRT_speedReportInfo: 'Detalla las fechas, ubicaciones y horas en que se infringió una velocidad específica y/o cuando se incumplió el límite de velocidad indicado en una cantidad específica. Establezca filtros para determinar qué eventos de exceso de velocidad se muestran.',
  reportsMainRT_startStopIdleReport: 'Reporte de Inicio, Parada e Inactividad',
  reportsMainRT_startStopIdleReportInfo: 'Organiza las lecturas desde el motor encendido hasta el motor apagado. Muestra el total de tiempos de encendido y apagado del motor, inactivo y de viaje para el intervalo de fechas seleccionado. También muestra la distancia total, la velocidad máxima y la velocidad media. Además de los totales generales, cada evento también se detalla en la información anterior.',
  reportsMainRT_stateMileageReport: 'Reporte de kilometraje del Estado',
  reportsMainRT_stateMileageReportInfo: 'Muestra las millas acumuladas por estado por vehículo durante el rango de fechas seleccionado, incluido el número de cruces fronterizos estatales y las lecturas del odómetro. Se puede utilizar para ayudar con los informes IFTA.',
  reportsMainRT_stateMileageCrossingReport: 'Reporte de Cruce de Kilometraje del Estado',
  reportsMainRT_stateMileageCrossingInfo: 'Muestra las millas cruzadas por estado por vehículo durante el rango de fechas seleccionado, incluido el número de cruces fronterizos estatales y las lecturas del odómetro. Se puede utilizar para ayudar con los informes IFTA.',
  reportsMainRT_errorDate: "La fecha de inicio debe ser inferior a la fecha de finalización",
  reportsMainRT_stopReport: 'Reporte de la parada',
  reportsMainRT_stopReportInfo: 'Enumera las paradas de cada día con dirección, hora y fecha y duración. Indica el tiempo de inactividad frente al tiempo de apagado del motor. Use el filtro Parada para mostrar solo las paradas que duran más de una cantidad de minutos.',
  reportsMainRT_stopSummaryReport: 'Informe Resumen de parada',
  reportsMainRT_stopSummaryReport_legacy: 'Antiguo Informe Resumen de parada',
  reportsMainRT_stopSummaryReportInfo: 'Organizado por conductor, fecha y parada: duración total de la parada, hora y fecha, dirección, estado y duración',
  reportsMainRT_vehServiceItemsReport: 'Informe de elementos de servicio del vehículo',
  reportsMainRT_vehServiceItemsReportLegacy: 'Antiguo Informe de elementos de servicio del vehículo',
  reportsMainRT_vehServiceItemsReportInfo: 'Muestra un desglose por elemento de servicio para todos los servicios completados que incluyeron ese elemento. Muestra el costo total de todos los servicios que incluyeron ese elemento de mantenimiento. Muestra el nombre del servicio, la hora y la fecha, el costo y las notas del servicio',
  reportsMainRT_milesPerGallonReport: 'Informe de Millas Por Galon',
  reportsMainRT_behaviorReport: 'Informe de Comportamiento',
  reportsMainRT_milesPerGallonReportInfo: 'Informe de Millas Por Galon',
  reportsMainRT_behaviorReportInfo: 'Informe de Comportamiento',
  reportsMainRT_vehicleMaintenanceItemsReport: 'Informe de elementos de mantenimiento del vehículo',
  reportsMainRT_vehicleMaintenanceItemsReportLegacy: 'Antiguo Informe de elementos de mantenimiento del vehículo',
  reportsMainRT_vehicleMaintenanceItemsReportInfo: 'Detalles Servicios completados para vehículos dentro del rango de fechas solicitado, incluida la hora y la fecha, el título del servicio, la cantidad de millaje, los días, las horas, el costo total y cualquier nota ingresada cuando se completó el servicio.',
  reportsMainRT_driverFormsReport: 'Antiguo Informe de formularios de conductor',
  reportsMainRT_driverFormsReportV2: 'Informe de formularios de conductor',
  reportsMainRT_driverStatusReportV2: 'Informe sobre el estado del conductor',
  reportsMainRT_driverFormsReportInfo: 'Formularios completos enviados por los conductores a través de la aplicación FSM. Única opción para ver las respuestas del Formulario.',
  reportsMainRT_driverStatusReportV2Info: 'Formularios completos enviados por los conductores a través de la aplicación del conductor. Única opción para ver las respuestas del Formulario.',
  reportsMainRT_summaryReport: 'Reporte resumido',
  reportsMainRT_summaryReportInfo: 'Proporciona una suma de Motor encendido, Motor apagado, Tiempo de viaje, Distancia, Tiempo de inactividad, Velocidad máxima y Velocidad promedio para el rango de fechas seleccionado.',
  reportsMainRT_timeFrame: 'Período de tiempo',
  reportsMainRT_timeFrameLabel: 'Seleccione',
  reportsMainRT_selectTimeFrame: 'Seleccione un Período de tiempo',
  reportsMainRT_selectTimeFrameRange: 'Seleccione una fecha o un intervalo',
  reportsMainRT_unitDriverSelection: 'Selección de unidad/conductor',
  reportsMainRT_driverSelection: 'Selección de conductor',
  reportsMainRT_event: 'Evento',
  reportsMainRT_more: 'Más',
  reportsMainRT_noTripsAvailable: 'No hay viajes disponibles',
  reportsMainRT_exportReportError: 'Lo sentimos, ocurrió un error durante la generación del archivo.',
  reportsMainRT_exportReportSuccess: 'El reporte ha sido generado correctamente.',
  reportsMainRT_exportReportTitleStarted: 'Correcto',
  reportsMainRT_exportReportMessageStarted: 'El reporte está siendo generado, te notificaremos cuando este listo.',
  reportsMainRT_exportReportTitleSuccess: 'Correcto',
  reportsMainRT_exportReportTitleFailed: 'Error',
  reportsMainRT_exportReportMessageSuccess: 'El reporte ha sido generado correctamente.',
  reportsMainRT_exportReportMessageFailed: 'Lo sentimos, ocurrió un error durante la generación del archivo.',
  reportsMainRT_exportReportMessageFailedNoData: 'Lo sentimos, no se encontró información para exportar.',
  reportsMainRT_pinReport: 'Anclar Reporte',
  reportsMainRT_unPinReport: 'Desanclar Reporte',
  reportsMainRT_getReport: 'Generar Reporte',
  reportsMainRT_addBeen: 'Se ha actualizado la lista de favoritos con éxito',
  reportsMainRT_updatedReport: 'El reporte ha sido actualizado correctamente',
  reportsMainRT_updatedReportFail: 'Error actualizando el nombre del reporte',
  reportsMainRT_notAvailable: 'No disponible',
  reportsMainRT_utilization: 'Informe Utilización',
  reportsMainRT_utilizationInfo: 'Para cada dispositivo seleccionado muestra la distancia total recorrida, el tiempo de funcionamiento del motor, el tiempo de inactividad, el tiempo detenido, el porcentaje de tiempo en movimiento, los días trabajados, el total de días en el rango seleccionado y el porcentaje de días trabajados',
  reportsMainRT_eventReport: 'Informe Evento',
  reportsMainRT_eventReportInfo: 'Proporciona una lista de eventos específicos para el intervalo de fechas y los dispositivos/controladores seleccionados. Utilice el filtro de eventos para mostrar solo los eventos seleccionados. La siguiente imagen muestra los filtros de eventos.',
  reportsMainRT_formReport: 'Informe de Formulario',
  reportsMainRT_movingReport: 'Informe en Movimiento',
  reportsMainRT_stateMileageSummariesReport: 'Informe sobre el resúmen del kilometraje',
  reportsMainRT_movingReportInfo: 'Muestra datos para cada Inicio de viaje a Parada de viaje. Muestra la dirección de origen y destino, las horas de inicio y fin, el tiempo total de viaje. Desglosado por día. Se puede filtrar para eliminar eventos por debajo de cierta duración',
  reportsMainRT_stateMileageSummariesReportInfo: 'Informe sobre el resúmen del kilometraje.',
  reportsMainRT_pingReport: 'Informe Ping',
  reportsMainRT_pingReportInfo: 'Solicitud manual de la posición de la unidad (rastreador de activos) utilizando las funciones Localizar en la pestaña Estado, el informe muestra las solicitudes manuales enviadas.',
  reportsMainRT_odometerReport: 'Informe Odómetro',
  reportsMainRT_odometerReportInfo: 'Da la lectura actual del cuentakilómetros del vehículo, organizada por grupo.',
  reportsMainRT_landmarkEngineHoursReport: 'Informe de horas históricas',
  reportsMainRT_landmarkEngineHoursReportInfo: 'Resume cuántas veces un vehículo visitó puntos de referencia particulares, incluida la duración total de la visita y las horas del motor, organizados por unidad.',
  reportsMainRT_NonReportingReport: 'Informe de no informe',
  reportsMainRT_NonReportingReportInfo: 'Enumera los vehículos que no han informado dentro del rango de fechas elegido.',
  reportsMainRT_driverTimesheetReport: 'Informe de horas del conductor',
  reportsMainRT_driverTimesheetReportInfo: 'Se desglosa por conductor para mostrar los vehículos asignados y el historial de asignaciones. Debe utilizar la herramienta Driver.',
  reportsMainRT_UnitInfoReport: 'Informe de la unidad',
  reportsMainRT_UnitInfoReportInfo: 'Organizado por etiqueta, brinda el tipo de unidad, el número de serie de la unidad, el número de serie de Garmin (si corresponde), la etiqueta de la unidad, la zona horaria, el conductor, el odómetro, las horas del motor, el equipo, el VIN, el último evento y la hora del último evento.',
  reportsMainRT_geofenceReport: 'Informe de Geocerca',
  reportsMainRT_geofenceReportInfo: 'Proporciona una lista de eventos de geocerca para cada vehículo, incluido el evento, el nombre de la geocerca, la dirección, las etiquetas, la hora y la fecha, la hora y la distancia.',
  reportsMainRT_timesheetReport: 'Informe Parte de horas',
  reportsMainRT_timesheetReportInfo: 'Proporciona la fecha, la hora y la dirección de cuando el motor se encendió por primera vez en el día (hora del primer encendido), así como la fecha, la hora y la dirección de la última vez que se apagó el motor del día (último apagado). También proporciona el tiempo total transcurrido entre el primer encendido y el último motor apagado y la distancia total recorrida cada día',
  reportsMainRT_locationReport: 'Reporte de localización',
  reportsMainRT_locationReportInfo: 'Historial para cambiar las asignaciones de ubicación de vehículos.',
  reportsMainRT_landmarkBasedReport: "Basado en Puntos de Interés",
  reportsMainRT_landmarkBasedReportInfo: "Muestra qué unidades entraron y salieron de cada punto de referencia, la duración dentro del punto de referencia y el tiempo dentro del punto de referencia.",
  reportsMainRT_eventFilter: "Filtro de evento",
  reportsMainRT_serviceItemFilter: "Filtro de artículos de servicio",
  reportsMainRT_formFilter: "Filtro de formulario",
  reportsMainRT_landmarkView: "Vista de punto de Interés",
  reportsMainRT_geofenceView: "Vista de Geocerca",
  reportsMainRT_legacyChatHistory: 'Antiguo Informe del Historial de Chat',
  reportsMainRT_chatHistory: 'Informe Historial Chat',
  reportsMainRT_legacyChatHistoryInfo: 'Historial de chat heredado entre Driver y Dispatch. Organizado por Chofer.',
  reportsMainRT_chatHistoryInfo: 'Historial de chat entre Driver y Dispatch. Organizado por Chofer.',
  reportsMainRT_chatToFrom: 'Chat Para/Desde',
  reportsMainRT_message: 'Mensaje',
  reportsMainRT_stopTitle: 'Título de parada',
  reportsMainRT_stopStatus: 'Estado de parada',
  reportsMainRT_inOut: 'Entrada/Salida',
  reportsMainRT_driverStatus: 'Estado del conductor',
  reportsMainRT_driverStatusReport: 'Antiguo Informe del Estado del Conductor',
  reportsMainRT_stopHistory: 'Informe Historial de Paradas',
  reportsMainRT_stopHistory_legacy: 'Antiguo Informe Historial de Paradas',
  reportsMainRT_stopHistoryInfo: 'Fecha/hora, dirección, título de la parada, estado actual y duración del viaje',
  reportsMainRT_timecardv2: 'Reporte Tarjeta de tiempo',
  reportsMainRT_fsmTimeCardReport: 'Antiguo Reporte Tarjeta de tiempo',
  reportsMainRT_driverFormsReportV2Info: 'Formularios completados enviados por los conductores a través de la aplicación del conductor. Única opción para ver las respuestas del formulario.',
  reportsMainRT_fsmTimeCardReportInfo: 'Marcaciones del reloj de tiempo del conductor a través de la aplicación FSM, organizadas por fecha, incluye la ubicación del teléfono/tableta utilizada y el tiempo total',
  reportsMainRT_fsmTimeCardReportInfoV2: 'Marcaciones del reloj de tiempo del conductor a través de la aplicación del conductor, organizadas por fecha, incluye la ubicación del teléfono/tableta utilizada y el tiempo total',
  reportsMainRT_fsmCombinedReport: 'Antiguo Informe Combinado',
  reportsMainRT_fsmCombinedReportV2: 'Informe Combinado',
  reportsMainRT_fsmCombinedReportInfo: 'Cambios de estado del conductor, marcaciones de reloj de tiempo, historial de chat, paradas, estados de parada actuales',
  reportsMainRT_filesReportsGenerated: 'El informe ha sido generado, seleccione los archivos que desea descargar',
  reportsModalDownloadReports: 'Descargar Reportes',
  reportsMainRT_notData: 'sin información',
  reportsMainRT_clearAll: 'Limpiar todo',
  reportsMainRT_latitudeLongitudeReport: 'Informe Lat/Long',
  reportsMainRT_latitudeLongitudeReportInfo: 'Proporciona la latitud y la longitud de cada lectura junto con el evento, la hora, la fecha y la dirección.',
  reportsMainRT_geofenceBasedReport: 'Informe basado en geocercas',
  reportsMainRT_geofenceBasedReportInfo: 'Proporciona una lista de eventos de geovalla para cada vehículo, incluido el evento, el nombre de la geovalla, la dirección, las etiquetas, la hora y la fecha, la hora y la distancia',
  reportsMainRT_diagnosticParametersReport: 'Parámetros de diagnóstico',
  reportsMainRT_diagnosticParametersReportInfo: 'Retroalimentación actual de los sensores de diagnóstico en los vehículos, es decir, voltaje actual de la batería. Solo disponible para ciertas unidades enchufables.',
  reportsMainRT_totalInGeofenceCount: 'Cuenta Total en geocerca',
  generatedReport_timeInGeofence: 'Tiempo en geocerca',
  generatedReport_distanceInGeofence: 'Distancia en geocerca',
  reportsMainRT_latitude: 'Latitud',
  reportsMainRT_longitude: 'Longitud',
  reportsMainRT_singleLandmark: "Puntos de interes individuales",
  reportsMainRT_tagLandmarkView: "Etiquetas puntos de interés",
  reportsMainRT_allLandmarkView: "Todos los Puntos de interés",
  reportsMainRT_singleGeofence: "Geocercas individuales",
  reportsMainRT_tagGeofenceView: "Etiquetas de Geocercas",
  reportsMainRT_allGeofenceView: "Todas las Geocercas",
  reportsMainRT_singleForm: "Forms individuales",
  reportsMainRT_allFormView: "Todos Forms",
  reportsMainRT_landmarkCrewReport: "Informe de la tripulación",
  reportsMainRT_landmarkCrewReportInfo: "Detalla los puntos de referencia visitados por las tripulaciones asignadas. Muestra la hora y la fecha, el punto de referencia, el conductor, el miembro de la tripulación, el tiempo total de la tripulación en el punto de referencia y el tiempo total del vehículo en el punto de referencia. Organizado por Unidad.",
  reportsMainRT_landmarkSummaryReport: "puntos de interés detallados",
  reportsMainRT_landmarkSummaryReportInfo: "Resume cuántas veces visitó un vehículo puntos de referencia particulares, incluida la duración total de la visita y las horas del motor.",
  reportsMainRT_DateChangedLocation: "Fecha de cambio",
  reportsMainRT_Location: 'Ubicación',
  reportsMainRT_Address: 'Direcciòn',
  reportsMainRT_Form: 'Formulario',
  reportsMainRT_Question: 'Pregunta',
  reportsMainRT_Answer: 'Respuesta',
  reportsMainRT_Time: 'Tiempo',
  generatedReport_unknownDateChangedLocation: 'Ubicación de cambio de fecha desconocida',
  generatedReport_eventName: 'Nombre del Evento',
  generatedReport_batteryVoltage: 'Voltaje de la batería (V)',
  generatedReport_coolantTemp: 'Temperatura de refrigerante (F)',
  generatedReport_engineSpeed: 'RPM del motor',
  generatedReport_fuelPercent: 'Nivel de combustible (%)',
  generatedReport_fuelRemain: 'Nivel de combustible restante (gal)',
  generatedReport_fuelRate: 'Tasa de combustible (gal/hr)',
  generatedReport_throttlePosition: 'La posición del acelerador (%)',
  generatedReport_tripFuel: 'Uso de combustible calculado',
  generatedReport_tripOdometer: 'Odómetro de viaje',
  generatedReport_vehicleSpeed: 'Velocidad del vehículo',
  generatedReport_zone1: 'Zona 1',
  generatedReport_zone2: 'Zona 2',
  generatedReport_zone3: 'Zona 3',
  reportsMainRT_DiagnosticIndicatorsReport: 'Informe de indicadores de diagnóstico',
  reportsMainRT_DiagnosticIndicatorsReportInfo: 'Lista de posibles luces de advertencia que *podrían* ocurrirle al vehículo (presión de neumáticos baja, control del motor, etc.). Solo disponible para ciertas unidades enchufables.',
  reportsMainRT_JBSummaryReport: 'Informe resumido de JB',
  reportsMainRT_JBSummaryReportInfo: 'Desglosado por vehículo, muestra Promedio de combustible económico del motor (mpg), Combustible total usado del motor (gal), Horas totales del motor, Combustible inactivo total del motor (gal), Horas inactivas totales del motor, Combustible total Hi-Rez (gal), PTO total Horas, Horas Totales del Vehículo.',
  reportsMainRT_EngineHoursReport: 'Reporte de Horas de motor',
  reportsMainRT_EngineHoursReportInfo: 'Solo para unidades Smart One C. Organizado por Unidad, proporciona Días Totales, Horas Totales y Horas Promedio para el Rango seleccionado. Hora y fecha, ubicación y horario.',
  reportsMainRT_smartOneCUtilizationReport: 'Informe de uso de Smart One C',
  reportsMainRT_smartOneCUtilizationReportInfo: 'Solo para unidades Smart One C. Organizado por unidad. Etiqueta, etiquetas, tiempo de funcionamiento del motor, tiempo de funcionamiento del motor (horas), tiempo de funcionamiento del motor (%), tiempo de apagado del motor (horas), tiempo de apagado del motor (%)',
  reportsMainRT_TimeDate: 'Hora y fecha',
  reportsMainRT_EventName: 'Evento',
  reportsMainRT_absActive: 'ABS Activo',
  reportsMainRT_absIndicator: 'Indicador de ABS',
  reportsMainRT_airBagIndicator: 'Indicador de AirBag',
  reportsMainRT_brakeIndicator: 'Indicador de freno',
  reportsMainRT_brakeSwichStatus: 'Cambio del estado de frenos',
  reportsMainRT_coolantHotLight: 'Luz de refrigerante caliente',
  reportsMainRT_cruiseControl: 'Control de crucero',
  reportsMainRT_dtc: 'DTC',
  reportsMainRT_ignitionStatus: 'Estado de encendido',
  reportsMainRT_milStatus: 'Estado de MIL',
  reportsMainRT_oilPressure: 'Presión del aceite',
  reportsMainRT_pto: 'Estado de PTO',
  reportsMainRT_seatBelt: 'Cinturón de seguridad',
  reportsMainRT_engineAverageFuelEconomy: 'Economía media de combustible del motor',
  reportsMainRT_engineTotalFuelUsed: 'Combustible total utilizado en el motor',
  reportsMainRT_engineTotalHours: 'Horas totales del motor',
  reportsMainRT_engineTotalIdleFuel: 'Combustible total en inactividad del motor',
  reportsMainRT_engineTotalIdleHours: 'Horas de inactividad totales del motor',
  reportsMainRT_hirezTotalFuel: 'Combustible total de Hirez',
  reportsMainRT_totalPtoHours: 'Horas totales PTO',
  reportsMainRT_totalVehicleHours: 'Horas totales del vehículo',
  reportsMainRT_jbDiagnostics: 'JB Reporte Diagnostico ',
  reportsMainRT_jbDiagnosticsInfo: 'Muestra lecturas de diagnóstico específicas para: Posición del Pedal de Aceleración, Voltaje de la Batería, Posición del Pedal de Freno, Presión del Refrigerante del Motor, Temperatura del Refrigerante del Motor, Tasa de Combustible del Motor, Nivel de Aceite del Motor, Presión del Aceite del Motor, Temperatura del Aceite del Motor, Nivel de Combustible 1, Nivel de Combustible 2, Odómetro, RPM e Interruptor del Cinturón de Seguridad. Lecturas desglosadas por vehículo y reportadas cada 10 minutos.',
  reportsMainRT_jbdtcReport: 'JB DTC Reporte',
  reportsMainRT_jbdtcReportInfo: 'Lista de códigos DTC detectados a través de un dispositivo JBUS',
  reportsMainRT_hours: 'Horas',
  reportsMainRT_hoursMin: 'horas',
  reportsMainRT_engineHoursReportCaution: 'Este informe solo está disponible para dispositivos T1000 y T5000',
  reportsMainRT_smartOneCUtilizationReportCaution: 'Este informe solo está disponible para dispositivos T1000 y Smart One C',
  reportsMainRT_totalDays: 'Dias Totales',
  reportsMainRT_totalHours: 'Horas Totales',
  reportsMainRT_averageHours: 'Promedio de horas',
  reportsMainRT_formStopSummaryReport: 'Antiguo Informe resumen de paradas de formulario',
  reportsMainRT_formStopSummaryReportV2: 'Informe resumen de paradas de formulario ',
  reportsMainRT_formStopSummaryReportInfo: 'Fecha/hora, evento del vehículo, dirección, duración, formulario de preguntas y respuestas',
  reportsMainRT_basic: 'Básicos',
  reportsMainRT_diagnostics: 'Diagnósticos',
  reportsMainRT_fsm: 'FSM',
  reportsMainRT_legacyMaintenance: 'Antiguo Mantenimiento',
  reportsMainRT_maintenance: 'Mantenimiento',
  reportsMainRT_others: 'Otros',
  reportsMainRT_label: 'Etiqueta',
  reportsMainRT_unitGroups: 'Grupos de unidades',
  reportsMainRT_tags: "Etiquetas",
  reportsMainRT_engineRunningTime: 'Tiempo de funcionamiento del motor (horas)',
  reportsMainRT_engineRunningTimePerc: 'Tiempo de funcionamiento del motor (%)',
  reportsMainRT_engineOffTime: 'Tiempo de apagado del motor (horas)',
  reportsMainRT_engineOffTimePerc: 'Tiempo de apagado del motor (%)',
  reportsMainRT_fuelCardReport: 'Informe de tarjeta de combustible',
  reportsMainRT_fuelCardReportInfo: 'Informe de tarjeta de combustible',
  reportsMainRT_card: 'Tarjeta',
  reportsMainRT_reportOptions: 'Opciones de informe',
  reportsMainRT_fuel: 'Combustible',
  generatedReport_mpg: 'MPG',
  generatedReport_fleet: 'Vehiculo',
  generatedReport_transactionId: 'Trans. ID',
  generatedReport_posted: 'Publicada',
  generatedReport_quantity: 'Cantidad',
  generatedReport_unitAddress: 'Direccion unidad',
  generatedReport_price: 'Costo',
  reportsMainRT_serviceTitle: 'Titulo',
  reportsMainRT_distance: 'Distancia',
  reportsMainRT_days: 'Dias',
  generatedReport_notes: 'Notas',
  reportsMainRT_inputReport: 'Informe de Entradas',
  reportsMainRT_inputReportInfo: 'Historial de comentarios de entrada. Cuándo y dónde se activaron las entradas, qué entradas se activaron, el número total de veces que se activaron y el tiempo total de activación.',
  reportsMainRT_noDataEvent: 'No hay datos disponibles para el período de tiempo seleccionado.',
  reportsMainRT_enterValue: 'Introducir valor',
  reportsMainRT_getNewReport: 'Nuevo Reporte',
  reportsMainRT_editReport: 'Editar Reporte',
  reportsAdvanceFilterOptions: 'Opciones de filtro avanzadas',
  reportsAdvanceTimeRanges1: 'Aplicar los horarios del',
  reportsAdvanceTimeRanges2: 'a todos los días',


  reportReportType_seedOr: `Velocidad = o > `,
  reportReportType_overPosted: `Exeso de velocidad > `,
  reportReportType_minutes: `Minutos = `,
  reportReportType_eventFilter: `Filtro de eventos = `,
  reportReportType_serviceItemFilter: `Filtro de elementos de servicio = `,
  reportReportType_landmarkView: `Vista de marcador = `,
  reportReportType_landmark: `Marcador = `,
  reportReportType_tags: `Etiquetas = `,
  reportReportType_geofenceView: `Vista Geocerca = `,
  reportReportType_geofence: `Geocerca= `,

  reportReportType_DeletePinned: '¿Seguro que quieres borrar este favorito?',
  generatedReport_event_1: 'Encendido',
  generatedReport_event_2: 'Ignición apagada',
  generatedReport_event_3: 'Parada de viaje',
  generatedReport_event_4: 'Inicio de viaje',
  generatedReport_event_5: 'Ping',
  generatedReport_event_6: 'Inactivo',
  generatedReport_event_7: 'Apagado principal',
  generatedReport_event_8: 'Conducir',
  generatedReport_event_9: 'Giro difícil',
  generatedReport_event_10: 'Freno duro',
  generatedReport_event_11: 'Detener',
  generatedReport_event_12: 'Aceleración rápida',
  generatedReport_event_13: 'Desconexión de la alimentación principal',
  generatedReport_event_14: 'INACTIVO',
  generatedReport_event_15: 'MOVER',
  sec: 'seg',
  daysTime: 'días',
  Idle: 'Inactivo',
  generatedReport_Mon: 'Lun',
  generatedReport_Tue: 'Mar',
  generatedReport_Wed: 'Mie',
  generatedReport_Thu: 'Jue',
  generatedReport_Fri: 'Vie',
  generatedReport_Sat: 'Sab',
  generatedReport_Sun: 'Dom',
  generatedReport_input: 'Entrada',
  generatedReport_count: 'Ocurrencia',
  generatedReport_totalMachine: 'Total de horas de máquina',
  generatedReport_totalWorkHours: 'Total de horas de trabajo',
  generatedReport_totalBilling: 'Total de horas de facturación',
  generatedReport_totalOvertime: 'Total horas extras',
  generatedReport_NoActivity: 'Sin actividad',
  stop_history_report_v2_InRoute: "En Ruta",
  stop_history_report_v2_Pending: "Pendiente",
  stop_history_report_v2_Done: "Realizado",
  stop_history_report_v2_OnSite: "En Sitio",
  total_distance_traveled: 'Distancia total recorrida',
  speed_measurement_miles: 'mi',
  speed_measurement_kilometers: 'km',
  current_as_of: 'Actual a partir de: '
};
