import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    error: null,
    unit: null,
    open: false,
    loading: false,
    result: null,
};

const route = 'modules/settings/views/cameraManage/components/editCamera/redux/slice'

export const cameraManageEditCamera = createAsyncThunk('cameraManage/editCamera/cameraManageEditCamera', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `
                mutation updateUnit (
                    $id: Int!,
                    $label: String
                ) {
                    data: updateUnit (
                        id: $id,
                        label: $label,
                    ) {
                        id
                        label
                    }
                }
            `,
            payload,
            endpoints.GRAPHQL_GENERAL
        );
        return response?.data
        
    } catch (exc) {
        ExceptionManager(exc, route, 'cameraManageSearchUnits');
        return rejectWithValue(exc)
    }
});

const settingsCameraManageEditCameraRedux = createSlice({
    name: 'cameraManage/editCamera',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        openModalEditCamera(state, action) {
            return { ...state, open: action?.payload?.open, unit: action?.payload?.unit }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //cameraManageEditCamera
        builder.addCase(cameraManageEditCamera.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(cameraManageEditCamera.fulfilled, (state, action) => {
            state.result = action.payload
            state.loading = false;
        });
        builder.addCase(cameraManageEditCamera.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = settingsCameraManageEditCameraRedux;

// Extract and export each action creator by name
export const { reset, openModalEditCamera } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;