/* eslint-disable */
import React, { forwardRef, useState, useEffect, useCallback, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useLocation } from "react-router-dom";
import _ from 'lodash';
import moment from 'moment'
import { useIntl } from 'react-intl';
import {
  disableReportInSelect, formatDatetime
} from 'Modules/reports/views/main/components/reportsTab/helper';
//globals
import { Grid } from '@mui/material';

//Components / Controls
import SidePanel, { SidePanelBody, SidePanelHeader } from 'Components/SidePanel';
import { getGeocoder } from 'Components/Map/providers/googleGeocoder'
import Map from 'Components/Map'
import { getCordinatesAndRadius } from '../data/Helpers';
import { radiusFeetToMeters } from 'Components/Map/utils'
import { getFilterNameByEntity } from 'Modules/reports/views/main/components/reportsTab/helper';

//Actions
import {
  downloadAttachmentUrl,
  reportsMainGenerateReportUpdate,
  mapGetLandmarkTags,
  getKmls,
  mapGetGeofenceTags,
  catalogLoad, reportsMainShowReportSetup, reportsMainSetFormDataReportSetup
} from 'Redux/actions';

import {
  OrderData,
  dateTimeZoneToUTC
} from 'Core/data/Helpers';

import {
  getPlaceholderSelector
} from 'Modules/reports/views/main/components/reportsTab/helper';

// import { defaultLocale } from './defaultValues'

export const useInput = (initialValue, label = '', placeholder = '') => {
  const [value, setValue] = useState(initialValue);
  useEffect(
    () => {
      setValue(initialValue);
    },
    [initialValue]
  );
  const onChange = (txt) => {
    setValue(txt);
  };
  return {
    value,
    onChange,
    label,
    placeholder
  };
};

export const useSelect = (values, textField = '', label = '', placeholder = '', selected) => {
  const [options, setOptions] = useState(values);
  const [optionsSelected, setOptionsSelected] = useState(selected);
  useEffect(
    () => {
      setOptions(values);
    },
    [values]
  );
  useEffect(
    () => {
      setOptionsSelected(selected);
    },
    [selected]
  );
  const onChange = (item) => {
    setOptionsSelected(item);
  };
  return {
    options,
    onChange,
    value: optionsSelected,
    textField,
    label,
    placeholder
  };
};

export const useFileUpload = (initialValue) => {
  const [files, setFiles] = useState([]);
  useEffect(
    () => {
      if (initialValue) {
        setFiles(initialValue);
      }
    },
    [JSON.stringify(initialValue)]
  );

  const onChange = (files) => {
    setFiles(files);
  };

  const getFilesIds = () => {
    let ids = [];
    if (files) {
      for (const file of files) {
        if (file?.id || file?.Id) {
          ids.push(file.id || file?.Id);
        }
      }
    }
    return ids;
  }

  return {
    value: files,
    onChange,
    getFilesIds
  };
};

export const useDownload = () => {
  const [urlFile, setUrlFile] = useState(null);
  const dispatch = useDispatch();
  const endPoint = useSelector(
    (state) => state.commomRedux.endPoint
  );
  useEffect(() => {
    if (endPoint) {
      setUrlFile(urlFile);
    }
  }, [endPoint]);

  const getUrlFile = (id) => {
    dispatch(downloadAttachmentUrl({
      id
    }))
  }

  const getExtension = (filename) => {
    return filename.split('.').pop();
  }

  return {
    getUrlFile,
    urlFile,
    getExtension
  }
};

export const useTimePicker = () => {
  const ampmOptions = [{ 'id': 1, 'name': 'AM' }, { 'id': 2, 'name': 'PM' }];
  const now = moment();
  const hour = useInput();
  const minute = useInput();
  const second = useInput();
  const [ampm, setAmPm] = useState(JSON.parse(JSON.stringify(now.hour() > 12 ? ampmOptions[1] : ampmOptions[0])));

  useEffect(() => {
    // Default value for time input
    const defaultHour = now.hour() > 12 ? now.hour() - 12 : now.hour()
    hour.onChange(defaultHour);
    minute.onChange(now.minutes().toString());
    second.onChange(now.seconds().toString());
  }, []);

  const handleChange = (value, type) => {
    if (type === 'am/pm') {
      setAmPm(value);
      return getCompleteHour(null, null, null, value);
    } else if (type === 'hour') {
      hour.onChange(value);
      return getCompleteHour(value, null, null, null);
    } else if (type === 'minute') {
      minute.onChange(value);
      return getCompleteHour(null, value, null, null);
    } else if (type === 'second') {
      second.onChange(value);
      return getCompleteHour(null, null, value, null);
    }

  }

  const setHour = (newHour, newMinute, newSecond, newAmPm) => {
    const now = moment();
    const defaultHour = now.hour() > 12 ? now.hour() - 12 : now.hour()

    hour.onChange(newHour || defaultHour);
    minute.onChange(newMinute || now.minutes());
    second.onChange(newSecond || now.seconds());

    if (newAmPm) {
      const findAmPm = ampmOptions.find(ampm => ampm.name === newAmPm);
      setAmPm(findAmPm);
    } else {
      setAmPm(ampmOptions[0]);
    }
  }

  const getCompleteHour = (tempHour, tempMinute, tempSecond, tempAmpm) => {
    let newTempAmpm = tempAmpm;
    if (typeof tempAmpm === 'string') {
      newTempAmpm = {
        name: tempAmpm
      }
    }
    return `${formatNumberToTwoDigists(tempHour || hour.value)}:${formatNumberToTwoDigists(tempMinute || minute.value)}:${formatNumberToTwoDigists(tempSecond || second.value)} ${newTempAmpm?.name || (ampm?.name || '')}`;
  }

  const formatNumberToTwoDigists = (number) => {
    if (!number) {
      return '00';
    }

    let newNumber = number;
    if (number.length === 0) {
      newNumber = '00';
      return newNumber;
    }

    if (number < 10 && String(number || '').length <= 1) {
      newNumber = `0${number}`;
      return newNumber;
    }

    return newNumber;
  }

  return {
    hour,
    minute,
    second,
    ampm,
    handleChange,
    getCompleteHour,
    formatNumberToTwoDigists,
    setHour
  }
};

export const useDetectOutsideClick = (ref) => {
  const [clickInside, setClickInside] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && typeof event.target.className === 'string' && !event.target.className?.includes("react-select__option")) {
        setClickInside(false);
      } else {
        setClickInside(true);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onChange = (value) => {
    setClickInside(value);
  }

  return {
    clickInside,
    onChange
  };
}


const deepCompareEquals = (a, b) => {
  // implement deep comparison here
  return _.isEqual(a, b);
}

const useDeepCompareMemoize = (value) => {
  const ref = useRef()
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}

export const useDeepCompareEffect = (callback, dependencies) => {
  useEffect(
    callback,
    dependencies.map(useDeepCompareMemoize)
  )
}

/**
 * Hook to validate information from selector and filters changes
 * @param {*} dataSelector
 * @param {*} filterSelector
 */
/* export const useDataToValidate = (dataSelector, filterSelector) => {

  let lastSync = moment()
  let lastDataSync = []
  let lastFilers
  let lastIds = []

  //Function to validate changes in two arrays validate specific ids and properties changed
  const useValidateChanges = (lastData, nextData, ids, idProperty, properties = [], validateAll, callbackChanges) => {
    let refresh = false;

    //if empty is invalid
    if (lastData.length === 0 && nextData.length === 0) {
      return false
    }

    if (lastData.length > 0 && nextData.length > 0 && (!validateAll && (!ids || ids.length === 0))) {
      return false
    }

    let lastDataFilter
    let nextDataFilter
    if (validateAll) {
      lastDataFilter = lastData
      nextDataFilter = nextData
    }
    else {
      lastDataFilter = ids.length > 0 && lastData.filter(x => ids.includes(x[idProperty].toString())) || lastData
      nextDataFilter = ids.length > 0 && nextData.filter(x => ids.includes(x[idProperty].toString())) || nextData
    }

    if (lastDataFilter.length != nextDataFilter.length) {
      return true;
    }

    let propertiesChangesGlobal = []
    let newDataChanges = []
    lastDataFilter.forEach(ld => {
      let internalRefresh = false;
      const nd = nextDataFilter?.find(x => x[idProperty].toString() === ld[idProperty].toString())
      if (properties.length > 0) {
        let propertiesChanges = []
        properties.forEach(p => {
          if (nd && ld && !_.isEqual(ld[p], nd[p])) {
            propertiesChanges.push({
              id: nd[idProperty],
              atribute: p,
              oldValue: ld[p],
              newValue: nd[p]
            })
            internalRefresh = true
            refresh = true
          }
        })
        if (internalRefresh && propertiesChanges.length > 0) {
          propertiesChangesGlobal.push(...propertiesChanges)
        }
      }
      else
        if (!_.isEqual(ld, nd)) {
          internalRefresh = true
          refresh = true
        }

      if (callbackChanges && internalRefresh) {
        newDataChanges.push(nd)
      }

    })

    if (callbackChanges && newDataChanges.length > 0) {
      callbackChanges({
        data: newDataChanges,
        changes: propertiesChangesGlobal
      })
    }

    return refresh;
  }

  //when send custom filter selector to validate changes and force sync
  let selectors = [dataSelector]
  if (filterSelector)
    selectors.push(filterSelector)

  return createSelector(
    selectors,
    (data, filters) => (ids, idProperty, propertiesToValidate, firstLoad, loadCallback, validateAll, callbackChanges) => {

      //validate force sync
      let forceSync = true;

      //if necesary because when memoize the function the lazy load is problem
      if (firstLoad) {
        loadCallback();
        lastSync = moment()
        lastDataSync = []
        lastFilers = filters
        return lastDataSync
      }

      //if necesary send data when changed ids
      if (!_.isEqual(lastIds, ids)) {
        lastSync = moment();
        lastIds = ids;
        if (ids.length === 0) {
          return lastDataSync
        }
        else {
          lastDataSync = data;
          return data;
        }
      }

      //handle time to refresh
      let date = moment(lastSync).add(0.2, 'seconds');

      if (moment() > date) {
        if (lastDataSync.length === 0 && data.length > 0) {
          lastSync = moment();
          lastDataSync = data;
          return data;
        }
      }

      //when has a custom filter selector to handle change logic
      if (filters) {
        if (!lastFilers) {
          lastFilers = filters
        }

        //when filters changed is necesary force sync
        forceSync = !_.isEqual(lastFilers, filters)
        if (forceSync) {
          lastSync = moment();
          lastDataSync = data;
          lastFilers = filters
          return data;
        }
      }


      if (moment() > date) {

        //validate changes to refresh
        forceSync = useValidateChanges(lastDataSync, data, ids, idProperty, propertiesToValidate, validateAll, callbackChanges)
        lastSync = moment();
        if (forceSync) {
          lastDataSync = data;
          return data;
        }
      }

      return lastDataSync
    }
  )
} */

/**
 * Hook to compute date respecto ids array and specific properties
 * @param {*} dataSelector
 * @param {*} idProperty
 * @param {*} propertiesToValidate
 */
/* export const useComputeData = (dataSelector, idProperty, propertiesToValidate, validateAll = false, callbackSingleChange) => {

  //Validate FirstLoad In component for lazy load
  const [firstLoad, setFirstLoad] = useState(true)
  const [ids, setIds] = useState([])
  const loadCallback = useCallback(() => {
    if (firstLoad)
      setFirstLoad(false)
  }, [])

  //handle when change items to validate changes
  const changeIds = useCallback((value, field = "id") => {
    //", value)
    let idsN = value.map(x => x[field].toString());
    if (!_.isEqual(ids, idsN)) {
      setIds(idsN)
    }
  }, []);

  //Return selector with compute data
  let data = useSelector(
    (state) => dataSelector(state)(ids, idProperty, propertiesToValidate, firstLoad, loadCallback, validateAll, callbackSingleChange), (prevProps, nextProps) => {
      return _.isEqual(prevProps, nextProps)
    })

  return [data, changeIds]
} */

/**
 * Hook to show map in SidePanel
 */
export const useSidePanelMap = () => {


  const [showMap, setShowMap] = useState(false)
  const [pointers, setPointers] = useState([])
  const landmark = useSelector(state => state.reportsMainRTRedux.landmark)

  const mapRef = useRef()
  /**
   * Callback to open or close map and send to inital pointers
   */
  const onToggleSideMap = useCallback((show, _pointers) => {
    setShowMap(value => show || !value)
    if (!show) {
      setPointers([])
    }
    mapRef.current = null;
    //setShowMap(false)
    if (_pointers && show) {
      setPointers(_pointers)
      mapRef?.current?.centerPointOnMap(_pointers?.latitude || 0, _pointers?.longitude || 0, 15);
    }
  }, [])
  useEffect(() => {
    if (mapRef?.current?.drawLandmark && landmark) {
      const { radius, coordinatesCenter } = getCordinatesAndRadius(landmark)
      mapRef.current.drawLandmark(landmark, radiusFeetToMeters(radius), coordinatesCenter);
      mapRef.current.centerByFitBounds(coordinatesCenter, radius)
    }
  }, [mapRef?.current?.drawLandmark]);
  /**
   * Callback to change pointers into the map
   */
  const onChangePointers = useCallback((_pointers) => {
    setPointers(_pointers)
    if (_pointers) {
      mapRef?.current?.centerPointOnMap(_pointers?.latitude || 0, _pointers?.longitude || 0, 15);
    }
  }, [])

  /**
   * Map Component
   */


  /*<SidePanel style={{ zIndex: 2, width: showMap ? '70%' : '' }} isOpen={showMap} toggle={() => onToggleSideMap()} >
    <SidePanelBody hiddenButton={props.hiddenButton || false}>
      <Map units={pointers} noRealtime={true}>
      </Map>
    </SidePanelBody>
  </SidePanel>*/

  //style="z-index: -1;position: fixed;inset: 0px;background-color: rgba(0, 0, 0, 0.5);-webkit-tap-highlight-color: transparent;"

  const SideMapComponent = (props) => {
    return (
      <SidePanel style={{ zIndex: 20, width: showMap ? '70%' : '' }} hiddenButton={props.hiddenButton || false}
        overlay={true} isOpen={showMap} toggle={() => onToggleSideMap()} overlayClass={props.overlayClass} >
        <SidePanelHeader>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <h2 style={{ margin: 0 }}>{props.title}</h2>
            </Grid>
          </Grid>
        </SidePanelHeader>
        <SidePanelBody>
          {
            showMap && <Map
              ref={mapRef}
              units={pointers}
              noRealtime={true}
              hiddenButton={true}
              hiddenTraffic={true}
              hiddenWeather={true}
              hiddenLegends={true}
              hiddenSearchMap={true}
              {...props}
            >
            </Map>
          }
        </SidePanelBody>
      </SidePanel>
    )
  }
  return [SideMapComponent, onToggleSideMap, mapRef, onChangePointers];
}


export const useTimeFrameComponent = (timeFrame, date, isModal = false, defaultFrame = 'thisMonth') => {
  const [option, setOption] = useState()
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const onChangeSelect = useCallback((value, newStart, newEnd) => {
    let start, end
    switch (value?.id) {
      case 'today':
        start = moment().startOf('day').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'yesterday':
        start = moment().subtract(1, 'days').startOf('day').toDate()
        end = moment.max(moment().subtract(1, 'days')).endOf('day').toDate()
        break;
      case 'last24hours':
        start = moment().subtract(24, 'hours').startOf('day').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'week':
        start = moment().day(0).startOf('day').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'lastWeek':
        start = moment().day(-7).startOf('day').toDate()
        end = moment().day(-1).endOf('day').toDate()
        break;
      case 'last7days':
        start = moment().subtract(7, 'days').startOf('day').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'last15days':
        start = moment().subtract(15, 'days').startOf('day').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'thisMonth':
        start = moment().startOf('month').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'last30Days':
        start = moment().subtract(30, 'days').startOf('day').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'last90Days':
        start = moment().subtract(90, 'days').startOf('day').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'lastMonth':
        start = moment(moment()).subtract(1, 'months').startOf('month').toDate()
        end = moment(moment()).subtract(1, 'months').endOf('month').toDate()
        break;
      case 'quarter':
        start = moment().startOf('quarter').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'last3month':
        start = moment().subtract(3, 'months').startOf('day').toDate()
        end = moment.max(moment()).endOf('day').toDate()
        break;
      case 'lastQuarter':
        const quarter = moment().quarter();
        start = moment().quarter(quarter - 1).startOf('quarter').toDate()
        end = moment().quarter(quarter - 1).endOf('quarter').toDate()
        break;

      case 'custom':
        start = newStart || date?.start || moment().startOf('day').toDate()
        end = newEnd || date?.end || moment().endOf('day').toDate();
        break;
    }
    setOption(value)
    setStartDate(start)
    setEndDate(end)
  })

  const reset = useCallback(() => {
    onChangeSelect(timeFrame.find(x => x.id === defaultFrame));
  })

  const defaultvalue = useCallback((value) => {
    value && onChangeSelect(timeFrame.find(x => x.id === value));
  })


  if (!option)
    onChangeSelect(timeFrame.find(x => x.id === defaultFrame))

  const onChangeDateRange = (value) => {
    const { start, end } = value;
    const newStart = start && new Date(start);
    const newEnd = end && new Date(end);
    // Set New Date Range
    setStartDate(newStart);
    setEndDate(newEnd);
  };

  // we received the time in case the use select just the startDate and not the endDate.
  const handleCloseModal = (endTime) => {
    if (isModal) {
      if (!startDate) {
        let start = moment().startOf('day').toDate()
        setStartDate(start);
      }

      if (!endDate) {
        if (startDate) {
          if (endTime) {
            const newEndTime = moment(endTime);
            let newEndDate = moment(startDate)
              .set('hour', newEndTime.hours())
              .set('minute', newEndTime.minutes())
              .toDate();
            setEndDate(newEndDate);
          } else {
            setEndDate(startDate);
          }
        } else {
          let end = moment.max(moment()).endOf('day').toDate()
          setEndDate(end);
        }

      }
    }
  }
  return {
    onChangeSelect,
    onChangeDateRange,
    reset,
    option,
    startDate,
    endDate,
    options: timeFrame,
    defaultvalue,
    handleCloseModal
  }

}
/**
 * Hook to use geocoder to identify lat, lng and direction.
 */
export const useGeocoder = () => {

  //const mapReference = useRef()
  const [address, setAddress] = useState()

  const onGetGeocoder = useCallback((params) => {
    getGeocoder({
      from: params.from, //latlng, address, placeId
      lat: params?.lat,
      lng: params?.lng,
      address: params?.address,
      placeId: params?.placeId || '',
      id: params?.id || '', //stablish the id for the petition
      callback: (value) => { setAddress(value) }
    })
  })

  return [onGetGeocoder, address, setAddress]

}

// IMPORT EXTERNAL SCRIPT
export const importExternalScript = resourceUrl => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);
};

/** hook for handle big amounts of data on local on scroll */
export const useInfiniteScroll = (callback, element) => {

  const [isFetching, setIsFetching] = useState(false);
  const [lastHeightDetected, setLastHeightDetected] = useState(null);

  useEffect(() => {
    const elementToWatch = element.current
    elementToWatch.addEventListener('scroll', handleScroll);
    return () => elementToWatch.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    callback();
  }, [isFetching]);

  function handleScroll() {
    if (element.current.scrollTop > (element.current.scrollHeight - (element.current.offsetHeight * 2.5)) && element.current.scrollTop != lastHeightDetected) {
      setIsFetching(true);
      setLastHeightDetected(element.current.scrollTop)
    }
  }

  return [isFetching, setIsFetching];
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const useWindowSize = (onResize) => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if (onResize)
        onResize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export const useReportTypeOptions = (user) => {
  const { messages } = useIntl();

  const reportTypeOptions = [
    {
      id: 'basic',
      label: messages['reportsMainRT_basic'],
      options: [
        { id: "simpleReport", name: messages['reportsMainRT_simpleReport'], info: messages['reportsMainRT_simpleReportInfo'], disabled: disableReportInSelect(user, ['70'], 'sur') },
        { id: "speedReport", name: messages['reportsMainRT_speedReport'], info: messages['reportsMainRT_speedReportInfo'], disabled: disableReportInSelect(user, ['71'], 'spr'), advancedFilter: true },
        { id: "detailedReport", name: messages['reportsMainRT_detailedReport'], info: messages['reportsMainRT_detailedReportInfo'], disabled: disableReportInSelect(user, ['69'], 'der') },
        { id: "mileageReport", name: messages['reportsMainRT_mileageReport'], info: messages['reportsMainRT_mileageReportInfo'], disabled: disableReportInSelect(user, ['72'], 'mir') },
        { id: "mileageSummaryReport", name: messages['reportsMainRT_mileageSummaryReport'], info: messages['reportsMainRT_mileageSummaryReportInfo'], disabled: disableReportInSelect(user, ['93'], 'ms') },
        { id: "driverMileageSummaryReport", name: messages['reportsMainRT_driverMileageSummaryReport'], info: messages['reportsMainRT_driverMileageSummaryReportInfo'], disabled: disableReportInSelect(user, ['267'], 'drmisumm') },
        { id: "utilizationReport", name: messages['reportsMainRT_utilization'], info: messages['reportsMainRT_utilizationInfo'], disabled: disableReportInSelect(user, ['221'], 'utlz') },
        { id: "summaryReport", name: messages['reportsMainRT_summaryReport'], info: messages['reportsMainRT_summaryReportInfo'], disabled: disableReportInSelect(user, ['68'], 'sum') },
        { id: "smartOneCUtilizationReport", name: messages['reportsMainRT_smartOneCUtilizationReport'], info: messages['reportsMainRT_smartOneCUtilizationReportInfo'], disabled: disableReportInSelect(user, ['224'], 'socutil'), advancedFilter: true },
        { id: "advancedSummaryReport", name: messages['reportsMainRT_advancedSummaryReport'], info: messages['reportsMainRT_advancedSummaryReportInfo'], disabled: disableReportInSelect(user, ['144'], 'avs') },
        { id: "hourOfUseReport", name: messages['reportsMainRT_hourOfUseReport'], info: messages['reportsMainRT_hourOfUseReportInfo'], disabled: disableReportInSelect(user, ['254'], 'hoofus') },

      ],
    },
    {
      id: 'diagnostics',
      label: messages['reportsMainRT_diagnostics'],
      options: [
        { id: "diagnosticParametersReport", name: messages['reportsMainRT_diagnosticParametersReport'], info: messages['reportsMainRT_diagnosticParametersReportInfo'], disabled: disableReportInSelect(user, ['116'], 'dpa') },
        { id: "diagnosticIndicatorsReport", name: messages['reportsMainRT_DiagnosticIndicatorsReport'], info: messages['reportsMainRT_DiagnosticIndicatorsReportInfo'], disabled: disableReportInSelect(user, ['115'], 'did') }, // TODO submodule-approved
        { id: "jbDiagnosticsReport", name: messages['reportsMainRT_jbDiagnostics'], info: messages['reportsMainRT_jbDiagnosticsInfo'], disabled: disableReportInSelect(user, ['118'], 'jd') }, // TODO submodule-approved
        { id: "jbSummaryReport", name: messages['reportsMainRT_JBSummaryReport'], info: messages['reportsMainRT_JBSummaryReportInfo'], disabled: disableReportInSelect(user, ['119'], 'js') }, // TODO submodule-approved
        { id: "jbdtcReport", name: messages['reportsMainRT_jbdtcReport'], info: messages['reportsMainRT_jbdtcReportInfo'], disabled: disableReportInSelect(user, ['123'], 'jdtc') }, // TODO submodule-approved

      ],
    },
    {
      id: 'event',
      label: messages['reportsMainRT_event'],
      options: [
        { id: "actReport", name: messages['reportsMainRT_actReport'], info: messages['reportsMainRT_actReportInfo'], disabled: disableReportInSelect(user, ['238'], 'acr') },
        { id: "idleReport", name: messages['reportsMainRT_idleReport'], info: messages['reportsMainRT_idleReportInfo'], disabled: disableReportInSelect(user, ['74'], 'idl'), advancedFilter: true }, // TODO submodule-approved
        { id: "startStopIdleReport", name: messages['reportsMainRT_startStopIdleReport'], info: messages['reportsMainRT_startStopIdleReportInfo'], disabled: disableReportInSelect(user, ['73'], 'ssi') }, // TODO submodule-approved
        { id: "ignitionReport", name: messages['reportsMainRT_ignitionReport'], info: messages['reportsMainRT_ignitionReportInfo'], disabled: disableReportInSelect(user, ['76'], 'ign') }, // TODO submodule-approved
        { id: "stopReport", name: messages['reportsMainRT_stopReport'], info: messages['reportsMainRT_stopReportInfo'], disabled: disableReportInSelect(user, ['75'], 'stp'), advancedFilter: true },
        { id: "driverSafetyReport", name: messages['reportsMainRT_driverSafetyReport'], info: messages['reportsMainRT_driverSafetyReportInfo'], disabled: disableReportInSelect(user, ['99'], 'sfy') }, // TODO submodule-approved
        { id: "eventReport", name: messages['reportsMainRT_eventReport'], info: messages['reportsMainRT_eventReportInfo'], disabled: disableReportInSelect(user, ['79'], 'evt'), advancedFilter: true }, // TODO submodule-approved
        { id: "timesheetReport", name: messages['reportsMainRT_timesheetReport'], info: messages['reportsMainRT_timesheetReportInfo'], disabled: disableReportInSelect(user, ['77'], 'tsr') }, // TODO submodule-approved
        { id: "movingReport", name: messages['reportsMainRT_movingReport'], info: messages['reportsMainRT_movingReportInfo'], disabled: disableReportInSelect(user, ['80'], 'sst'), advancedFilter: true }, // TODO submodule-approved
        { id: "inputReport", name: messages['reportsMainRT_inputReport'], info: messages['reportsMainRT_inputReportInfo'], disabled: disableReportInSelect(user, ['18'], 'inp') }, // TODO submodule-approved

      ],
    },
    {
      id: 'fsm',
      label: messages['reportsMainRT_fsm'],
      options: [
        { id: "chatHistoryReport", name: messages['reportsMainRT_legacyChatHistory'], info: messages['reportsMainRT_legacyChatHistoryInfo'], disabled: disableReportInSelect(user, ['58'], 'fch'), hideUnits: true }, // TODO submodule-approved
        { id: "stopHistoryReport", name: messages['reportsMainRT_stopHistory_legacy'], info: messages['reportsMainRT_stopHistoryInfo'], disabled: disableReportInSelect(user, ['58'], 'fsh'), hideUnits: true },
        { id: "driverFormsReport", name: messages['reportsMainRT_driverFormsReport'], info: messages['reportsMainRT_driverFormsReportInfo'], disabled: disableReportInSelect(user, ['58'], 'fdf'), hideUnits: true, advancedFilter: true }, // TODO submodule-approved
        { id: "fsmTimeCardReport", name: messages['reportsMainRT_fsmTimeCardReport'], info: messages['reportsMainRT_fsmTimeCardReportInfo'], disabled: disableReportInSelect(user, ['76'], 'sfy'), hideUnits: true },
        { id: "stopSummaryReport", name: messages['reportsMainRT_stopSummaryReport_legacy'], info: messages['reportsMainRT_stopSummaryReportInfo'], disabled: disableReportInSelect(user, ['58'], 'ssr'), hideUnits: true }, // TODO submodule-approved
        { id: "driverStatusReport", name: messages['reportsMainRT_driverStatusReport'], info: messages['reportsMainRT_driverStatusReportInfo'], disabled: disableReportInSelect(user, ['141'], 'dvcsuminfo'), hideUnits: true }, // TODO submodule-approved
        { id: "fsmCombinedReport", name: messages['reportsMainRT_fsmCombinedReport'], info: messages['reportsMainRT_fsmCombinedReportInfo'], disabled: disableReportInSelect(user, ['58'], 'fc'), hideUnits: true }, // TODO submodule-approved
        { id: "formStopSummaryReport", name: messages['reportsMainRT_formStopSummaryReport'], info: messages['reportsMainRT_formStopSummaryReportInfo'], disabled: disableReportInSelect(user, ['58'], 'ffss'), hideUnits: true, advancedFilter: true },

      ],
    },
    {
      id: 'maintenance',
      label: messages['reportsMainRT_maintenance'],
      options: [
        { id: "maintenanceItemsReport", name: messages['reportsMainRT_vehicleMaintenanceItemsReport'], info: messages['reportsMainRT_vehicleMaintenanceItemsReportInfo'], disabled: disableReportInSelect(user, ['229'], 'vehiclemaintenanceitems') }, // TODO submodule-approved
        { id: "serviceItemsReport", name: messages['reportsMainRT_vehServiceItemsReport'], info: messages['reportsMainRT_vehServiceItemsReportInfo'], disabled: disableReportInSelect(user, ['230'], 'vehicleserviceitems'), advancedFilter: true }, // TODO submodule-approved,
        { id: "vehicleMaintenanceItemsReport", name: messages['reportsMainRT_vehicleMaintenanceItemsReportLegacy'], info: messages['reportsMainRT_vehicleMaintenanceItemsReportInfo'], disabled: disableReportInSelect(user, ['91'], 'vmi') }, // TODO submodule-approved
        { id: "vehServiceItemsReport", name: messages['reportsMainRT_vehServiceItemsReportLegacy'], info: messages['reportsMainRT_vehServiceItemsReportInfo'], disabled: disableReportInSelect(user, ['92'], 'vsi'), advancedFilter: true }, // TODO submodule-approved
      ],
    },
    {
      id: 'location',
      label: messages['reportsMainRT_Location'],
      options: [
        { id: "stateMileageReport", name: messages['reportsMainRT_stateMileageReport'], info: messages['reportsMainRT_stateMileageReportInfo'], disabled: disableReportInSelect(user, ['82'], 'stm') }, // TODO submodule-approved
        { id: "landmarkReport", name: messages['reportsMainRT_landmarkReport'], info: messages['reportsMainRT_landmarkReportInfo'], disabled: disableReportInSelect(user, ['83'], 'lmi') }, // TODO submodule-approved
        { id: "latitudeLongitudeReport", name: messages['reportsMainRT_latitudeLongitudeReport'], info: messages['reportsMainRT_latitudeLongitudeReportInfo'], disabled: disableReportInSelect(user, ['84'], 'lalo') }, // TODO submodule-approved
        { id: "landmarkBasedReport", name: messages['reportsMainRT_landmarkBasedReport'], info: messages['reportsMainRT_landmarkBasedReportInfo'], disabled: disableReportInSelect(user, ['85'], 'lmb'), advancedFilter: true }, // TODO submodule-approved
        { id: "geofenceBasedReport", name: messages['reportsMainRT_geofenceBasedReport'], info: messages['reportsMainRT_geofenceBasedReportInfo'], disabled: disableReportInSelect(user, ['128'], 'gfb'), advancedFilter: true },
        { id: "landmarkEngineHoursReport", name: messages['reportsMainRT_landmarkEngineHoursReport'], info: messages['reportsMainRT_landmarkEngineHoursReportInfo'], disabled: disableReportInSelect(user, ['117'], 'tsr'), advancedFilter: true },
        { id: "landmarkSummaryReport", name: messages['reportsMainRT_landmarkSummaryReport'], info: messages['reportsMainRT_landmarkSummaryReportInfo'], disabled: disableReportInSelect(user, ['135'], 'nrr'), advancedFilter: true }, // TODO submodule-approved
        { id: "landmarkCrewReport", name: messages['reportsMainRT_landmarkCrewReport'], info: messages['reportsMainRT_landmarkCrewReportInfo'], disabled: disableReportInSelect(user, ['127'], 'lmc') }, // TODO submodule-approved
        { id: "geofenceReport", name: messages['reportsMainRT_geofenceReport'], info: messages['reportsMainRT_geofenceReportInfo'], disabled: disableReportInSelect(user, ['81'], 'gfr') }, // TODO submodule-approved
        { id: "locationReport", name: messages['reportsMainRT_locationReport'], info: messages['reportsMainRT_locationReportInfo'],disabled: disableReportInSelect(user, ['89'], 'loc') }, // TODO submodule-approved
        { id: "stateMileageCrossingReport", name: messages['reportsMainRT_stateMileageCrossingReport'], info: messages['reportsMainRT_stateMileageCrossingInfo'],disabled: disableReportInSelect(user, ['240'], 'smicross') }, // TODO submodule-approved
        { id: "stateMileageSummariesReport", name: messages['reportsMainRT_stateMileageSummariesReport'], info: messages['reportsMainRT_stateMileageSummariesReportInfo'],disabled: disableReportInSelect(user, ['240'], 'smicross') } // TODO submodule-approved
      ],
    },
    {
      id: 'driverApp',
      label: messages['generatedReport_driverApp'],
      options: [
        { id: "driverStatusReportV2", name: messages['reportsMainRT_driverStatusReportV2'], info: messages['reportsMainRT_driverStatusReportV2Info'], disabled: disableReportInSelect(user, ['58'], 'fds'), hideUnits: true },
        { id: "driverFormsReportV2", name: messages['reportsMainRT_driverFormsReportV2'], info: messages['reportsMainRT_driverFormsReportV2Info'], disabled: disableReportInSelect(user, ['252'], 'dapprfr'), hideUnits: true, advancedFilter: true },
        { id: "timeCardReportV2", name: messages['reportsMainRT_timecardv2'], info: messages['reportsMainRT_fsmTimeCardReportInfoV2'], disabled: disableReportInSelect(user, ['251'], 'dapprtc'), hideUnits: true },
        { id: "formStopSummaryReportV2", name: messages['reportsMainRT_formStopSummaryReportV2'], info: messages['reportsMainRT_formStopSummaryReportInfo'], disabled: disableReportInSelect(user, ['255'], 'dappfss'), hideUnits: true, advancedFilter: true },
        { id: "chatHistoryReportV2", name: messages['reportsMainRT_chatHistory'], info: messages['reportsMainRT_chatHistoryInfo'], disabled: disableReportInSelect(user, ['253'], 'dapprcht'), hideUnits: true }, // TODO submodule-approved
        { id: "stopHistoryReportV2", name: messages['reportsMainRT_stopHistory'], info: messages['reportsMainRT_stopHistoryInfo'], disabled: disableReportInSelect(user, ['123'], 'jdtc'), hideUnits: true },
        { id: "stopSummaryReportV2", name: messages['reportsMainRT_stopSummaryReport'], info: messages['reportsMainRT_stopSummaryReportInfo'], disabled: disableReportInSelect(user, ['258'], 'dappstsr'), hideUnits: true },
        { id: "fsmCombinedReportV2", name: messages['reportsMainRT_fsmCombinedReportV2'], info: messages['reportsMainRT_fsmCombinedReportInfo'], disabled: disableReportInSelect(user, ['256'], 'dappcombr'), hideUnits: true },
      ]
    }
    ,
    {
      id: 'others',
      label: messages['reportsMainRT_others'],
      options: [
        { id: "odometerReport", name: messages['reportsMainRT_odometerReport'], info: messages['reportsMainRT_odometerReportInfo'], disabled: disableReportInSelect(user, ['143'], 'or') }, // TODO submodule-approved
        { id: "pingReport", name: messages['reportsMainRT_pingReport'], info: messages['reportsMainRT_pingReportInfo'], disabled: disableReportInSelect(user, ['88'], 'ping') }, // TODO submodule-approved
        { id: "temperatureReport", name: messages['reportsMainRT_temperatureReport'], info: messages['reportsMainRT_temperatureReportInfo'], disabled: disableReportInSelect(user, ['87'], 'temp') }, // TODO submodule-approved
        { id: "nonReportingReport", name: messages['reportsMainRT_NonReportingReport'], info: messages['reportsMainRT_NonReportingReportInfo'], disabled: disableReportInSelect(user, ['135'], 'nrr') },
        { id: "driverTimesheetReport", name: messages['reportsMainRT_driverTimesheetReport'], info: messages['reportsMainRT_driverTimesheetReportInfo'], disabled: disableReportInSelect(user, ['86'], 'dtsr') }, // TODO submodule-approved
        { id: "UnitInfoReport", name: messages['reportsMainRT_UnitInfoReport'], info: messages['reportsMainRT_UnitInfoReportInfo'], disabled: disableReportInSelect(user, ['90'], 'ui') }, // TODO submodule-approved
        { id: "alertLogReport", name: messages['reportsMainRT_alertLogReport'], info: messages['reportsMainRT_alertLogReportInfo'], disabled: disableReportInSelect(user, ['125'], 'log') }, // TODO submodule-approved
        { id: "engineHoursReport", name: messages['reportsMainRT_EngineHoursReport'], info: messages['reportsMainRT_EngineHoursReportInfo'], disabled: disableReportInSelect(user, ['117'], 'teh') }, // TODO submodule-approved
        { id: "fuelCardReport", name: messages['reportsMainRT_fuelCardReport'], info: messages['reportsMainRT_fuelCardReportInfo'], disabled: disableReportInSelect(user, ['96'], 'fcr') }, // TODO submodule-approved
        { id: "deviceSummaryInfoReport", name: messages['reportsMainRT_deviceSummaryInfoReport'], info: messages['reportsMainRT_deviceSummaryInfoReportInfo'], disabled: disableReportInSelect(user, ['141'], 'dvcsuminfo') }, // TODO submodule-approved
        { id: "milesPerGallonReport", name: messages['reportsMainRT_milesPerGallonReport'], info: messages['reportsMainRT_milesPerGallonReportInfo'], disabled: disableReportInSelect(user, ['97'], 'mpg') }, // TODO submodule-approved
        { id: "behaviorReport", name: messages['reportsMainRT_behaviorReport'], info: messages['reportsMainRT_behaviorReportInfo'], disabled: disableReportInSelect(user, ['259'], 'behrep'), showJustInSchedule: true },
      ],
    }
  ];



  const getNameReportType = (id) => {
    const category = reportTypeOptions.find((reportType) => reportType.options.find((res) => res.id === id));
    return category.options.find((res) => res.id === id)?.name || '';
  }

  function getSortReportOptions() {
    const sortInternal = reportTypeOptions.map((category) => {
      category.options.sort((a, b) => a.name?.localeCompare(b.name));
      return category;
    })
    return sortInternal.sort((a, b) => a.label?.localeCompare(b.label));
  }

  return {
    reportTypeOptions: getSortReportOptions(),
    getNameReportType
  }
}

export const useTableReport = ({ data, report, messages, hideHeader = true, enableSorting = true, groups = [], onChangeExpandable,
  renderExpandableRow, classNameColumns, propertyToShowExpandable = 'label', showExpandable = false, hiddenPagination = false, customLoading, customOnChangePage, hideNumberOfRows, totalItems, centerPagination, serverSideTable, hideSelectorUnitsDriver }) => {
  const dispatch = useDispatch();
  const { loading, filters } = report
  const { filterArray, filterType } = filters;
  const [unitDriverSelected, setUnitDriverSelected] = useState([]);

  let newData = data;
  if (!data) {
    const { data } = report
    newData = data;
  }

  return {
    loading: customLoading || loading,
    asyncLoading: report.asyncLoading,
    data: newData || [],
    totalItems: totalItems ?? (report.data.total),
    groups,
    renderExpandableRow,
    idRenderExpandableRow: onChangeExpandable,
    hideHeader,
    classNameColumns,
    enableSorting,
    defaultSortField: "deviceId",
    defaultSortOrder: "asc",
    noDataMessage: messages["reportsMainRT_noDataMessage"],
    propertyToShowExpandable,
    valueSelector: unitDriverSelected,
    itemsSelector: filterArray,
    textFieldSelector: getFilterNameByEntity(filterType),
    placeholderSelector: getPlaceholderSelector(filterType, messages),
    onChangedPage: customOnChangePage || ((page, rowsPerPage) => {
      let currentReport = report;
      currentReport.exportType = "json";
      currentReport.filters = { ...currentReport.filters, unitsSelected: unitDriverSelected };
      dispatch(reportsMainGenerateReportUpdate(currentReport, page, rowsPerPage))
    }),
    onChangeSelector: (value) => {
      setUnitDriverSelected(value)
      let currentReport = report;
      currentReport.filters = { ...currentReport.filters, unitsSelected: value };
      dispatch(reportsMainGenerateReportUpdate(currentReport, 1, 10));
    },
    showExpandable,
    hiddenPagination,
    hideNumberOfRows,
    centerPagination,
    serverSideTable,
    hideSelectorUnitsDriver
  }
}

export const useEntityTag = (entities = []) => {
  const [currentEntities, setCurrentEntities] = useState(entities)
  const [selectedEntity, setSelectedEntity] = useState(entities[0]);
  const [selectedSingleUnitsEntity, setSelectedSingleUnitsEntity] = useState([]);

  return {
    entities: currentEntities,
    value: selectedSingleUnitsEntity,
    entity: selectedEntity,
    onChangeEntity: (newSelectedEntity) => {
      setSelectedEntity(newSelectedEntity)
      setSelectedSingleUnitsEntity([])
    },
    onChange: (selectedItems) => {
      setSelectedSingleUnitsEntity(selectedItems)
    },
    hideUnits: () => {
      const newEntities = entities.filter(entity => entity.id != "Unit")
      setCurrentEntities(newEntities)
      setSelectedEntity(newEntities[0])
      setSelectedSingleUnitsEntity([])
    },
    showAll: () => {
      setCurrentEntities(entities)
    }
  }
}


export const useNewEntityTag = (entities = [], entityLabel, height, optionLabel = "Select option", defaultOption = 'tags', useIncludesInactive = false) => {
  const [values, setValues] = useState([]);
  const [option, setOption] = useState({ id: defaultOption });
  const [entity, setEntity] = useState({ id: "Unit" });
  const [currentEntities, setCurrentEntities] = useState(entities)
  const [includeInactiveDrivers, setIncludeInactiveDrivers] = useState(false)
  let entityVariable = entity;
  let optionVariable = option;
  let includeInactiveDriversVariable = includeInactiveDrivers;

  return {
    entities: currentEntities,
    entityLabel,
    height,
    optionLabel,
    onChange: (newItems) => {
      if (useIncludesInactive && entityVariable.id === 'Driver' && optionVariable.id === 'entities' && !includeInactiveDriversVariable){
        const includesItems = newItems.filter(driver => !driver.status === includeInactiveDriversVariable)
        setValues(includesItems);
      }
      else
        setValues(newItems);
    },
    values: values,
    option: option,
    onOptionChange: (newOption) => {
      setValues([]);
      setOption(newOption);
      optionVariable = newOption;
    },
    entity,
    onChangeEntity: (newEntity) => {
      setEntity(newEntity)
      entityVariable = newEntity
    },
    hideUnits: () => {
      const newEntities = entities.filter(entity => entity != "Unit")
      setCurrentEntities(newEntities)
      setEntity({ id: "Driver" })
      entityVariable = { id: "Driver" }
      setValues([])
    },
    showAll: () => {
      setCurrentEntities(entities)
    },
    onValueChange: (newValue) => {
      setValues(newValue);
    },
    includeInactiveDrivers,
    onOptionChangeStatus: (newValue) => {
      setIncludeInactiveDrivers(newValue);
      includeInactiveDriversVariable = newValue;
      setEntity({ id: "Driver" })
      entityVariable = { id: "Driver" }
    }
  }
}


export const useReportCustomFields = (haveUntagged) => {
  const initialState = {
    advancedFilter: 0,
    speed: 0,
    speedLimitDelta: 0,
    minute: 0,
    checkIdleProductive: false,
    excludeIdle: false,
    form: 1,
    formView: "all",
    event: 'all',
    geofenceBaseView: "all",
    landmarkBaseView: "all",
    risk: ""
  }

  const [landmarks, setLandmarks] = useState([]);
  const [geofences, setGeofences] = useState([]);
  const [otherFilters, setOtherFilters] = useState(initialState)
  const [allForms, setAllForms] = useState([])

  const landmarkTags = useSelector((state) => state.realtimeMapLandmarkListModuleRedux.landmarkTags);
  const landmarkTagsLoading = useSelector((state) => state.realtimeMapLandmarkListModuleRedux.landmarkTagsLoading);
  const jsonLandmark = useSelector((state) => state.realtimeMapRedux.jsonLandmark);
  const loadingLandmarkJson = useSelector((state) => state.realtimeMapRedux.loadingLandmarkJson);

  const forms = useSelector(state => state.catalogsRedux.forms);

  const geofenceTags = useSelector((state) => state.realtimeMapNewGeofenceModuleRedux.geofenceTags);
  const geofenceTagsLoading = useSelector((state) => state.realtimeMapNewGeofenceModuleRedux.geofenceTagsLoading);
  const jsonGeofence = useSelector((state) => state.realtimeMapRedux.jsonGeofence);
  const loadingGeofenceJson = useSelector((state) => state.realtimeMapRedux.loadingGeofenceJson);


  const { messages } = useIntl();
  const dispatch = useDispatch();

  // <---- Select Event Type ---->
  const selectEventTypeOptions = [
    { id: "all", label: messages['generatedReport_allEvents'] },
    { id: "Drive", label: messages['generatedReport_drive'] },
    { id: "Ignition Off", label: messages['generatedReport_ignitionOff'] },
    { id: "Ignition On", label: messages['generatedReport_ignitionOn'] },
    { id: "Travel Start", label: messages['generatedReport_travelStart'] },
    { id: "Travel Stop", label: messages['generatedReport_travelStop'] },
    { id: "Main Power Off", label: messages['generatedReport_mainPowerOff'] },
    { id: "Main Power On", label: messages['generatedReport_mainPowerOn'] },
    { id: "Input Off", label: messages['generatedReport_inputOff'] },
    { id: "Input On", label: messages['generatedReport_inputOn'] },
    { id: "Antenna Disconnect", label: messages['generatedReport_antennaDisconnect'] },
    { id: "Antenna Connect", label: messages['generatedReport_antennaConnect'] },
    { id: "Internal Battery Disconnect", label: messages['generatedReport_internalBatteryDisconnect'] },
    { id: "Internal Battery Connect", label: messages['generatedReport_internalBatteryConnect'] },
  ];

  const landmarkViewOptions = [
    { id: "single", label: messages['reportsMainRT_singleLandmark'] },
    { id: "tags", label: messages['reportsMainRT_tagLandmarkView'] },
    { id: "all", label: messages['reportsMainRT_allLandmarkView'] }
  ];

  const geofenceViewOptions = [
    { id: "single", label: messages['reportsMainRT_singleGeofence'] },
    { id: "tags", label: messages['reportsMainRT_tagGeofenceView'] },
    { id: "all", label: messages['reportsMainRT_allGeofenceView'] }
  ];

  const driverViewOptions = [
    { id: "single", label: messages['reportsMainRT_singleForm'] },
    { id: "all", label: messages['reportsMainRT_allFormView'] }
  ];
  // End different view options
  useEffect(() => {
    dispatch(mapGetLandmarkTags());
    dispatch(getKmls())
    dispatch(catalogLoad(['forms']));
  }, []);

  useEffect(() => {
    dispatch(mapGetGeofenceTags())
  }, []);

  useEffect(() => {
    getAllLandmarks()
  }, [landmarkTags, jsonLandmark]);

  useEffect(() => {
    getAllGeofences()
  }, [geofenceTags, jsonGeofence])

  useEffect(() => {
    setAllForms([{ id: 1, name: messages['entityTagPicker_allForm'] }, ...forms])
  }, [forms])

  useEffect(() => {
    if (!(otherFilters?.advancedFilter)) {
      setOtherFilters({ ...initialState });
    }
  }, [otherFilters?.advancedFilter])

  const getGeofencesTags = () => {
    if (haveUntagged) {
      let tagItems = [];
      tagItems = [...tagItems, ...geofenceTags];
      return tagItems ? OrderData(tagItems, "name") : []
    }
  }

  const getAllLandmarks = () => {
    let newLandmarks = [];
    if (landmarkTags && landmarkTags.length > 0) {
      if (haveUntagged) {
        newLandmarks = OrderData(jsonLandmark, 'name');
      } else {
        //only groups to enable this user
        landmarkTags.forEach((tag) => {
          newLandmarks = [
            ...newLandmarks,
            ...jsonLandmark.filter((item) => {
              if (item.tagsId) {
                const tagsIds = item.tagsId.split(',')
                return tagsIds.includes(tag.id.toString())
              }
              return false;
            })
          ];
        })
        newLandmarks = OrderData(newLandmarks, 'name');
      }
      const toSetLandmarks = [...newLandmarks]
      setLandmarks(toSetLandmarks)
    } else {
      setLandmarks([]);
    }
  }

  const clear = () => { }

  const getAllGeofences = () => {
    let newGeofences = [];
    if (geofenceTags && geofenceTags.length > 0) {
      if (haveUntagged) {
        newGeofences = OrderData(jsonGeofence, 'name');
      } else {
        //only groups to enable this user
        geofenceTags.forEach((tag) => {
          newGeofences = [
            ...newGeofences,
            ...jsonGeofence.filter((item) => {
              if (item.tagsId) {
                const tagsIds = item.tagsId.split(',')
                return tagsIds.includes(tag.id.toString())
              }
              return false;
            })
          ];
        })
        newGeofences = OrderData(newGeofences, 'name');
      }
      const toSetData = [...newGeofences]
      setGeofences(toSetData);
    } else {
      if (haveUntagged) {
        newGeofences = OrderData(jsonGeofence, 'name');
        setGeofences(newGeofences);
      } else {
        setGeofences([]);
      }
    }
  }

  const getTags = (haveUntagged) => {
    if (haveUntagged) {
      let tagItems = []
      /*tagItems.push({
          id: 0,
          name: messages['entityTagPicker_untagged'],
          color: styles.color_untagged,
          visible: true,
          open: false,
          items: []
      })*/
      tagItems = [...tagItems, ...landmarkTags];
      return tagItems ? OrderData(tagItems, "name") : []
    }
    return landmarkTags ? OrderData(landmarkTags, "name") : []
  }

  const tags = getTags()
  const onChangeOtherFilters = (value) => {
    setOtherFilters(value);
  }

  const restartOtherFilters = () => {
    setOtherFilters(initialState);
  }

  return {
    clear,
    getGeofencesTags,
    getAllLandmarks,
    landmarks,
    geofences,
    getAllGeofences,
    tags,
    onChangeOtherFilters,
    landmarkTagsLoading,
    loadingLandmarkJson,
    geofenceTagsLoading,
    loadingGeofenceJson,
    selectEventTypeOptions,
    landmarkViewOptions,
    geofenceViewOptions,
    driverViewOptions,
    otherFilters,
    restartOtherFilters,
    allForms
  }

}


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

/**************************************************
 * Hooks to get size from UI - use from map when is no posible use CSS
 * (when @mediaQuery is not avaiable in this part use this hook)
 * @returns
 **************************************************/
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export const useCustomTimeRange = (format = "HH:mm") => {
  const [stateCustomTimeRange, setStateCustomTimeRange] = useState([])
  const [isValidCustomTime, setIsValidCustomTime] = useState(false)
  const [validateRange, setValidateRange] = useState(false)

  const dataCustomTimeRange = stateCustomTimeRange?.filter(({ checked }) => checked).map(({ key, timeFrom, timeTo }) => ({
    day: key,
    starTime: moment(timeFrom).format(format),
    endTime: moment(timeTo).format(format)
  }))

  let hasDay = dataCustomTimeRange.length > 0;

  const validateCustomTimeRanges = (value = false, used = false, validate = false) => {
    if (used) return isValidCustomTime
    if (validate) return setValidateRange
    if (!hasDay || validateRange) {
      setIsValidCustomTime(value)
      return true;
    };
  }

  return { dataCustomTimeRange, validateCustomTimeRanges, setStateCustomTimeRange };
}

export const useReportsSelector = (report, start, end, format, filterArray, filterType, timeZone, ROW_PER_PAGE = 25) => {
  const { messages, locale } = useIntl();
  const user = useSelector(
    (state) => state.securityRedux.user
  );
  const dispatch = useDispatch();
  const [resetCache, setResetCache] = useState(0);

  const showAllOptions = {
    id: -99,
    label: messages["context_menu_show_all"]
  };
    const baseExclude = ['driverMileageSummaryReport'];
    const isTagReport = report.filters?.filterArray[0].name === "Tag"
    const isFilterAdvance = report.filters?.otherFilters.advancedFilter
    const reportExcludeOfJump = isTagReport ? isFilterAdvance ? baseExclude.concat('landmarkBasedReport') : baseExclude : baseExclude.concat('landmarkBasedReport');

    const itemsFilterEntities =!reportExcludeOfJump.includes(report.type) ? [
    ...(filterArray?.length > 0 ?
      [
        ...(
          filterArray?.length > 1 ? [showAllOptions] : []
        ),
        ...filterArray,
      ]
      : [])
  ] : [showAllOptions];

  const [unitDriverSelected, setUnitDriverSelected] = useState([itemsFilterEntities[0]]);

  const getDatesBetween = () => {
    const dateStart = moment(start);
    const dateEnd = moment(end);

    // Crear una matriz vacía para almacenar los días
    let days = [{
      id: -99,
      label: `${formatDatetime(dateStart, format, timeZone)} - ${formatDatetime(dateEnd, format, timeZone)}`
    }];
    if (!dateStart.isSame(dateEnd, 'day')) {
      // Iterar sobre cada día entre las dos fechas y agregarlo a la matriz, All Days
      if (!['advancedSummaryReport', 'stateMileageSummariesReport', 'summaryReport','nonReportingReport', 'odometerReport','utilizationReport', 'driverMileageSummaryReport', 'landmarkReport','landmarkBasedReport','UnitInfoReport', 'driverTimesheetReport'].includes(report.type)) {
        while (dateStart.isSameOrBefore(dateEnd)) {
          const newDateStart = formatDatetime(dateStart, format, timeZone)
          const dateEndForLabel = dateStart.isSame(dateEnd, 'day') ? dateEnd : dateStart.endOf("day")
          const day = `${newDateStart} - ${formatDatetime(dateEndForLabel, format, timeZone)}`

          days.push({ id: `${dateStart.startOf("day").format("YYYY-MM-DD")}T00:00:00Z`, label: day });
          dateStart.add(1, 'days');
          dateStart.startOf("day")
        }
      }
    }
    return days;
  }

  const copyReportToSetupFromReportInstance = (report = null, params = null) => {
    dispatch(reportsMainShowReportSetup())
    dispatch(reportsMainSetFormDataReportSetup(report))
  }

  const dates = getDatesBetween()
  const [dateSelected, setDateSelected] = useState([dates[0]]);

  useEffect(() => {
    if (unitDriverSelected && unitDriverSelected[0]?.id === showAllOptions.id) {
      setUnitDriverSelected([showAllOptions])
    }
  }, [locale]);

  useEffect(() => {
    if (dateSelected) {
      if (dateSelected[0]?.id) {
        const newDate = dates.find(date => date?.id === dateSelected[0].id)
        if (newDate) {
          setDateSelected([newDate])
        }

      }
    }
  }, [user.dateformat]);

  return {
    itemsSelectorEntity: itemsFilterEntities,
    textFieldSelectorEntity: getFilterNameByEntity(filterType),
    valueSelecteEntity: unitDriverSelected,
    onChangeSelectEntity: (value) => {
      const unit = value?.id != -99 ? [value] : null;
      setUnitDriverSelected(unit || [itemsFilterEntities[0]])
      let currentReport = report;
      currentReport.exportType = "json";
      currentReport.data = []
      currentReport.filters = { ...currentReport.filters, unitsSelected: unit, accumulateData: false };
      dispatch(reportsMainGenerateReportUpdate(currentReport, 1, ROW_PER_PAGE));
      setResetCache(prev => prev + 1)
    },
    itemsSelectDates: dates,
    textFieldSelectorDates: "label",
    onChangeSelectDates: (value) => {

      if (['stateMileageSummariesReport'].includes(report.type)) {
        report.timeframeName = 'Custom';
        copyReportToSetupFromReportInstance(report)
      }else {
        const date = value?.id != -99 ? [value] : null;
        setDateSelected(date || [dates[0]])
        let currentReport = report;
        currentReport.data = []
        currentReport.exportType = "json";
        currentReport.filters = {
          ...currentReport.filters,
          unitsSelected: unitDriverSelected?.length && (unitDriverSelected[0]?.id != -99 ? unitDriverSelected : null),
          accumulateData: false,
          dates: date
        };
        dispatch(reportsMainGenerateReportUpdate(currentReport, 1, ROW_PER_PAGE));
        setResetCache(prev => prev + 1)
      }
    },
    dateSelected,
    resetCache,
    onChangeResetCache: () => {
      setResetCache(prev => prev + 1)
    }
  }
}
