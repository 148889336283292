

// Dependencies
import moment from "moment";

import { ExceptionManager } from 'Core/logManager'
import { checkDeviceData } from 'Core/data/Helpers'

/**
 * Generates a range from the given date to the current date.
 * @returns { string, string } - { startDate, endDate }
 */
export const getAbsoluteDateRange = (initialDate) => {
  const start = moment(initialDate).format();
  const end = moment().format();

  return { start, end };
}

export const formatAvailableMedia = (availableMediaList) => {
  return availableMediaList.map((availableMediaItem) => {

    if(availableMediaItem?.type !== 'deviceReading'){
        return availableMediaItem
    }

    const {
      reading: {
        id,
        driverName,
        deviceData,
        eventName,
        eventCode,
        unitTime,
        starred,
        notes,
        speed,
        normalizedSpeed,
        ecuSpeed,
        unitType,
        rawMessage,
        formattedAddress
      },
      linkedDevice: {
        id: deviceId,
        deviceModelName,
        esn
      }
    } = availableMediaItem;

    const { unit } = availableMediaItem;

    return {
      ...getDeviceData(deviceData, eventName, rawMessage),
      id,
      unitId: unit?.id,
      deviceId,
      timeZone: unit?.timeZone,
      eventName,
      unitTime,
      label: unit?.label,
      driverName: (driverName ? driverName : unit?.driverName),
      starred,
      notes,
      speed,
      normalizedSpeed,
      ecuSpeed,
      unitType,
      useEcuSpeed: unit?.useEcuSpeed,
      deviceModelName,
      eventCode,
      formattedAddress,
      esn,
      type: availableMediaItem?.type
    };
  });
};

const getDeviceData = (deviceDataJSON, eventName) => {
  try {
    //const deviceDataObject = JSON.parse(JSON.parse(deviceDataJSON));
    let deviceDataObject = checkDeviceData(deviceDataJSON);

    const pictureData = (data) => {
      const { pictureID, eventCategory, thumbnailUrl, videoReview } = data;
      return { pictureID, eventCategory, thumbnailUrl, videoReview };
    };

    const videoData = (data) => {
      const { clipID, thumbnailUrl, duration, url, videoReview } = data;
      return { clipID, thumbnailUrl, duration, url, videoReview };
    };

    return eventName === 'AutoSnapshot' ? pictureData(deviceDataObject) : videoData(deviceDataObject);
  } catch (exc) {
    ExceptionManager(exc, 'modules/video/.globals/helpers', 'getDeviceData')
  }

  return null
};

export const converSpeedToMph = (speed, unitType) => {
	let mph = 0
	switch (unitType) {
		case 'Calamp': //Calamp 1100
			mph = (speed * 0.0223693629); //cps to mph
			break;
		case 'Webtech':
			mph = (speed * 0.621371192); //kph to mph
			break;
		default:
			mph = speed;
			break;
	}
	return mph
}


