import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import _ from 'lodash';

// Material UI
import { Grid, Icon } from '@mui/material';
import { Tooltip, Loading } from 'Components';

// Actions
import {
  getCameraOnlineStatus,
  clearRedux
} from 'Redux/actions';

const getStatus = (state) => state.vidFleetVideoRedux.cameraStatusResponse
const getStatusLoading = (state) => state.vidFleetVideoRedux.loadingCameraStatus

const cameraOnlineStatusServer = (id) => {
  return createSelector(
    [getStatus, getStatusLoading],
    (status, loading) => {
      return [loading[id], status[id]]
    }
  )
}

const CameSignalStatus = (props) => {
  const dispatch = useDispatch();

  const { id, direction, justify, alignItems, simProviderName, unitId, vehicleEvent, sdCard } = props;
  const { intl } = props;
  const { messages } = intl;

  const [ icon, setIcon ] = useState();

  const [loading, cameraStatus] = useSelector(cameraOnlineStatusServer(id), (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps)
  });

  useEffect(() => {
    return () => {
      dispatch(clearRedux("VIDFLEET_VIDEO", { loadingCameraStatus: [], cameraStatusResponse: [] }));
    };
  }, []);

  useEffect(() => {
    if (!cameraStatus && id) {
      dispatch(getCameraOnlineStatus({esn: id, unitId: unitId }));
    } else if (cameraStatus && 
        //(vehicleEvent === "Ignition Off" || vehicleEvent === "Parked" || vehicleEvent === "Park") && 
        (simProviderName === "USC" || simProviderName === "US Cellular")
    ) {
      if (cameraStatus?.lastConnectTime < cameraStatus?.lastDisconnectTime) {
        setIcon("unknown");
      } else {
        setIcon(null);
      }
    }
  }, [id, cameraStatus, simProviderName]);

  const statusIcon = `/assets/icons/signal_${icon || cameraStatus?.signal}.svg`;

  return (
    <div>
      {
        loading ?
          <Loading style={{ padding: 10 }} size={20} />
          :
          <React.Fragment>
            {
              cameraStatus &&
              <React.Fragment>
                {
                  cameraStatus?.error ?
                    <Tooltip title="Error" placement={"top"}>
                      <img
                        style={{ padding: '5px 10px 5px 5px', width: 20, height: 20, position: 'relative' }}
                        src='/assets/icons/signal_unknown_error.svg'
                        alt="unknown"
                      />
                    </Tooltip> :
                    <Grid
                      container
                      direction={direction}
                      justifyContent={justify || "flex-start"}
                      alignItems={alignItems || "flex-start"}
                    >
                      <Grid item>
                        <Tooltip title={messages[icon || cameraStatus?.signal]} placement={"top"}>
                          <img style={{ padding: 5, width: 20, height: 20, position: 'relative' }} src={statusIcon} alt={icon || cameraStatus?.signal} />
                        </Tooltip>
                      </Grid>
                      {
                        sdCard?.status && sdCard?.status?.toUpperCase() !== "NORMAL" &&
                        <Grid item>
                          <Tooltip title={messages['vidFleet_sdCardError']} placement={"top"}>
                            <Icon style={{ color: '#f44336', fontSize: 19, marginTop: 3, marginLeft: 5 }}>sd_card_alert</Icon>
                          </Tooltip>
                        </Grid>
                      }
                      
                      {/*   
                        cameraStatus?.mode &&
                        <Grid item>
                          <Tooltip title={messages[cameraStatus?.mode]} placement={"top"}>
                            <img style={{ padding: '5px 10px 5px 5px', width: 20, height: 20 }} src={vehicleStatusIcon} alt={cameraStatus?.mode} />
                          </Tooltip>
                        </Grid>
                      */}
                    </Grid>
                }
              </React.Fragment>
            }
          </React.Fragment>
      }
    </div>
  );
}

export default injectIntl(CameSignalStatus);

CameSignalStatus.propTypes = {
  id: PropTypes.string.isRequired, //This is the camera's ESN
  direction: PropTypes.string.isRequired, //Show the indicators in vertical or horizontal
  direction: PropTypes.oneOf(['row', 'column'])
};