import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

const multipleSort = (data, sorts, columns = []) => {
   const newSorts = sorts;

   return [...data].sort((a, b) => {
      let i = 0, result = 0;
      while (i < newSorts.length && result === 0) {
         let column = columns?.find(column => column?.key === newSorts[i]?.key);
         let order = (newSorts[i].order == 'asc' ? 1 : -1);

         let valueA;
         let valueB;
         if (column && column?.sortValue) {
            valueA = getClearValue(column?.sortValue(a));
            valueB = getClearValue(column?.sortValue(b));
         } else {
            valueA = getClearValue(a[newSorts[i].key]);
            valueB = getClearValue(b[newSorts[i].key]);
         }

         if (column?.alphanumeric) {
            valueA = normalizeMixedDataValue(valueA);
            valueB = normalizeMixedDataValue(valueB);
         }

         result = order * (valueA < valueB ? -1 : (valueA > valueB ? 1 : 0));
         i++;
      }

      return result;
   })

}

const getClearValue = (value) => {
   if (!value) return "";

   if (typeof value == 'undefined') return "";

   if (Array.isArray(value) || typeof value === 'object') return "";

   if (isNaN(value)) return value.toLowerCase();

   return value
}

const filterDataBySearch = (data, searchText) => {
   function recursiveSearch(obj, text) {
      for (const prop in obj) {
         if (obj.hasOwnProperty(prop)) {
            if (typeof obj[prop] === 'string' && obj[prop].toLowerCase().includes(text)) {
               return true;
            } else if (typeof obj[prop] === 'object') {
               if (recursiveSearch(obj[prop], text)) {
                  return true;
               }
            }
         }
      }
      return false;
   }

   if (!searchText || searchText.trim() === '') {
      return data;
   }

   const lowerSearchText = searchText.toLowerCase();
   return data.filter(item => recursiveSearch(item, lowerSearchText));
}

export const usePaggination = (action, searchFields, isLocalData = false, tableRef, termsFields = []) => {

   const [sort, setSort] = useState({ key: 'title', order: 'asc' });
   const [searchText, setSearchText] = useState('');
   const [pageSize, setPageSize] = useState(10);
   const [allData, setAllData] = useState([]);
   const [filteredData, setFilteredData] = useState([]);
   const [columns, setColumns] = useState([]);
   const [params, setParams] = useState({});
   const [total, setTotal] = useState(0);
   const [data, setData] = useState([]);
   const [page, setPage] = useState(1);

   const [currentConditions, setCurrentConditions] = useState([]);
   const [currentSort, setCurrentSort] = useState(null);
   const [externalConditions, setExternalConditions] = useState([]);

   const dispatch = useDispatch();

   const refresh = useCallback((_page, _pageSize, _searchText, _parms, _sort, _data, _conditions) => {

      let newPage = _page || page
      setPage(newPage);

      let newData = _data || allData
      setAllData(newData);

      let newPageSize = _pageSize === "ALL" ? newData.length : _pageSize || pageSize
      setPageSize(newPageSize);

      let newSort = _sort || sort
      setSort(newSort)

      let newSearchText = _searchText != undefined ? _searchText : searchText
      setSearchText(newSearchText)

      let newParams = { ...params, ..._parms }
      setParams(newParams)

      //conditions
      const conditions = searchFields && newSearchText ? [
         {
            "fields": searchFields,
            "terms": [newSearchText]
         }
      ] : []

      if (termsFields.length) {
         termsFields.map((item) => {
            conditions.push(item)
         })
      }

      let newExternalConditions = _conditions || externalConditions
      setExternalConditions(newExternalConditions)
      if (_conditions?.length) {
         _conditions.map((item) => {
            conditions.push(item)
         })
      }

      if (!isLocalData) {
         setCurrentConditions(conditions)
         let formatSort = { field: newSort?.key, order: newSort?.order === "asc" ? "ASC" : "DESC" }
         setCurrentSort(formatSort)
         dispatch(action({ limit: newPageSize, offset: ((newPage || page) - 1) * newPageSize, conditions, sort: formatSort }))
      } else {
         let filteredDataLocal = [];

         //Search
         const lowerSearchText = newSearchText.toLowerCase();
         filteredDataLocal = filterDataBySearch(newData, lowerSearchText);

         //Sort data
         let sorts = [];
         if (newSort) {
            sorts.push(newSort)
         }
         if (sorts && sorts.length > 0) {
            filteredDataLocal = multipleSort(filteredDataLocal || [], sorts || [], columns || []);
         }
         //Set Total
         setTotal(filteredDataLocal?.length || 0)
         setFilteredData(filteredDataLocal)
         //Pagination
         const startIndex = (newPage - 1) * newPageSize;
         const endIndex = startIndex + newPageSize;
         const paginatedData = filteredDataLocal.slice(startIndex, endIndex);
         setData(paginatedData);
      }
   }, [data]);

   return {
      //Functions
      onChangeRowsPerPage: (newRowsPerPage) => {
         refresh(1, newRowsPerPage, searchText, params, sort, null, externalConditions)
      },
      onSearchChange: (value) => {
         setSearchText(value)
         if (window.typingTime) {
            clearTimeout(window.typingTime)
         }
         window.typingTime = setTimeout(() => {
            tableRef && tableRef.current?.setPage(1);
            refresh(1, pageSize, value, params, sort, null, externalConditions)
         }, 1000)
      },
      onChangeData: (_data, _sort, newPage) => {
         refresh(newPage, pageSize, searchText, params, _sort, _data, externalConditions)
      },
      onChangeSort: (newSort) => {
         refresh(page, pageSize, searchText, params, newSort, null, externalConditions)
      },
      refresh: () => {
         refresh(page, pageSize, searchText, params, sort, null, externalConditions)
      },
      onChangePage: (newPage) => {
         refresh(newPage, pageSize, searchText, params, sort, null, externalConditions)
      },
      addConditions: (_newPage, _conditions) => {
         refresh(_newPage, pageSize, searchText, params, sort, null, _conditions)
      },
      //Params
      setColumns,
      setSort,
      setData,
      columns,
      defaultPage: page,
      rowsPerPage: pageSize,
      searchText,
      currentConditions,
      currentSort,
      ...(isLocalData ? { data, total, allData, filteredData } : {}),
   }
}