import crewTab from "Modules/settings/views/crew/lan/es";
import driverGroupsTab from "Modules/settings/views/driverGroups/lan/es";
import mainView from "Modules/settings/views/main/lan/es";
import locationsView from "Modules/settings/views/locations/lan/es";
import vehicleStatusView from "Modules/settings/views/vehicleStatus/lan/es";
import vehicleEquipmentView from "Modules/settings/views/vehicleEquipment/lan/es";
import inputLabelsView from "Modules/settings/views/inputLabels/lan/es";
import DriverView from "Modules/settings/views/driver/lan/es";
import KeyFobView from "Modules/settings/views/keyFob/lan/es";
import TemperatureView from "Modules/settings/views/temperature/lan/es";
import unitView from "Modules/settings/views/unitSettings/lan/es";
import fuelCardView from "Modules/settings/views/fuelCard/lan/es";
import CameraManage from "Modules/settings/views/cameraManage/lan/es";
import CameraManageEdit from "Modules/settings/views/cameraManage/components/edit/lan/es";
import editUnitSettings from "Modules/settings/views/unitSettings/components/editUnitSettings/lan/es";
import driverStatus from "Modules/settings/views/driverStatus/lan/es";
import driverStatusDelete from "Modules/settings/views/driverStatus/components/delete/lan/es";
import driverStatusCreateEdit from "Modules/settings/views/driverStatus/components/createEdit/lan/es";
import driverStatusLogList from "Modules/settings/views/driver/components/timeClock/lan/es";
import driverStatusLogCreateEdit from "Modules/settings/views/driver/components/timeClock/components/createEdit/lan/es";
import driverStatusLogDelete from "Modules/settings/views/driver/components/timeClock/components/delete/lan/es";
import cameraManageEditCamera from 'Modules/settings/views/cameraManage/components/editCamera/lan/es';

const locales = {
    ...crewTab,
    ...mainView,
    ...inputLabelsView,
    ...vehicleStatusView,
    ...vehicleEquipmentView,
    ...driverGroupsTab,
    ...locationsView,
    ...DriverView,
    ...KeyFobView,
    ...TemperatureView,
    ...unitView,
    ...fuelCardView,
    ...CameraManage,
    ...CameraManageEdit,
    ...editUnitSettings,
    ...driverStatus,
    ...driverStatusDelete,
    ...driverStatusCreateEdit,
    ...driverStatusLogList,
    ...driverStatusLogCreateEdit,
    ...driverStatusLogDelete,
    ...cameraManageEditCamera
};

export default locales;