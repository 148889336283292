export default {
    settingsCameraManage_title: "Administración de Camaras",
    settingsCameraManage_recordId: "Id del Registro",
    settingsCameraManage_imei: "IMEI",
    settingsCameraManage_esn: "ESN",
    settingsCameraManage_pairedUnit: "Unidad Emparejada",
    settingsCameraManage_label: "Etiqueta",
    settingsCameraManage_unpaired: "No emparejado",
    settingsCameraManage_editCameraPairing: "Editar emparejamiento de la cámara",
    settingsCameraManage_showUnpairedOnly: "Mostrar sólo no emparejados",
    settingsCameraManage_editCamera: "Editar",
};