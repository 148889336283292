// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";

// Action Types
import {
  ACCOUNT_SETTINGS_SAVE,
  GET_PREFERENCE
} from "Redux/actionTypes";

// Actions
import {
  saveAccountSettingsResponse,
  getUserInfo,
  getPreferenceResult
} from "Redux/actions";

// Helpers
import { clientMutation, clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* saveAccountSettings({ payload }) {
  const { defaultTab, isKph, timezone, dateFormat, eldUser, durationFormat, enableChatDriver } = payload;
  const mutation = `
    mutation saveAccountSettings ($defaultTab: String!, $isKph: Boolean!, $timezone: String!, $dateFormat: String!, $eldUser: EldUserInput,$durationFormat: enumDurationFormat!, $enableChatDriver: Boolean) {
      saveAccountSettings (defaultTab: $defaultTab, isKph: $isKph, timezone: $timezone, dateFormat: $dateFormat, eldUser: $eldUser, durationFormat: $durationFormat, enableChatDriver: $enableChatDriver)
  }`;

  const variables = {
    defaultTab,
    isKph,
    timezone,
    dateFormat,
    eldUser,
    durationFormat,
    enableChatDriver
  };
  try {
    const data = yield call(
      clientMutation,
      mutation,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { saveAccountSettings: result } = data;
      yield put(saveAccountSettingsResponse({
        updatedAccount: result,
        error: false
      }));

      yield put(getUserInfo())
    }
  } catch (exc) {
    yield put(saveAccountSettingsResponse({ updatedAccount: false, error: true }));
    ExceptionManager(
      exc,
      "modules/.common/components/AccountSettings/redux/sagas",
      "saveAccountSettings"
    );
  }
}


function* getPreference({ payload }) {
  const { projectKey, entityName, entityId, preferenceKey} = payload;
  let data = yield call(
    clientQuery,
    `query getPreference ($projectKey: ProjectKey!, $entityName: EntityName!, $entityId: String!, $preferenceKey: String!) {
				preference: getPreference (projectKey: $projectKey, entityName: $entityName, entityId: $entityId, preferenceKey: $preferenceKey) {
					id
					projectKey
					entityName
					entityId
					preferenceKey
					preferenceValue
				}
			}`,
      {
				"projectKey": projectKey,
				"entityName": entityName,
				"entityId": entityId,
				"preferenceKey": preferenceKey
			},
    endpoints.GRAPHQL_GENERAL,
  );
  if (data?.preference && JSON.parse(data?.preference?.preferenceValue)) {
    yield put(getPreferenceResult({
      preference: preferenceKey,
      preferenceValue: JSON.parse(data?.preference?.preferenceValue)
    }))
  } else {
    yield put(getPreferenceResult({
      preference: preferenceKey,
      preferenceValue: false
    }))
  } 
}
function* watchMethods() {
  yield takeEvery(ACCOUNT_SETTINGS_SAVE, saveAccountSettings);
}

function* watchGetPreference() {
	yield takeEvery(GET_PREFERENCE, getPreference);
}

export default function* rootSaga() {
  yield all([
    fork(watchMethods),
    fork(watchGetPreference)
  ]);
}
