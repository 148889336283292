import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Material UI
import { Grid, Typography, Alert, Icon } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Cloud React Components
import { Button, Tooltip, Popover } from 'Components';
import { HasPermission } from 'Core/security';
import { TagsGroup } from 'Components/Tag';
import { getIconLandmark } from 'Components/Map/utils';

import {Timezone} from '../../../../../components/uiControls';


import {
  GetFormatForMoment,
  getFormattedDateByTimeZone,
  conversionTimeZone,
  headingToDirection,
  speedFormatMPH,
  copyToClipboard,
  cutNotes,
  formatAddress,
  formatDateUser,
  checkDeviceData
} from '../../../../../core/data/Helpers';
import { GetEventTemperatureValue, GetEventName } from 'Modules/.common/components/EventViewTools'

//actions
import {
  dtcGetDeviceDTC,
  centerOnUnit
} from 'Redux/actions';

// Global styles
import styles from 'Assets/sass/core/vars.scss';

// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'

// Global var to timer
let timerUpdateTime = null

const Location = (props) => {
  const dispatch = useDispatch();
  const { id, type, user, data, messages, size, locale } = props;
  const { isKph } = user
  const lang = useSelector(state => state.commomRedux.language);

  const EVENTS_NAME = {
    "IDLING": "Idle"
  };

  const [locationData, setLocationData] = useState({});
  const { followUnit, centerUnitId } = useSelector(state => state.realtimeMapRedux);
  const jsonLandmark = useSelector((state) => state.realtimeMapRedux.jsonLandmark);

  const isValidJson = (json) => {
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    return () => {
      clearInterval(timerUpdateTime)
      timerUpdateTime = null
    }
  },[])

  useEffect(() => {
    try {

      if (data) {
        clearInterval(timerUpdateTime)
        timerUpdateTime = null

        formatData();
      }
    } catch (e) {
      console.log(e);
    }
  }, [data]);

  const formatData = useCallback(() => {
    try {
      let ld = {}, speed, headingDirection;
      let landmarkIcon = null;
      if (type === "Vehicle") {
        speed = data?.markerDetail?.unit?.lastReading?.speed || 0;
        let isECUSpeed = data?.markerDetail?.unit?.lastReading?.ecuSpeed > 0 && data?.markerDetail?.unit?.useEcuSpeed

        //Jeremy - Notify in slack, ecuSpeed always come in MPH for all units
        //https://gtc-wins.slack.com/archives/C040LL7B677/p1669734677841839
        if (isECUSpeed) {
          speed = data?.markerDetail?.unit?.lastReading?.ecuSpeed || 0;
        }
        speed = speedFormatMPH(speed, data?.markerDetail?.unit?.lastReading?.unitType, isKph, isECUSpeed)
        headingDirection = headingToDirection(data?.markerDetail?.unit?.lastReading?.heading)
        speed = getFormatHeading(speed, headingDirection)
        let address = formatAddress(data?.markerDetail?.unit?.lastReading)

        //get the landmark icon
        landmarkIcon = getLandmarkIcon(data?.markerDetail?.unit?.lastReading?.landmark_id, data?.markerDetail?.unit?.tags)

        ld = {
          address: address || "N/A",
          //unitTime: formatUnixToDate(data?.markerDetail?.unit?.lastReading?.unitTime / 1000),
          //unitTime: formatCatalogDate(data?.markerDetail?.unit?.lastReading?.unitTime, formatDateUser()),
          unitTime: getFormattedDateByTimeZone(data?.markerDetail?.unit?.lastReading?.unitTime, data?.markerDetail?.unit?.timeZone?.name, messages, formatDateUser(), true, lang),
          unitTimeInitial: data?.markerDetail?.unit?.lastReading?.unitTime, 
          timeZoneInitial: data?.markerDetail?.unit?.timeZone?.name,
          currentTime: currentTime(data?.markerDetail?.unit?.timeZone?.name),
          speed: speed ? speed : "N/A",
          eventName: EVENTS_NAME[data?.markerDetail?.unit?.lastReading?.eventName] || data?.markerDetail?.unit?.lastReading?.eventName,
          latitude: data?.markerDetail?.unit?.lastReading?.latitude?.toFixed(6) || "",
          longitude: data?.markerDetail?.unit?.lastReading?.longitude?.toFixed(6) || "",
          useEcuSpeed: data?.markerDetail?.unit?.useEcuSpeed,
          driverName: data?.markerDetail?.unit?.driverId > 0 && data?.markerDetail?.unit?.driverName ? data?.markerDetail?.unit?.driverName : "",
          crews: data?.markerDetail?.unit?.crews || [],
          timeZone: data?.markerDetail?.unit?.timeZone?.name,
          battery: getBattery(data?.markerDetail?.unit?.lastReading?.deviceData) || null,
          landmarkIcon: landmarkIcon || null,
          toUpdateDateControl: new Date().getTime()
        }
      } else if (type === "Trail") {
        //let address = formatAddress(data?.markerDetail?.vehicleTrail?.address);
        let address = formatAddress(data?.markerDetail?.vehicleTrail)// is formated address comming null;        

        speed = data?.markerDetail?.vehicleTrail?.speed;
        if (data?.markerDetail?.vehicleTrail?.ecuSpeed > 0 && data?.markerDetail?.unit?.useEcuSpeed) {
          speed = data?.markerDetail?.vehicleTrail?.ecuSpeed;
        }

        speed = speedFormatMPH(speed, data?.markerDetail?.unit?.lastReading?.unitType, isKph, true)
        headingDirection = headingToDirection(data?.markerDetail?.vehicleTrail?.heading)
        speed = getFormatHeading(speed, headingDirection)


        //get the landmark icon
        landmarkIcon = getLandmarkIcon(data?.markerDetail?.unit?.lastReading?.landmark_id, data?.markerDetail?.unit?.tags)

        ld = {
          address: address || "N/A",
          //unitTime: formatDate(data?.markerDetail?.vehicleTrail?.unitTime),
          unitTime: getFormattedDateByTimeZone(data?.markerDetail?.vehicleTrail?.unitTime, data?.markerDetail?.unit?.timeZone?.name, messages, formatDateUser(), true, lang),
          unitTimeInitial: data?.markerDetail?.vehicleTrail?.unitTime, 
          timeZoneInitial: data?.markerDetail?.unit?.timeZone?.name,
          currentTime: currentTime(data?.markerDetail?.unit?.timeZone?.name),
          speed: speed ? speed : "N/A",
          eventName: EVENTS_NAME[data?.markerDetail?.vehicleTrail?.eventName] || data?.markerDetail?.vehicleTrail?.eventName,
          latitude: data?.markerDetail?.vehicleTrail?.latitude?.toFixed(6) || "",
          longitude: data?.markerDetail?.vehicleTrail?.longitude?.toFixed(6) || "",
          useEcuSpeed: data?.markerDetail?.unit?.useEcuSpeed,
          driverName: data?.markerDetail?.vehicleTrail?.driverId > 0 && data?.markerDetail?.vehicleTrail?.driverName ? data?.markerDetail?.vehicleTrail?.driverName : "",
          crews: data?.markerDetail?.unit?.crews || [],
          landmarkIcon: landmarkIcon || null,
          toUpdateDateControl: new Date().getTime()
        }
      }
      setLocationData(ld);

      if (timerUpdateTime) {
        clearInterval(timerUpdateTime)
      }
      timerUpdateTime = setInterval(() => { 
        onUpdateTime(ld) 
      }, 20000)

    } catch (e) { 
      console.log(e);
    }
  });

  const onUpdateTime = (locationData) => {
    //start timer
    if (type === "Vehicle") {
      setLocationData(
        {
          ...locationData,
          unitTime: getFormattedDateByTimeZone(data?.markerDetail?.unit?.lastReading?.unitTime, data?.markerDetail?.unit?.timeZone?.name, messages, formatDateUser(), true, lang),
          currentTime: currentTime(data?.markerDetail?.unit?.timeZone?.name),
          unitTimeInitial: data?.markerDetail?.unit?.lastReading?.unitTime, 
          toUpdateDateControl: new Date().getTime()
        }
      )
    }else if (type === "Trail") {
      setLocationData(
        {
          ...locationData,
          unitTime: getFormattedDateByTimeZone(data?.markerDetail?.vehicleTrail?.unitTime, data?.markerDetail?.unit?.timeZone?.name, messages, formatDateUser(), true, lang),
          currentTime: currentTime(data?.markerDetail?.unit?.timeZone?.name),
          unitTimeInitial: data?.markerDetail?.vehicleTrail?.unitTime,
          toUpdateDateControl: new Date().getTime()
        }
      )
    } 
  }

  const currentTime = useCallback((paramTimeZone) => {
    let timezone = conversionTimeZone(paramTimeZone);
    let _currentTime = new Date().toLocaleString("en-US", { timeZone: timezone });
    let responseDate = moment(_currentTime).format(formatDateUser());
    return responseDate;
  })

  const getFormatHeading = (speed, headingDirection) => {
    speed = headingDirection ? `${speed} ** Heading: ${headingDirection}` : speed
    return speed
  }

  const formatDateUser = () => {
    const momentFormat = GetFormatForMoment(user.dateformat);
    return `h:mm:ss A, ${momentFormat}`;
  }

  const getLandmarkIcon = (landmarkId, tag) => {
    let landmarkIcon = null;
    if (landmarkId && landmarkId > 0){
      const landmark = jsonLandmark.find((landmarkItem) => landmarkItem.id == landmarkId);
      if(landmark){
        landmarkIcon = getIconLandmark(landmark, tag);
        landmarkIcon = landmarkIcon ? { ...landmarkIcon, name: landmark.name } : null;
      }
    } 
    return landmarkIcon
  }

  const formatDate = useCallback((date) => {
    if (!date) {
      return "N/A";
    }

    const momentFormat = GetFormatForMoment(user.dateformat);
    return moment(date).format(`h:mm A, ${momentFormat}`);
  });

  const getBattery = (unit) => {
    let battery = null
    if (unit && isValidJson(unit)) {
      battery = JSON.parse(unit);
    }
    return battery?.batteryPercent || null
  }

  let lastReading = type === "Trail" ? data?.markerDetail?.vehicleTrail : data?.markerDetail?.unit?.lastReading
  let sdCard = data?.markerDetail?.linkedDevice?.sdCard ? checkDeviceData(data?.markerDetail?.linkedDevice?.sdCard) :  null

  return (
    <div className='info-window-tab'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        style={{
          padding: '16px 45px 16px 16px'
        }}
      >
        <Grid item xs zeroMinWidth>
          <Typography noWrap style={{ fontWeight: 'bold' }}>
            {data?.markerDetail?.unit?.label || data?.markerDetail?.unit?.lastReading?.deviceLabel}
          </Typography>
        </Grid>

        <Grid item xs>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <span style={{
                backgroundColor: '#66bb6a',
                color: 'white',
                padding: '2px 4px',
                textTransform: 'uppercase'
              }}>
                {data?.markerDetail?.unit?.deviceCategory}
              </span>
            </Grid>

            <Grid item xs>
              <TagsGroup
                options={(data && data?.markerDetail && data?.markerDetail?.unit?.tags ? data?.markerDetail?.unit?.tags : [])}
                nameField="label"
                colorField="color"
                alwaysGroup={true}
              />
            </Grid>

            <Grid item>
              <div style={{ cursor: 'pointer' }}>
                {followUnit && id == centerUnitId
                  ?
                  <Tooltip title={messages['maputilities_vehiclelist_follow_unit']}>
                    <span>
                      <VisibilityIcon
                        style={{ color: '#00AEEF' }}
                        onClick={() => {
                          dispatch(centerOnUnit(id, false));
                        }}
                      />
                    </span>
                  </Tooltip>
                  :
                  <VisibilityOffIcon
                    onClick={() => {
                      dispatch(centerOnUnit(id, true))
                    }}
                  />
                }
              </div>
            </Grid>

            {
              (data?.markerDetail?.unit?.jbus == 1 || data?.markerDetail?.unit?.vbus == 1)
                ?
                <Grid item>
                  {
                    (data?.markerDetail?.unit?.dtcCount?.history || data?.markerDetail?.unit?.dtcCount?.active)
                      ?
                      <span
                        className={`info-bubble-badge ${data?.markerDetail?.unit?.dtcCount?.active > 0 ? 'info-bubble-badge-red' : 'info-bubble-badge-black'}`}
                        onClick={() => {
                          dispatch(dtcGetDeviceDTC({
                            device: {
                              id: id,
                              label: data?.markerDetail?.unit?.lastReading?.deviceLabel || data?.markerDetail?.unit?.label
                            },
                            history: (data?.markerDetail?.unit?.dtcCount?.active > 0 ? false : true)
                          }))
                        }}
                      >
                        {messages['infoBubble_dtc']}
                      </span>
                      :
                      <span className={`info-bubble-badge info-bubble-badge-disabled`}>
                        {messages['infoBubble_dtc']}
                      </span>
                  }
                </Grid>
                :
                <Grid item></Grid>
            }
          </Grid>
        </Grid>
      </Grid>

      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>
      {
        data?.markerDetail?.linkedDevice?.manufacturerName === "VidFleet" && sdCard?.status && sdCard?.status?.toUpperCase() !== "NORMAL" &&
        <Alert
            style={{ display: 'flex', alignItems: 'center', height: 20, fontSize: 11, borderRadius: 0 }}
            severity="error"
            icon={<Icon style={{ color: '#f44336' }}>cancel</Icon>}
        >
            {messages['infoBubble_camera_sdcard_status']} <strong>{sdCard?.status}</strong>
        </Alert>
      }
      <div>
        <table className="info-bubble-table">
          <tbody>
            <tr>
              <td>
                {messages['infoBubble_address']}
              </td>
              <td>
                <div className="hover-container">
                  {
                    locationData?.address?.length > 50
                      ?
                      <Tooltip title={locationData?.address}>
                        <span style={{ paddingRight: 8 }}>
                          {cutNotes(locationData?.address, 50) || "N/A"}
                        </span>
                      </Tooltip>
                      :
                      <span style={{ paddingRight: 8 }}>
                        {locationData?.address || "N/A"}
                      </span>
                  }

                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(locationData?.address || "N/A")}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_currentTime']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {locationData?.currentTime}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(locationData?.currentTime)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_unitTime']}
              </td>
              <td>
                <div className="hover-container infoBubbleTimeSpace">
                  <span style={{ paddingRight: 8 }}>
                    {
                      locationData?.unitTimeInitial 
                      ?
                      <Timezone
                          onlyHour
                          date={locationData?.unitTimeInitial}
                          dateFormat={""}
                          timeFormat={"h:mm:ss A, MM/DD/YYYY"}
                          timezone={locationData?.timeZoneInitial}
                          className="infoBubbleTimeZoneClassName"
                          fromNow={true}
                          lang={locale}
                          refresh={locationData?.toUpdateDateControl}
                      /> 
                      : "N/A"
                    }
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(locationData?.unitTime || "N/A")}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_speed']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {
                      (locationData?.useEcuSpeed) &&
                      <span
                        className="ecuChip"
                        style={{ background: styles.ecu }}
                      >
                        ECU
                      </span>
                    }
                    {locationData?.speed || "N/A"}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(locationData?.speed || "N/A")}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_event']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {
                      locationData?.landmarkIcon &&
                      <Tooltip title={locationData?.landmarkIcon?.name}>
                        <img
                            style={{
                                width: 20,
                                height: 20,
                                paddingRight: 8,
                                paddingTop: 2
                            }}
                            src={locationData?.landmarkIcon?.icon}
                            className="groupItem-icon"
                        />
                      </Tooltip>
                    }
                    {GetEventName(lastReading)}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(GetEventName(lastReading))}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_latLng']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {(locationData?.latitude && locationData?.longitude ? `${locationData?.latitude}, ${locationData?.longitude}` : "N/A")}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(`${locationData?.latitude && locationData?.longitude ? `${locationData?.latitude}, ${locationData?.longitude}` : "N/A"}`)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            {
              locationData.driverName
                ?
                <tr>
                  <td>
                    {messages['infoBubble_driver']}
                  </td>
                  <td>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <div className="hover-container">
                          {
                            locationData?.driverName.length > 55
                              ?
                              <Tooltip title={locationData?.driverName}>
                                <span style={{ paddingRight: 8 }}>
                                  {cutNotes(locationData?.driverName, 55)}
                                </span>
                              </Tooltip>
                              :
                              <span style={{ paddingRight: 8 }}>
                                {locationData?.driverName}
                              </span>
                          }
                          <Button
                            type="icon"
                            icon="content_copy"
                            onClick={() => copyToClipboard(locationData?.driverName)}
                            fontSize="inherit"
                            className="btn-no-padding hover-item"
                          />
                        </div>
                      </Grid>

                      {
                        HasPermission(user, 'cwm', [126]) &&
                        <Grid item xs>
                          {
                            !locationData?.crews?.length ?
                              <span style={{ whiteSpace: 'nowrap' }} className="info-bubble-crew inactive">
                                0 {messages['infoBubble_crewMembers']}
                              </span> :
                              <Popover
                                component={
                                  <span style={{ whiteSpace: 'nowrap' }} className="info-bubble-crew active">
                                    {locationData?.crews?.length} {messages['infoBubble_crewMembers']}
                                  </span>
                                }
                              >
                                <div
                                  style={{
                                    padding: '10px 15px',
                                    backgroundColor: 'rgba(0,0,0,0.6)',
                                    color: '#FFFFFF'
                                  }}
                                >
                                  <ul
                                    style={{
                                      listStyleType: 'none',
                                      margin: 0,
                                      padding: 0
                                    }}
                                  >
                                    {
                                      locationData?.crews?.map((crew, key) => {
                                        return (
                                          <li key={key}>{crew?.firstName} {crew?.lastName}</li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </Popover>

                          }
                        </Grid>
                      }
                    </Grid>
                  </td>
                </tr>
                : null
            }

            {
              data?.markerDetail?.unit && GetEventTemperatureValue(lastReading, user) &&
              <tr>
                <td>
                  {messages['infoBubble_tempReading']}
                </td>
                <td>
                  {String(GetEventTemperatureValue(lastReading, user))}
                </td>
              </tr>
            }

          </tbody>
        </table>

        {/* <div style={{ textAlign: 'center', padding: '10px 5px 5px 10px', fontSize: 14 }}>
          <span style={{ color: '#999', fontWeight: 'normal' }}>
            {messages['infoBubble_speedMessage']}
          </span>
        </div> */}

      </div>
    </div>
  )
}

export default Location;