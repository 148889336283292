export default {
    settingsCameraManageEdit_title: "Emparejamiento de Camara",
    settingsCameraManageEdit_select: "Seleccion una unidad para empajera con la Camara",
    settingsCameraManageEdit_confirmText: "¿Esta seguro de Agregar/Quitar el emparejamiento de Camara?",
    settingsCameraManageEdit_confirmTitle: "Agregar/Quitar emparejamiento de Camera",
    settingsCameraManageEdit_pairingSuccessSave: "La cámara se ha emparejado exitosamente",
    settingsCameraManageEdit_unPairingSuccessSave: "La cámara se ha desemparejado exitosamente",

    settingsCameraManageEdit_changeConfirmMessage: "La cámara ya tiene una unidad emparejada. Está seguro de que desea cambiar el emparejamiento?",
    settingsCameraManageEdit_pairingConfirmMessage: "¿Está seguro de emparejar la cámara?",
    settingsCameraManageEdit_unpairingConfirmMessage: "¿Está seguro de desemparejar la cámara?",

    settingsCameraManageEdit_changeConfirmTitle: "Agregar/Quitar emparejamiento de Camera",
    settingsCameraManageEdit_pairingConfirmTitle: "Agregar emparejamiento de Camera",
    settingsCameraManageEdit_unpairingConfirmTitle: "Quitar emparejamiento de Camera",
    settingsCameraManageEdit_warningMessageFirst: "Esta unidad está actualmente emparejada con",
    settingsCameraManageEdit_warningMessageSecond: "Si continúa esta unidad será eliminada de",
    settingsCameraManageEdit_warningMessageThird: "y asignada a",
};