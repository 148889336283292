//imports
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

// component imports
import Tabs, { TabContainer } from 'Components/Tabs';
// Material UI
import { Checkbox, Grid } from "@mui/material";
// temporary imports
import ReportTooLarge from './.components/ReportTooLarge';
import Loading from 'Components/Loading'
import { Tooltip } from 'Components';
import { GetFormatForMomentTwoCharacters } from 'Core/data/Helpers';

// actions
//actions
import {
    catalogLoad,
    clearRedux,
    deleteReportLog,
    getPreSchedule,
    reportsListShowReportFilter,
    reportsMainGenerateReport,
    reportsMainRemoveReport,
    reportsMainSetFormDataReportSetup,
    reportsMainShowReportSetup,
    reportsScheduleShowReportSetup,
    reportsVerifyStateReport,
    reportUpdateField,
    searchReportsLog,
    sendGenericExport,
    reportUpdateFieldBatch,
} from 'Redux/actions'
import cx from 'classnames'

import { v4 as uuidv4 } from 'uuid';

// Hooks
import { useReportTypeOptions, useReportCustomFields } from 'Core/hooks';

// helper
import {
    changeDate,
    FIELD_NAME_IS_STARRED,
    FIELD_NAME_REPORT,
    formatDatetime,
    formatTabLabel,
    getEntitiesAllType,
    getEntitiesType,
    getEntityType, getNameUnitDriverTag,
    TYPE_REPORT_RECENT,
    TYPE_REPORT_STARRED
} from 'Modules/reports/views/main/components/reportsTab/helper'
import {
    AdvancedSummaryReport,
    AlertLogReport,
    ChatHistoryReport,
    ActReport,
    DriverFormsReportV2,
    DriverStatusReportV2,
    DetailedReport,
    DeviceSummaryInfoReport,
    DiagnosticIndicatorsReport,
    DiagnosticParametersReport,
    DriverFormsReport,
    DriverSafetyReport,
    DriverTimesheetReport,
    EngineHoursReport,
    EventReport,
    FormStopSummaryReport,
    FormStopSummaryReportV2,
    FSMCombinedReport,
    FsmDriverStatusReport,
    FSMTimeCardReport,
    TimeCardReportV2,
    FuelCardReport,
    GeofenceBasedReport,
    GeofenceReport,
    IdleReport,
    IgnitionReport,
    InputReport,
    JBDiagnosticsReport,
    JBDTCReport,
    JBSummaryReport,
    LandmarkBasedReport,
    LandmarkCrewReport,
    LandmarkEngineHoursReport,
    LandmarkReport,
    LandmarkSummaryReport,
    LatitudeLongitudeReport,
    LocationReport,
    MaintenanceItemsReport,
    MileageReport,
    MileageSummaryReport,
    DriverMileageSummaryReport,
    MilesPerGallonReport,
    MovingReport,
    StateMileageSummariesReport,
    NonReportingReport,
    OdometerReport,
    PingReport,
    ServiceItemsReport,
    SimpleReport,
    SmartOneCUtilizationReport,
    SpeedReport,
    StartStopIdleReport,
    StateMileageReport,
    StopHistoryReport,
    StopReport,
    StopSummaryReport,
    SummaryReport,
    TemperatureReport,
    TimesheetReport,
    UnitInfoReport,
    UtilizationReport,
    VehicleMaintenanceItemsReport,
    VehServiceItemsReport,
    ChatHistoryReportV2,
    StopHistoryReportV2,
    StopSummaryReportV2,
    FSMCombinedReportV2,
    HourOfUseReport,
    StateMileageCrossingReport
} from './.components/reports'
//styles
import 'Modules/reports/views/main/main.scss';
import { NotificationManager } from 'Components/Notifications';
//views
import ReportSetup from './.components/reportSetup';
import ModalLandMark from "Modules/fleet/components/ModalLandMark";
import { getDataForEntityTags } from 'Core/data/Helpers';
import { AddButton } from "Components/Table";
import { TableButtons } from "Components/uiControls/Table";
import moment from "moment/moment";
import Tag, { TagsGroup } from "../../../../../../components/Tag";
import { Button, Input, InputSearch, Pill } from "Components";
import { MenuComponent } from "Components/Menu";
import Modal, { ModalBody, ModalFooter } from "Components/Modal";
import Form from "../../../../../../components/Form";
import { usePagination } from "Components/uiControls/Table/hooks";
import ReportListFilters from "Modules/reports/views/main/components/reportsTab/.components/reportListFilters";
import { getReportTypeName } from "Modules/reports/.globals/helpers";
import VirtualizedTable from "Components/uiControls/VirtualizedTable";

const ReportsTab = (props) => {
    const { messages } = props.intl;
    const divRef = useRef();
    const modalDeletePinnedRef = useRef();
    const tabRef = useRef();
    const tableRef = useRef();
    const reports = useSelector(state => state.reportsMainRTRedux.reports);
    const loadingGlobalReport = useSelector(state => state.reportsMainRTRedux.loadingGlobalReport);
    const showMessageReport = useSelector(state => state.reportsMainRTRedux.showMessageReport);
    const messageReport = useSelector(state => state.reportsMainRTRedux.messageReport);
    const statusMessageReport = useSelector(state => state.reportsMainRTRedux.statusMessageReport);
    const titleReport = useSelector(state => state.reportsMainRTRedux.titleReport);
    const recentlyGenerated = useSelector(state => state.reportsMainRTRedux.recentlyGenerated);
    const pinnedReports = useSelector(state => state.reportsMainRTRedux.pinnedReports);
    const updateReport = useSelector(state => state.reportsMainRTRedux.updateReport);
    const fieldUpdated = useSelector(state => state.reportsMainRTRedux.fieldUpdated);
    const newReport = useSelector(state => state.catalogsRedux.newReport)
    const loadingRecentlyReports = useSelector(state => state.reportsMainRTRedux.loadingRecentlyReports);
    const loadingPinnedReports = useSelector(state => state.reportsMainRTRedux.loadingPinnedReports);
    const drivers = useSelector(state => state.catalogsRedux.drivers);
    const driversIgnoreStatus = useSelector(state => state.catalogsRedux.driversIgnoreStatus);
    const services = useSelector(state => state.catalogsRedux.maintenanceServicesItems);

    const units = useSelector(state => state.catalogsRedux.unitsList);
    const { timeZone } = useSelector(state => state.securityRedux.user);
    const user = useSelector(state => state.securityRedux.user);

    const reportTypeOptions = useReportTypeOptions(user);

    const lang = useSelector(state => state.commomRedux.language);
    const [openReportsTooLargeModal, setOpenReportsTooLargeModal] = useState(false);
    const loadingDeleteReportLog = useSelector(state => state.reportsMainRTRedux.loadingDeleteReportLog);
    const deletedReportLog = useSelector(state => state.reportsMainRTRedux.deletedReportLog);
    const [searchText, setSearchText] = useState("");

    const [selectedItems, setSelectedItems] = useState([]);
    const [exclusionsItems, setExclusionsItems] = useState([])
    const [selectedAll, setSelectedAll] = useState(false);

    const [openDelete, setOpenDelete] = useState(false);
    const [rowsToDelete, setRowsToDelete] = useState([]);
    const [rowToExcludes, setRowToExcludes] = useState([])

    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [resetCache, setResetCache] = useState(0);
    const [currentFilterReport, setCurrentFilterReport] = useState({ id: TYPE_REPORT_RECENT, label: 'Recent', value: true });

    const [width, setWidth] = useState(0)
    const [numberTab, setNumberTab] = useState(0)

    const dispatch = useDispatch();
    const frmDeleteSchedule = useRef();
    const pagination = usePagination(tableRef, searchReportsLog);
    const exportResult = useSelector(state => state.commomRedux.exportResult);
    const momentFormat = GetFormatForMomentTwoCharacters(user.dateformat)

    const [dataModalPinned, setDataModalPinned] = useState({
        report: {},
        isOpenModal: false
    });

    const tags = useSelector(state => state.catalogsRedux.tags);
    const haveUntagged = tags?.find(t => t?.id == -1) ? true : false;
    const customFieldsReports = useReportCustomFields(haveUntagged)
    const pageCountRef = useRef(1)
    const dateFilter = new Date();

    useEffect(() => {
        // Effect to load the initial information in the home
        dispatch(searchReportsLog(currentFilterReport.id, "", rowsPerPage, 1, dateFilter));

        return () => {
            dispatch(clearRedux("REPORTS_MAIN_RT"))
            dispatch(clearRedux("REPORTS_MODAL_EXTRA_TIME"))
            // Clear previous rep.handleChangeTab
            dispatch(clearRedux("CATALOG_REDUX", { newReport: null }))
            dispatch(clearRedux("CATALOG_REDUX", { urlsDownload: [] }))
        }
    }, []);

    useEffect(() => {
        dispatch(catalogLoad(["drivers", "driversIgnoreStatus", "maintenanceServicesItems"]));
    }, [])

    useEffect(() => {
        if (numberTab == 0) {
            setResetCache(prev => prev + 1)
        }
    }, [numberTab])

    useEffect(() => {
        if (newReport) {
            if (newReport.status === "FAILED") {
                NotificationManager.error(
                    messages['reportsMainRT_exportReportMessageFailed'],
                    messages['error'],
                    3000,
                    null,
                    null,
                    'error'
                );
            } else if (newReport.exportFile || newReport.status === "SUCCESS") {
                // when a new report is added through the catalog, we show the notification, reload the table and the home items
                dispatch(searchReportsLog(currentFilterReport.id, "", rowsPerPage, 1, dateFilter));
                if (!newReport.exportFile) {
                    NotificationManager.success(
                        messages['reportsMainRT_exportReportMessageSuccess'],
                        messages['success'],
                        3000,
                        null,
                        null,
                        'success',
                    );
                }
            }

            const timer = setTimeout(() => {
                if (!newReport.exportFile && newReport.status === "SUCCESS") {
                    dispatch(reportsVerifyStateReport(newReport.reportInstanceId, newReport.report, true));
                }
                dispatch(clearRedux("CATALOG_REDUX", { newReport: null }))
            }, 500);
            return () => clearTimeout(timer);

        }
    }, [JSON.stringify(newReport)]);

    useEffect(() => {
        const countReports = Object.values(reports).length;
        if (countReports > 0) {
            let selectedTab = tabRef.current?.selectedTab || 0
            if (selectedTab > 0 || countReports != selectedTab) {
                //tabRef.current?.onChangeSelectedTab(countReports)
                setNumberTab(countReports)
            }
        }
    }, [Object.values(reports).length]);

    useEffect(() => {

        if (deletedReportLog) {
            NotificationManager.success(
                messages['success'],
                messages['reportsMainST_deleteReport'],
                3000,
                null,
                null,
                'warning',
                null
            );
            dispatch(searchReportsLog(currentFilterReport.id, "", rowsPerPage, 1, dateFilter));
            setSelectedItems([]);
            setExclusionsItems([]);
            setOpenDelete(false)
            tableRef.current?.setSelectedItems([]);
            setRowsToDelete([]);
        }
    }, [deletedReportLog]);


    useEffect(() => {
        if (exportResult) {
            NotificationManager.info(
                messages['exportInProcessBody'],
                messages['exportInProcessTitle'],
                4000,
                null,
                null,
                'info',
                null
            );
            dispatch(clearRedux("COMMON", { exportResult: null, exportName: null }));
        }
    }, [exportResult]);

    useEffect(() => {
        if (showMessageReport) {
            // Show message when report is generated
            if (statusMessageReport === 'SUCCESS') {
                NotificationManager.success(
                    null,
                    null,
                    3000,
                    null,
                    null,
                    null,
                    titleReport,
                    messageReport
                );
                dispatch(searchReportsLog(currentFilterReport.id, "", rowsPerPage, 1, dateFilter));
            } else {
                NotificationManager.error(
                    null,
                    null,
                    3000,
                    null,
                    null,
                    null,
                    titleReport,
                    messageReport
                );
            }
            dispatch(clearRedux('REPORTS_MAIN_RT', { showMessageReport: false, messageReport: '', titleReport: '', statusMessageReport: '' }))
        }
    }, [showMessageReport])

    useEffect(() => {
        if (updateReport?.data.id) {

            // this event is triggered occurs when a report is updated either by name or becomes one of the favorites

            if (updateReport.actionName == 'reportName') {
                NotificationManager.success(
                    messages['reportsMainRT_updatedReport'],
                    messages['success'],
                    3000,
                    null,
                    null,
                    'success',
                );
            } else {
                NotificationManager.success(
                    messages['reportsMainRT_addBeen'],
                    messages['success'],
                    3000,
                    null,
                    null,
                    'success',
                );
            }


            if (fieldUpdated === FIELD_NAME_IS_STARRED) {
                // If the report is starred just update the starred List
                dispatch(searchReportsLog(currentFilterReport.id, "", 99, null, dateFilter));
                setSelectedItems([]);
                setExclusionsItems([])
                setOpenDelete(false)
                tableRef.current?.setSelectedItems([]);
                setRowsToDelete([]);
            } else if (fieldUpdated === FIELD_NAME_REPORT) {
                // If the name changed update all three lists
                dispatch(searchReportsLog(currentFilterReport.id, "", rowsPerPage, 1, dateFilter));
            }
            dispatch(clearRedux("REPORTS_MAIN_RT", { fieldUpdated: '', updateReport: null }))
        }
    }, [updateReport?.data]);

    useEffect(() => {
        setWidth(divRef.current?.clientWidth)
        function handleWindowResize() {
            setWidth(divRef.current?.clientWidth);
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [divRef.current?.clientWidth]);

    const pinnedReportHandler = (pin, id, createdOn) => {
        // function to upload the starred field
        if (id) {
            dispatch(reportUpdateField(id, pin, FIELD_NAME_IS_STARRED, createdOn))
        }
    }

    const renderIconReportReady = (number, isIconRight) => {
        // Render icons based on the tab it is on
        return (
            <h3 className={cx("number-reports-ready-ctn", "reports-no-margin", isIconRight ? "icon-right-home-report" : "")}>
                <span className={cx("number-reports-ready", isIconRight ? "span-icon-right-home-report" : "")}>
                    {number || 0}
                </span>
            </h3>
        )
    }
    const onChangeTab = (tabNumber) => {
        setNumberTab(tabNumber)
    }
    //change label item report module
    const selectedDriverUnitsLabel = (label) => {
        switch (label) {
            case 'unitGroupId':
                return messages['Unit_Groups']
            case 'deviceId':
                return messages['units']
            case 'driverId':
                return messages['drivers']
            case 'driverGroupId':
                return messages['driver_groups']
            default:
                return messages['units']
        }
    }

    const TimeframeLabel = (label) => {

        switch (label) {
            case 'Today':
            case 'Hoy':
                return messages['date_today']

            case 'Yesterday':
            case 'Ayer':
                return messages['date_yesterday']

            case 'This Week':
            case 'Esta semana':
                return messages['date_this_week']

            case 'Last Week':
            case 'La semana pasada':
                return messages['date_last_week']

            case 'This Quarter':
            case 'Este trimestre':
                return messages['date_this_quarter']

            case 'Last Quarter':
            case 'El trimestre pasado':
                return messages['date_last_quarter']

            case 'Custom':
            case 'Personalizado':
                return messages['date_custom']

            case 'This Month':
            case 'Este Mes':
                return messages['date_this_month']

            case 'Last Month':
            case 'El mes pasado':
                return messages['date_last_month']

            default:
                return messages['date_today']
        }

    }

    const renderItems = (parameters) => {
        if (currentFilterReport?.value) {
            return <TableViewRecently {...parameters}></TableViewRecently>;
        } else {
            return <TableViewPinned {...parameters}></TableViewPinned>;
        }
    }

    const headers = (items) => {
        if (currentFilterReport?.value) {
            return <TableViewRecentlyHeader showCheck={items?.length} items={items}></TableViewRecentlyHeader>;
        } else {
            return <TableViewPinnedHeader showCheck={items?.length} items={items}></TableViewPinnedHeader>;
        }
    }
    const renderColumnReports = (title, items, total) => {
        const recentFilter = { id: TYPE_REPORT_RECENT, label: messages['reportsRecentlyGenerated'], value: true }
        const pinnedFilter = { id: TYPE_REPORT_STARRED, label: messages['reportsPinnedReports'], value: false }
        return (
            <>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6} sm={1} style={{ display: "flex" }}>
                        <TableButtons
                            messages={messages}
                            tableRef={tabRef}
                            {...pagination}
                            buttons={['export']}
                            onExport={(type) => {
                                const headers = columns.filter(x => x.show && x.export).map(x => {
                                    return {
                                        header: x.title,
                                        column: x.key,
                                        functionString: x.functionString
                                    }
                                }) || [];

                                dispatch(sendGenericExport(
                                    "getReportLog",
                                    type,
                                    `
                                            query getReportLog ($openSearch: String, $limit: Int, $offset: Int, $category: CategoryReport, $endDate: AWSDateTime) {
                                                getReportLog (openSearch: $openSearch, limit: $limit, offset: $offset, category: $category, endDate: $endDate) {
                                                    items {
                                                        id 
                                                        reportName
                                                        reportType
                                                        format
                                                        outputUrl
                                                        reportStatus
                                                        createdOn
                                                        params
                                                        delayedOn
                                                        scheduled
                                                        dataResultPath
                                                        reportLogId
                                                        dateRangeFilter
                                                        runFor
                                                        reportTypeFilter
                                                        timeframeName
                                                        formatFilter
                                                        createOnFilter
                                                        filterType
                                                        status
                                                    }
                                                    total
                                                    offset
                                                }
                                              }
                                            `,
                                    { openSearch: searchText, category: currentFilterReport.id, endDate: dateFilter },
                                    headers,
                                    { unit: 'px', size: 'A4', orientation: 'landscape', fontSize: 10 }
                                ));
                            }}
                        />
                        <AddButton icon="delete" tooltip={messages['delete']}
                            onClick={() => {
                                if (selectedItems?.length > 0) {
                                    setOpenDelete(true)
                                    setRowsToDelete(selectedItems.concat())
                                } else {
                                    setOpenDelete(true)
                                    setRowToExcludes(exclusionsItems.concat())
                                }
                            }}
                            className={((selectedItems.length > 0 || exclusionsItems.length > 0) || selectedAll) ? 'icon-button' : 'disabled hidden-delete-schedule'}
                        />
                    </Grid>

                    <Grid item xs={6} sm={2} style={{ display: "flex" }}>
                        <Grid item xs key={1}>
                            <Pill
                                key={1}
                                textField="label"
                                item={recentFilter}
                                outlined={recentFilter?.id !== currentFilterReport.id}
                                onClick={() => {
                                    setCurrentFilterReport(recentFilter)
                                    setRowsPerPage(rowsPerPage)
                                    setSearchText('')
                                    setSelectedItems([])
                                    setExclusionsItems([])
                                    setSelectedAll(false)
                                    setOpenDelete(false)
                                    pageCountRef.current = 1
                                    setRowsToDelete([]);
                                    dispatch(searchReportsLog(recentFilter?.id, "", rowsPerPage, 1, dateFilter));
                                }}
                                style={{ border: '0', width: '100%' }}
                                colorField="default"
                                colorSelection="66bb6a"
                            />
                        </Grid>
                        <Grid item xs key={2}>
                            <Pill
                                key={2}
                                textField="label"
                                item={pinnedFilter}
                                outlined={pinnedFilter?.id !== currentFilterReport.id}
                                onClick={() => {
                                    setCurrentFilterReport(pinnedFilter)
                                    setRowsPerPage(rowsPerPage)
                                    setSearchText('')
                                    setSelectedItems([])
                                    setExclusionsItems([])
                                    setSelectedAll(false)
                                    setOpenDelete(false)
                                    setRowsToDelete([]);
                                    pageCountRef.current = 1
                                    dispatch(searchReportsLog(pinnedFilter?.id, "", rowsPerPage, 1, dateFilter));
                                }}
                                style={{ border: '0', width: '100%' }}
                                colorField="default"
                                colorSelection="66bb6a"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} className="mb-10 text-right">
                        <InputSearch
                            onSearch={onChangeSearchText}
                            onSearchWhenFinish={(txt) => {
                                // getDriverTasks(txt);
                            }}
                            searchWhenFinishedTyping={true}
                            searchText={searchText}
                        />
                    </Grid>
                </Grid>

                <Grid item sm={12} style={{ overflowX: "auto" }}>
                    {(headers(items))}
                    <VirtualizedTable
                        container={tableRef}
                        renderItems={renderItems}
                        isNextPageLoading={currentFilterReport.value ? loadingRecentlyReports : loadingPinnedReports}
                        handleNewPageLoad={() => {
                            const page = pageCountRef.current + 1
                            dispatch(searchReportsLog(currentFilterReport.id, searchText, rowsPerPage, page, dateFilter));
                            pageCountRef.current = page
                        }}
                        data={items || []}
                        total={total}
                        noDataMessage={currentFilterReport.value ? messages['noGeneratedReport_defaultNewMessage'] : messages['thereAreNoPinned']}
                        containerClass={`container-virtualized-reports-home ${messages['at'] === "a las" && "container__table_large"}`}
                        resetCache={resetCache}
                    />
                </Grid>

                <ModalDeletePinned
                    state={dataModalPinned}
                    setState={setDataModalPinned}
                />

                <Modal
                    size={'small'}
                    open={openDelete}
                    handleClose={handleCloseDelete}
                    title={`${messages['delete']}`}
                >
                    <ModalBody>
                        <div style={{ padding: '0px 25px' }}>
                            <Form ref={frmDeleteSchedule} onValidSubmit={() => {

                                const ids = rowsToDelete?.map((item) => item.reportLogId)
                                const idsExclusions = rowToExcludes?.map((item) => item.reportLogId) ?? []
                                if (recentFilter?.id === currentFilterReport.id || pinnedFilter?.id === currentFilterReport.id) {
                                    setSelectedAll(false)
                                    if (exclusionsItems.length > 0) {
                                        dispatch(
                                            deleteReportLog(
                                                rowToExcludes[0].id,
                                                rowToExcludes[0].createdOn,
                                                rowToExcludes[0].reportLogId,
                                                ['-99'],
                                                currentFilterReport.id,
                                                idsExclusions
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            deleteReportLog(
                                                rowsToDelete[0]?.id,
                                                rowsToDelete[0]?.createdOn,
                                                rowsToDelete[0]?.reportLogId,
                                                selectedAll ? ['-99'] : (total === rowsToDelete.length ? ['-99'] : ids),
                                                currentFilterReport.id,
                                                idsExclusions
                                            )
                                        )
                                    }
                                } else {
                                    dispatch(reportUpdateFieldBatch(selectedItems, '', FIELD_NAME_IS_STARRED))
                                }
                            }}>
                                <p style={{ overflowWrap: 'anywhere' }}>
                                    {rowsToDelete?.length > 0 ?
                                        (selectedAll
                                            ? messages['reportsMainST_deleteAllReportsMessage']
                                            : messages['reportsMainST_deleteReportsMessage']) :
                                        messages['reportsMainST_deleteReportsMessage']}
                                </p>
                            </Form>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className='container-buttons-delete-modal-reports'>
                            <Button
                                id={'btn-cancel-schedule'}
                                type="button"
                                onClick={handleCloseDelete}
                                className="btn-cancel"
                            >
                                {messages['cancel']}
                            </Button>
                            <Button
                                id={'btn-schedule'}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    frmDeleteSchedule.current.submit();
                                }}
                                className="btn-blue"
                                disabled={loadingDeleteReportLog}
                            >
                                {messages['delete']}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <ReportListFilters messages={messages} />
            </>
        )
    }

    const ReportStatus = ({ report, loading, reports, onChangeTab }) => {
        const id = `reportStatus-${uuidv4()}`
        let uIParams = formatParams(report.params, 'UIParams');

        if (!uIParams.filters) {
            uIParams = JSON.parse(uIParams)
        }

        const category = formatParams(report.params, 'category');
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

        const copyReportToSetup = (report = null, params = null) => {
            let uIParams = formatParams(report.params, 'UIParams');

            if (!uIParams.filters) {
                uIParams = JSON.parse(uIParams)
            }

            if (params) uIParams = { ...uIParams, ...params }
            dispatch(reportsMainShowReportSetup())
            dispatch(reportsMainSetFormDataReportSetup(uIParams))
        }

        const checkIfReportIsOpen = () => {
            const index = Object.values(reports).findIndex((reportOpened, key) => {
                if (reportOpened.dataResultPath === report.dataResultPath) {
                    return true;
                }
                return false;
            })

            if (index >= 0) {
                onChangeTab(index + 1)
                return true;
            }
        }
        if (currentFilterReport.value) {
            switch (report.reportStatus) {
                case 'fail': {
                    return (
                        <strong>{messages['videoClipRequest_failed']}</strong>
                    )
                }
                case 'inProgress': {
                    return (
                        <span>{messages['videoClipRequest_inProgress']}</span>
                    )
                }
                case 'completed': {
                    const menuScheduleOptions = [messages["reportsPinReports"], report.format === 'json' ? messages["generatedReport_view"] : messages["generatedReport_download"], messages['generatedReport_copy'], messages['reportsView_schedule']];
                    let newUrl = "";
                    let newLink;
                    if (report.outputUrl) {
                        let url = "";
                        try {
                            url = JSON.parse(JSON.stringify(report.outputUrl));
                            url = JSON.parse(url);
                        } catch (e) {
                        }
                        newLink = Array.isArray(url) ? url[0]?.url : report.outputUrl
                        if (newLink) {
                            newUrl = newLink?.includes("http") ? newLink : `https://${newLink}`
                        }
                    }
                    return (
                        <>
                            <div className={isSafari ? 'btn-report-status-safari' : 'btn-report-status'}
                                style={{ "--position": (currentFilterReport.value ? 19 : 18) + "px" }}>
                                {
                                    report.format === 'json' ? (
                                        <Button
                                            onClick={() => {
                                                const reportIsOpen = checkIfReportIsOpen();
                                                if (!reportIsOpen) {
                                                    const allDrivers = category?.includeInactiveDrivers ? driversIgnoreStatus : drivers;
                                                    getReport(report.id, report.reportName, report.format, report.outputUrl, uIParams, false, report.dataResultPath, report.createdOn, report.id, category?.entities,
                                                        messages, dispatch, reportTypeOptions, allDrivers, units, lang)
                                                }

                                            }}
                                            id="generate-report-btn"
                                            className="btn-blue"
                                        >
                                            {messages["generatedReport_view"]}
                                        </Button>
                                    ) : (
                                        <>
                                            {   newLink ?
                                                (<Button
                                                    onClick={() => {
                                                        window.open(newUrl, "_blank");
                                                    }}
                                                    id="generate-report-btn"
                                                    className="btn-blue"
                                                >
                                                    {messages["generatedReport_download"]}
                                                </Button>) :
                                                (<Button
                                                    id="generate-report-btn"
                                                    className="btn-disabled"
                                                >
                                                    {messages["reportSetup_noDataFound"]}
                                                </Button>)
                                            }
                                        </>
                                    )
                                }
                                <MenuComponent
                                    options={newLink ? menuScheduleOptions:menuScheduleOptions.filter(value => value !== messages["generatedReport_download"])}
                                    icon={'more_vert'}
                                    className="icon-menu-item-report icon-menu-home-report"
                                    getContentAnchorEl={null}
                                    elementId={id}
                                    onClick={(type) => {
                                        if (type === messages["generatedReport_view"]) {
                                            const reportIsOpen = checkIfReportIsOpen();
                                            if (!reportIsOpen) {
                                                const allDrivers = category?.includeInactiveDrivers ? driversIgnoreStatus : drivers;
                                                getReport(report.id, report.reportName, report.format, report.outputUrl, uIParams, false, report.dataResultPath, report.createdOn, report.id, category?.entities,
                                                    messages, dispatch, reportTypeOptions, allDrivers, units, lang)
                                            }
                                        } else if (type === messages["generatedReport_download"]) {
                                            window.open(newUrl, "_blank");
                                        } else if (type === messages["reportsPinReports"]) {
                                            dispatch(reportUpdateField(report.id, new Date().toISOString(), FIELD_NAME_IS_STARRED, report.createdOn))
                                        } else if (type === messages["generatedReport_copy"]) {
                                            copyReportToSetup(report);
                                        } else if (type === messages['reportsView_schedule']) {
                                            const dateRange = formatParams(report.params, 'dateRange');
                                            const schedule = {
                                                "name": report.reportName,
                                                "reportType": report.reportType,
                                                "category": {
                                                    "field": category.field,
                                                    "includeInactiveDrivers": category.includeInactiveDrivers,
                                                    "value": [],
                                                    "categoryName": uIParams.filters.filterEntity,
                                                    "dataResultFilter": category.dataResultFilter,
                                                    "entities": uIParams.filters.filterArray.map(data => {
                                                        data.parentId = null
                                                        return data
                                                    }),
                                                },
                                                frequency: 'daily',
                                                monthDays: [0],
                                                "integerRanges": [],
                                                "dateRange": dateRange,
                                                "weekDays": [],
                                                "subscribers": [],
                                                "format": report.format,
                                                "stringValues": [],
                                                "filterValues": [],
                                                "deliveryOn": [],
                                                "selectAll": uIParams.isAll,
                                            }

                                            props.handleChangeTab(1)
                                            dispatch(getPreSchedule(schedule))
                                            dispatch(reportsScheduleShowReportSetup())
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                            <span id={id} className="span-report-status">{messages['reportsReady_ready']}</span>
                        </>
                    )
                }
                case 'pending': {
                    return (
                        <span style={{ color: "gray" }}>{messages['videoClipRequest_inProgress']}</span>
                    )
                }
                case 'processFailed': {
                    return (
                        <span>{messages['videoClipRequest_failed']}</span>
                    )
                }
            }
        } else {
            const exportOptions = formatParams(report.params, 'exportOptions');
            const menuScheduleOptions = [messages["generatedReport_edit"], messages['delete'], messages['reportsView_schedule']];
            return (
                <>
                    <div className={isSafari ? 'btn-report-status-safari' : 'btn-report-status'}
                        style={{ "--position": (currentFilterReport.value ? 19 : 18) + "px" }}>
                        {
                            <Button
                                onClick={() => {
                                    const allDrivers = category?.includeInactiveDrivers ? driversIgnoreStatus : drivers;
                                    getReport(report.id, report.reportName, report.format, report.outputUrl, uIParams, true, report.dataResultPath, report.createdOn, report.id, category?.entities,
                                        messages, dispatch, reportTypeOptions, allDrivers, units, lang)
                                }}
                                id="generate-report-btn"
                                className="btn-blue"
                            >
                                {messages["reportSetup_getReport"]}
                            </Button>
                        }
                        <MenuComponent
                            options={menuScheduleOptions}
                            icon={'more_vert'}
                            className="icon-menu-item-report icon-menu-home-report"
                            elementId={id}
                            onClick={(type) => {
                                if (type === messages["delete"]) {
                                    setDataModalPinned({
                                        report,
                                        isOpenModal: true
                                    })
                                } else if (type === messages["edit"]) {
                                    copyReportToSetup(report, { edit: { id: report.id, createdOn: report.createdOn } });
                                } else if (type === messages['reportsView_schedule']) {
                                    const dateRange = formatParams(report.params, 'dateRange');
                                    const schedule = {
                                        "name": report.reportName,
                                        "reportType": report.reportType,
                                        "category": {
                                            "field": category.field,
                                            "includeInactiveDrivers": category.includeInactiveDrivers,
                                            "value": [],
                                            "categoryName": uIParams.filters.filterEntity,
                                            "dataResultFilter": category.dataResultFilter,
                                            "entities": uIParams.filters.filterArray.map(data => {
                                                data.parentId = null
                                                return data
                                            }),
                                        },
                                        frequency: 'daily',
                                        monthDays: [0],
                                        "integerRanges": [],
                                        "dateRange": dateRange,
                                        "weekDays": [],
                                        "subscribers": [],
                                        "format": report.format,
                                        "stringValues": [],
                                        "filterValues": [],
                                        "deliveryOn": [],
                                        "selectAll": uIParams.isAll,
                                    }
                                    props.handleChangeTab(1)
                                    dispatch(getPreSchedule(schedule))
                                    dispatch(reportsScheduleShowReportSetup())
                                }
                            }}
                            getContentAnchorEl={null}
                            disabled={loading}
                        />
                    </div>
                    <span id={id} >{exportOptions.format.fileFormat === 'json' ? 'Web' : exportOptions.format.fileFormat.toLocaleUpperCase()}</span>
                </>
            )
        }
    }

    const ModalDeletePinned = (props) => {

        const { state, setState } = props

        const { isOpenModal, report } = state;

        const setIsOpenModal = (value) => {
            setState({
                ...dataModalPinned,
                isOpenModal: value
            })
        }

        return (
            <Modal
                size={'small'}
                open={isOpenModal}
                handleClose={() => setIsOpenModal(false)}
                title={`${messages['delete']}`}
            >
                <ModalBody>
                    <div style={{ padding: '0px 25px' }}>
                        <Form ref={modalDeletePinnedRef}
                            onValidSubmit={() => { dispatch(reportUpdateField(report.id, '', FIELD_NAME_IS_STARRED, report.createdOn)) }}
                        >
                            <p style={{ overflowWrap: 'anywhere' }}>
                                {messages['reportReportType_DeletePinned']}
                            </p>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className='container-buttons-delete-modal-reports'>
                        <Button
                            id={'btn-cancel-schedule'}
                            type="button"
                            onClick={() => setIsOpenModal(false)}
                            className="btn-cancel"
                        >
                            {messages['cancel']}
                        </Button>
                        <Button
                            id={'btn-schedule'}
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                modalDeletePinnedRef.current.submit();
                                setDataModalPinned({
                                    report: {},
                                    isOpenModal: false
                                })
                            }}
                            className="btn-blue"
                        >
                            {messages['delete']}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }

    const TableViewRecentlyHeader = (data) => {
        return (
            <Grid container xs={12} item direction={"row"} className={`container-headers-reports-home ${messages['at'] === "a las" && "container__table_large"} ${(data.items?.length ? '' : 'nodata')}`}>
                <Grid item sm={0.8} className={'reports-cut-text'}>
                    <Checkbox
                        style={{ color: "black", zIndex: 0 }}
                        className={'checkbox-table-select'}
                        checked={selectedAll}
                        disabled={!data.showCheck}
                        indeterminate={selectedItems.length > 0 || exclusionsItems.length > 0}
                        onChange={() => {
                            if (selectedItems.length > 0 && !selectedAll) {
                                setSelectedItems([])
                                setExclusionsItems([])
                                setSelectedAll(false)
                            } else {
                                setSelectedAll(!selectedAll)
                                setExclusionsItems([])
                            }
                        }}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        size="small"
                    />
                </Grid>
                <Grid item sm={1.7} className={'reports-cut-text'}>
                    {messages['reportsMainST_report']}
                </Grid>
                <Grid item sm={0.8} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectViewBy']}
                </Grid>
                <Grid item sm={1.4} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectRunFor']}
                </Grid>
                <Grid item sm={4.3} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectTimeframe']}
                </Grid>
                <Grid item sm={2} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectCreated']}
                </Grid>
                <Grid item sm={1} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectStatus']}
                </Grid>
            </Grid>
        )
    }
    const TableViewRecently = ({ index, style, content, id, previousItem }) => {

        let uIParams = formatParams(content.params, 'UIParams');
        const dateRange = formatParams(content.params, 'dateRange');

        if (!uIParams.filters) {
            uIParams = JSON.parse(uIParams)
        }
        const entity = uIParams.filters?.filterArray[0];
        const textAll = getEntitiesAllType(uIParams, messages);
        const labelEntity = (uIParams.allEntities && uIParams.allEntities.length) ? uIParams.allEntities[0]?.name : entity.name;
        const count = uIParams.isAll ? textAll : uIParams.filters.filterArray.length;
        const name = `${count} ${entity.id < 1 ? getNameUnitDriverTag(labelEntity ? labelEntity : 'Tag', messages, true) : getNameUnitDriverTag(labelEntity ? labelEntity : 'Tag', messages, true)}${(uIParams.filters.filterArray.length <= 1 && !uIParams.isAll) ? '' : messages['reportSetup_finallyPlural']}`;
        const localTimeStart = moment.utc(dateRange.GTE).local().format();
        const localTimeEnd = moment.utc(dateRange.LTE).local().format();
        const start = formatDatetime(localTimeStart, `${momentFormat || "M/DD/YY"} h:mm A`, timeZone).split(' ');
        const end = formatDatetime(localTimeEnd, `${momentFormat || "M/DD/YY"} h:mm A`, timeZone).split(' ');

        const localTime = moment.utc(content.createdOn).local().format();

        const useCheckReportTitle = content?.params && (services && customFieldsReports) ? getInfoReportFilter(content, { services, customFieldsReports }) : '';

        const getEditLabel = (row, isSafari, size) => {
            return <div className="item-name-content-report-home" id={isSafari ? 'item-name-content-report-home-safari' : 'item-name-content-report-home'}
                style={{ "--text": (size) + "px" }}>
                <Tooltip placement="left" title={row?.reportName} >
                    <div className={"report-content-editable-label-home"}>
                        <Input
                            id="name"
                            type={"inline-report"}
                            placeholder={messages['mapLandmarkCreateEditLandmark_name']}
                            value={row?.reportName || ""}
                            showHoverIcon
                            showHoverIconIndex={row.reportLogId}
                            onChange={(newLabel) => {
                                if (newLabel.trim())
                                    dispatch(reportUpdateField(row.id, newLabel, FIELD_NAME_REPORT, row.createdOn))
                            }}
                            labelSave={messages['save']}
                            labelCancel={messages['cancel']}
                            required
                            requiredMessage={messages['required_field']}
                            maxLength={50}
                        />
                    </div>
                </Tooltip>
            </div>;
        }

        return (
            <div
                className="listItem homeItemReport content-item-reports-home"
                style={style}
                id={id}
            >
                <Grid container direction={"column"} className={"container-row-reports-home"}>
                    <Grid container item direction={"row"} style={{ paddingTop: "1px", paddingBottom: "1px" }}>
                        <Grid item className={"report-ctn-text"} sm={0.8}>
                            <Checkbox
                                style={{ color: "black", zIndex: 0 }}
                                className={'checkbox-table-select'}
                                checked={
                                    selectedItems.findIndex(x => x.reportLogId === content.reportLogId || x === content) > -1 || (
                                        selectedAll && !(exclusionsItems.findIndex(x => x.reportLogId === content.reportLogId || x === content) > -1))
                                }
                                onChange={() => {

                                    let newSelectedItems = selectedAll ? exclusionsItems.concat() : selectedItems.concat();

                                    let indexCheck = newSelectedItems.findIndex(x => x.reportLogId === content.reportLogId || x === content)

                                    if (indexCheck > -1) {
                                        newSelectedItems.splice(indexCheck, 1)
                                    } else {
                                        newSelectedItems.push(content)
                                    }

                                    selectedAll ? setExclusionsItems(newSelectedItems) : setSelectedItems(newSelectedItems)

                                }}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                                size="small"
                            />
                            {useCheckReportTitle !== '' && (
                                <div className='report-table-info-tooltip'>
                                    <Button
                                        type="icon"
                                        icon="info"
                                        placement="right"
                                        tooltip={useCheckReportTitle}
                                        tooltiptype="report"
                                    />
                                </div>
                            )}
                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={1.7}>
                            <div id="content-edi-report-name">
                                {getEditLabel(content, false, currentFilterReport.value ? (width > 960 ? 16 : 16.5) : 16)}
                                <div id={'temp-edit-report-name'}>
                                    <p>.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={0.8}>

                            <span className="">{`${getEntityType(uIParams, messages)}`}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text report-tags-group"} sm={1.4}>

                            {count !== textAll && entity.name === 'Tag' ? (
                                <>
                                    <Grid item sm>
                                        <div onClick={() => {
                                            dispatch(reportsListShowReportFilter(name, uIParams.filters.filterArray))
                                        }}>
                                            <TagsGroup
                                                options={(uIParams.filters.filterArray.map(data => {
                                                    data.color = data.color ? data.color : '000000'
                                                    return data
                                                }))}
                                                nameField="label"
                                                colorField="color"
                                            />
                                        </div>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="show-more-text"
                                        onClick={() => {
                                            dispatch(reportsListShowReportFilter(name, count === textAll ? uIParams.allEntities : uIParams.filters.filterArray))
                                        }}
                                    >
                                        <span
                                            className="home-report-title">{name}</span>
                                    </div>
                                </>
                            )}

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={4.3}>

                            {start[0] === end[0] ? (
                                <span>{`${TimeframeLabel(dateRange.timeframeName)} 
                                    (${start[0]} ${start[1]} ${start[2]} - ${end[1]} ${end[2]})`}
                                </span>
                            ) : (
                                <span>{`${TimeframeLabel(dateRange.timeframeName)} 
                                    (${start[0]} ${start[1]} ${start[2]} 
                                    - 
                                    ${end[0]} ${end[1]} ${end[2]})`}
                                </span>
                            )}

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={2}>

                            <span>{formatDatetime(localTime, `${momentFormat || "M/DD/YY"} h:mm A`, timeZone)}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={1} id={"column-status-reports-home"}>

                            <ReportStatus report={content} loading={loadingRecentlyReports} reports={reports} onChangeTab={onChangeTab}></ReportStatus>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const TableViewPinnedHeader = (data) => {
        return (
            <Grid container xs={12} item direction={"row"} className={`container-headers-reports-home ${messages['at'] === "a las" && "container__table_large"} ${data.items?.length ? '' : 'nodata'}`}>
                <Grid item sm={0.8} className={'reports-cut-text'}>
                    <Checkbox
                        style={{ color: "black", zIndex: 0 }}
                        className={'checkbox-table-select'}
                        checked={selectedAll}
                        disabled={!data.showCheck}
                        indeterminate={selectedItems.length > 0 || exclusionsItems.length > 0}
                        onChange={() => {
                            if (selectedItems.length > 0 && !selectedAll) {
                                setSelectedItems([])
                                setExclusionsItems([])
                                setSelectedAll(false)
                            } else {
                                setSelectedAll(!selectedAll)
                                setExclusionsItems([])
                            }
                        }}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        size="small"

                    />
                </Grid>
                <Grid item sm={2.5} className={'reports-cut-text'}>
                    {messages['reportsMainST_reportName']}
                </Grid>
                <Grid item sm={2.5} className={'reports-cut-text'}>
                    {messages['reportsMainST_reportType']}
                </Grid>
                <Grid item sm={1.5} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectViewBy']}
                </Grid>
                <Grid item sm={1.5} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectRunFor']}
                </Grid>
                <Grid item sm={2.2} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectTimeframe']}
                </Grid>
                <Grid item sm={1} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectStatus']}
                </Grid>
            </Grid>
        )
    }

    const TableViewPinned = ({ index, style, content, id, previousItem }) => {

        let uIParams = formatParams(content.params, 'UIParams');

        if (!uIParams.filters) {
            uIParams = JSON.parse(uIParams)
        }

        const dateRange = formatParams(content.params, 'dateRange');

        const entity = uIParams.filters.filterArray[0];
        const textAll = getEntitiesAllType(uIParams, messages);
        const count = uIParams.filters.filterArray.length <= 1 ?
            (entity.id < 1 ? textAll : (uIParams.filters.filterArray.length))
            : uIParams.filters.filterArray.length;
        const name = `${count} ${entity.id < 1 ? getEntitiesType(uIParams, messages) : getEntityType(uIParams, messages)}${uIParams.filters.filterArray.length <= 1 ? '' : messages['reportSetup_finallyPlural']}`;

        const useCheckReportTitle = content?.params && (services && customFieldsReports) ? getInfoReportFilter(content, { services, customFieldsReports }) : '';

        const getEditLabel = (row, isSafari, size) => {
            return <div className="item-name-content-report-home" id={isSafari ? 'item-name-content-report-home-safari' : 'item-name-content-report-home'}
                style={{ "--text": (size) + "px" }}>
                <Tooltip placement="left" title={row?.reportName} >
                    <div className={"report-content-editable-label-home"}>
                        <Input
                            id="name"
                            type={"inline-report"}
                            placeholder={messages['mapLandmarkCreateEditLandmark_name']}
                            value={row?.reportName || ""}
                            showHoverIcon
                            showHoverIconIndex={row.reportLogId}
                            onChange={(newLabel) => {
                                if (newLabel.trim())
                                    dispatch(reportUpdateField(row.id, newLabel, FIELD_NAME_REPORT, row.createdOn))
                            }}
                            required
                            requiredMessage={messages['required_field']}
                            maxLength={50}
                        />
                    </div>
                </Tooltip>
            </div>;
        }

        return (
            <div
                className="listItem homeItemReport content-item-reports-home"
                style={style}
                id={id}
            >
                <Grid container direction={"column"} className={"container-row-reports-home"}>
                    <Grid container item direction={"row"} style={{ paddingTop: "1px", paddingBottom: "1px" }}>
                        <Grid item className={"report-ctn-text"} sm={0.8}>
                            <Checkbox
                                style={{ color: "black", zIndex: 0 }}
                                className={'checkbox-table-select'}
                                checked={
                                    selectedItems.findIndex(x => x.reportLogId === content.reportLogId || x === content) > -1 || (
                                        selectedAll && !(exclusionsItems.findIndex(x => x.reportLogId === content.reportLogId || x === content) > -1))
                                }
                                onChange={() => {

                                    let newSelectedItems = selectedAll ? exclusionsItems.concat() : selectedItems.concat();

                                    let indexCheck = newSelectedItems.findIndex(x => x.reportLogId === content.reportLogId || x === content)

                                    if (indexCheck > -1) {
                                        newSelectedItems.splice(indexCheck, 1)
                                    } else {
                                        newSelectedItems.push(content)
                                    }

                                    selectedAll ? setExclusionsItems(newSelectedItems) : setSelectedItems(newSelectedItems)

                                }}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                                size="small"
                            />
                            {useCheckReportTitle !== '' && (
                                <div className='report-table-info-tooltip'>
                                    <Button
                                        type="icon"
                                        icon="info"
                                        placement="right"
                                        tooltip={useCheckReportTitle}
                                        tooltiptype="report"
                                    />
                                </div>
                            )}
                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={2.5}>
                            <div id="content-edi-report-name">
                                {getEditLabel(content, false, currentFilterReport.value ? (width > 960 ? 16 : 16.5) : 16)}
                                <div id={'temp-edit-report-name'}>
                                    <p>.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={2.5}>

                            <span className="">{`${getReportTypeName(content.reportType, messages)}`}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={1.5}>

                            <span className="">{`${getEntityType(uIParams, messages)}`}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text report-tags-group"} sm={1.5}>

                            {count !== textAll && entity.name === 'Tag' ? (
                                <>
                                    <Grid item xs>
                                        <div onClick={() => {
                                            dispatch(reportsListShowReportFilter(name, uIParams.filters.filterArray))
                                        }}>
                                            <TagsGroup
                                                options={(uIParams.filters.filterArray.map(data => {
                                                    data.color = data.color ? data.color : '000000'
                                                    return data
                                                }))}
                                                nameField="label"
                                                colorField="color"
                                            />
                                        </div>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="show-more-text"
                                        onClick={() => {
                                            dispatch(reportsListShowReportFilter(name, count === textAll ? uIParams.allEntities : uIParams.filters.filterArray))
                                        }}
                                    >
                                        <span
                                            className="home-report-title">{name}</span>
                                    </div>
                                </>
                            )}

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={2.2}>

                            <span>{TimeframeLabel(dateRange.timeframeName)}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={1} id={"column-status-reports-home"}>
                            <ReportStatus report={content} loading={loadingPinnedReports} reports={reports} onChangeTab={onChangeTab}></ReportStatus>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const onChangeSearchText = (newSearchText) => {
        setSearchText(newSearchText)
        pageCountRef.current = 1
        setSelectedItems([])
        setExclusionsItems([])
        dispatch(searchReportsLog(currentFilterReport?.id, newSearchText, rowsPerPage, 1, dateFilter));
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const copyReportToSetup = (report = null, params = null) => {
        let uIParams = formatParams(report.params, 'UIParams');


        if (!uIParams.filters) {
            uIParams = JSON.parse(uIParams)
        }

        if (params) uIParams = { ...uIParams, ...params }
        dispatch(reportsMainShowReportSetup())
        dispatch(reportsMainSetFormDataReportSetup(uIParams))
    }

    const columns = getColumns(messages, currentFilterReport.value ? loadingRecentlyReports : loadingPinnedReports, timeZone, dispatch, reportTypeOptions, units, currentFilterReport, TimeframeLabel, width, copyReportToSetup, props.handleChangeTab, user.dateformat);



    const copyReportToSetupFromReportInstance = (report = null, params = null) => {
        dispatch(reportsMainShowReportSetup())
        dispatch(reportsMainSetFormDataReportSetup(report))
    }

    const refClearReportSetup = useRef(null)
    const openReportSetup = () => {
        dispatch(reportsMainShowReportSetup())
        refClearReportSetup.current()
    }

    const scheduleReport = (report) => {
        const schedule = {
            "name": messages['reportsMainRT_' + report.type],
            "reportType": report.type,
            "category": {
                "field": report.filters.filterType,
                "includeInactiveDrivers": report.filters.includeInactiveDrivers,
                "value": [],
                "categoryName": report.filters.filterEntity,
                "dataResultFilter": false,
                "entities": report.filters.filterArray.map(data => {
                    data.parentId = null
                    data.type = data.name
                    return data
                }),
            },
            frequency: 'daily',
            monthDays: [0],
            "integerRanges": [],
            "dateRange": {
                "fields": [
                    "unitTime"
                ],
                "GTE": report.filters.startRange,
                "LTE": report.filters.endRange,
                "timeframeName": "Custom",
                "reportTimeRanges": []
            },
            "weekDays": [],
            "subscribers": [],
            "format": report.exportType,
            "stringValues": [],
            "filterValues": [],
            "deliveryOn": [],
            "selectAll": report.isAll,
        }
        props.handleChangeTab(1)
        dispatch(getPreSchedule(schedule))
        dispatch(reportsScheduleShowReportSetup())
    }
    return (
        <div>
            <Tabs
                ref={tabRef}
                type="square"
                id="tab-square-reports-background"
                onCloseTab={(index) => {
                    //remove from array the selected report from tab
                    const reportIdValues = Object.values(reports);
                    //remove from array the selected report from tab
                    let reportId = reportIdValues[index - 1].id
                    dispatch(reportsMainRemoveReport(reportId))
                }}
                scrollButtons
                tab={numberTab}
                onChange={(e) => {
                    setNumberTab(e)
                }}
                showTooltip
                labelTooltip={`${messages['reportsMainRT_more']}`}
                positionTooltip="bottom"

            >
                <TabContainer label={messages['generatedReport_home']} index={0} renderAlways={{ classNameCustom: 'reports' }}
                    className="reports-tab-container-items">
                    <Grid
                        ref={divRef}
                        container
                        spacing={1}
                    >
                        {currentFilterReport.value ? renderColumnReports(messages['reportsRecentlyGenerated_title'], recentlyGenerated.items, recentlyGenerated.total) :
                            renderColumnReports(messages['reportsPinnedReports_title'], pinnedReports.items, pinnedReports.total)}
                    </Grid>
                </TabContainer>
                {

                    Object.values(reports).map((report, key) => {
                        let iconType = report?.filters?.filterType === "deviceId" || report?.filters?.filterType === "unitGroupId" ? 'time_to_leave' : 'person'
                        const paramsReport = {
                            messages,
                            report,
                            user,
                            copyReportToSetup: copyReportToSetupFromReportInstance,
                            openReportSetup,
                            scheduleReport,
                            isActive: numberTab === (key + 1)
                        }
                        return (
                            <TabContainer label={getReportTypeName(report.type, messages) || formatTabLabel(report.type, key + 1)} index={key + 1} key={key} closeable renderAlways
                                className="no-padding-top-report">
                                {
                                    report.type === "detailedReport" &&
                                    <DetailedReport {...paramsReport} />
                                }
                                {
                                    report.type === "actReport" &&
                                    <ActReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverFormsReportV2" &&
                                    <DriverFormsReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "driverStatusReportV2" &&
                                    <DriverStatusReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "summaryReport" &&
                                    <SummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "idleReport" &&
                                    <IdleReport {...paramsReport} />
                                }
                                {
                                    report.type === "ignitionReport" &&
                                    <IgnitionReport {...paramsReport} />
                                }
                                {
                                    report.type === "mileageReport" &&
                                    <MileageReport {...paramsReport} />
                                }
                                {
                                    report.type === "speedReport" &&
                                    <SpeedReport {...paramsReport} />
                                }
                                {
                                    report.type === "stopReport" &&
                                    <StopReport {...paramsReport} />
                                }
                                {
                                    report.type === "startStopIdleReport" &&
                                    <StartStopIdleReport {...paramsReport} />
                                }
                                {
                                    report.type === "simpleReport" &&
                                    <SimpleReport {...paramsReport} />
                                }
                                {
                                    report.type === "advancedSummaryReport" &&
                                    <AdvancedSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "mileageSummaryReport" &&
                                    <MileageSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverMileageSummaryReport" &&
                                    <DriverMileageSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "landmarkReport" &&
                                    <LandmarkReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverSafetyReport" &&
                                    <DriverSafetyReport {...paramsReport} />
                                }
                                {
                                    report.type === "alertLogReport" &&
                                    <AlertLogReport {...paramsReport} />
                                }
                                {
                                    report.type === "stateMileageReport" &&
                                    <StateMileageReport {...paramsReport} />
                                }
                                {
                                    report.type === "utilizationReport" &&
                                    <UtilizationReport {...paramsReport} />
                                }
                                {
                                    report.type === "latitudeLongitudeReport" &&
                                    <LatitudeLongitudeReport {...paramsReport} />
                                }
                                {
                                    report.type === "eventReport" &&
                                    <EventReport {...paramsReport} />
                                }
                                {
                                    report.type === "timesheetReport" &&
                                    <TimesheetReport {...paramsReport} />
                                }
                                {
                                    report.type === "pingReport" &&
                                    <PingReport {...paramsReport} />
                                }
                                {
                                    report.type === "landmarkBasedReport" &&
                                    <LandmarkBasedReport {...paramsReport} />
                                }
                                {
                                    report.type === "odometerReport" &&
                                    <OdometerReport {...paramsReport} />
                                }
                                {
                                    report.type === "geofenceBasedReport" &&
                                    <GeofenceBasedReport {...paramsReport} />

                                }
                                {
                                    report.type === "landmarkCrewReport" &&
                                    <LandmarkCrewReport {...paramsReport} />
                                }

                                {
                                    report.type === "landmarkEngineHoursReport" &&
                                    <LandmarkEngineHoursReport {...paramsReport} />
                                }
                                {
                                    report.type === "nonReportingReport" &&
                                    <NonReportingReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverTimesheetReport" &&
                                    <DriverTimesheetReport {...paramsReport} />
                                }
                                {
                                    report.type === "UnitInfoReport" &&
                                    <UnitInfoReport {...paramsReport} />
                                }
                                {
                                    report.type === "movingReport" &&
                                    <MovingReport {...paramsReport} />
                                }
                                {
                                    report.type === "landmarkSummaryReport" &&
                                    <LandmarkSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "locationReport" &&
                                    <LocationReport {...paramsReport} />
                                }
                                {
                                    report.type === "geofenceReport" &&
                                    <GeofenceReport {...paramsReport} />
                                }
                                {
                                    report.type === "jbDiagnosticsReport" &&
                                    <JBDiagnosticsReport {...paramsReport} />
                                }
                                {
                                    report.type === "diagnosticParametersReport" &&
                                    <DiagnosticParametersReport {...paramsReport} />
                                }
                                {
                                    report.type === "diagnosticIndicatorsReport" &&
                                    <DiagnosticIndicatorsReport {...paramsReport} />
                                }
                                {
                                    report.type === "jbSummaryReport" &&
                                    <JBSummaryReport {...paramsReport} />
                                }

                                {
                                    report.type === "temperatureReport" &&
                                    <TemperatureReport {...paramsReport} />
                                }
                                {
                                    report.type === "jbdtcReport" &&
                                    <JBDTCReport {...paramsReport} />
                                }
                                {
                                    report.type === "chatHistoryReport" &&
                                    <ChatHistoryReport {...paramsReport} />
                                }
                                {
                                    report.type === "engineHoursReport" &&
                                    <EngineHoursReport {...paramsReport} />
                                }
                                {
                                    report.type === "stopHistoryReport" &&
                                    <StopHistoryReport {...paramsReport} />
                                }
                                {
                                    report.type === "fsmTimeCardReport" &&
                                    <FSMTimeCardReport {...paramsReport} />
                                }
                                {
                                    report.type === "timeCardReportV2" &&
                                    <TimeCardReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "stopSummaryReport" &&
                                    <StopSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "formStopSummaryReport" &&
                                    <FormStopSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "formStopSummaryReportV2" &&
                                    <FormStopSummaryReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "driverStatusReport" &&
                                    <FsmDriverStatusReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverFormsReport" &&
                                    <DriverFormsReport {...paramsReport} />
                                }
                                {
                                    report.type === "smartOneCUtilizationReport" &&
                                    <SmartOneCUtilizationReport {...paramsReport} />
                                }
                                {
                                    report.type === "fuelCardReport" &&
                                    <FuelCardReport {...paramsReport} />
                                }
                                {
                                    report.type === "deviceSummaryInfoReport" &&
                                    <DeviceSummaryInfoReport {...paramsReport} />
                                }
                                {
                                    report.type === "milesPerGallonReport" &&
                                    <MilesPerGallonReport {...paramsReport} />
                                }
                                {
                                    report.type === "vehicleMaintenanceItemsReport" &&
                                    <VehicleMaintenanceItemsReport {...paramsReport} />
                                }
                                {
                                    report.type === "maintenanceItemsReport" &&
                                    <MaintenanceItemsReport {...paramsReport} />
                                }
                                {
                                    report.type === "vehServiceItemsReport" &&
                                    <VehServiceItemsReport {...paramsReport} />
                                }
                                {
                                    report.type === "serviceItemsReport" &&
                                    <ServiceItemsReport {...paramsReport} />
                                }
                                {
                                    report.type === "inputReport" &&
                                    <InputReport {...paramsReport} />
                                }
                                {
                                    report.type === "fsmCombinedReport" &&
                                    <FSMCombinedReport {...paramsReport} />
                                }
                                {
                                    report.type === "chatHistoryReportV2" &&
                                    <ChatHistoryReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "stopHistoryReportV2" &&
                                    <StopHistoryReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "fsmCombinedReportV2" &&
                                    <FSMCombinedReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "stopSummaryReportV2" &&
                                    <StopSummaryReportV2 {...paramsReport} />
                                }
                                {
                                  report.type === "hourOfUseReport" &&
                                  <HourOfUseReport {...paramsReport}/>
                                }
                                {
                                    report.type === "stateMileageCrossingReport" &&
                                    <StateMileageCrossingReport {...paramsReport}/>
                                }
                                {
                                    report.type === "stateMileageSummariesReport" &&
                                    <StateMileageSummariesReport {...paramsReport}/>
                                }
                            </TabContainer>
                        )
                    })
                }
            </Tabs>

            <ReportSetup
                messages={messages}
                refClearReportSetup={refClearReportSetup}
            />
            {
                loadingGlobalReport &&
                <Loading overlay show />
            }
            <ModalLandMark />

            {
                openReportsTooLargeModal &&
                <ReportTooLarge open={openReportsTooLargeModal} onClose={() => { setOpenReportsTooLargeModal(false); }} />
            }
        </div>
    )
}

const deleteComma = (string, type) => {
    if (string && type == 'csv') {
        return String(string).replaceAll(',', ' - ')
    }
    return string;
}

const formatParams = (param, select) => {
    if (param) {
        const formatParams = JSON.parse(param);
        return formatParams[select] ? formatParams[select] : JSON.parse(formatParams)[select]
    }
}

const getColumns = (messages, loading, timeZone, dispatch, reportTypeOptions, units, currentFilterReport, timeframeLabel, width, copyReportToSetup, handleChangeTab, dateformat) => {
    const momentFormat = GetFormatForMomentTwoCharacters(dateformat)
    const addColumnType = (currentFilterReport.value) ? [] :
        [{
            key: 'reportType',
            show: true,
            sort: false,
            title: currentFilterReport.value ? messages['reportsMainST_selectViewBy'] : messages['reportsMainST_reportType'],
            export: true,
            render: null,
            functionString: 'return row?.reportTypeFilter'
        }];

    const addColumnCreated = (currentFilterReport.value) ?
        [{
            key: 'selectCreated',
            show: true,
            sort: false,
            title: messages['reportsMainST_selectCreated'],
            export: true,
            render: null,
            exportValue: (row) => {
                const localTime = moment.utc(row.createdOn).local().format();
                return formatDatetime(localTime, `${momentFormat || "M/DD/YY"} h:mm A`, timeZone);
            },
            functionString: 'return row?.createOnFilter'
        }] : [];


    return [
        {
            key: 'name',
            show: true,
            sort: false,
            title: currentFilterReport.value ? messages['reportsMainST_report'] : messages['reportsMainST_reportName'],
            width: '18%',
            export: true,
            render: null,
            functionString: 'return row?.reportName'
        },
        ...addColumnType,
        {
            key: 'selectViewBy',
            show: true,
            sort: false,
            title: messages['reportsMainST_selectViewBy'],
            export: true,
            render: null,
            functionString: 'return row?.filterType'
        },
        {
            key: 'selectRunFor',
            show: true,
            sort: false,
            title: messages['reportsMainST_selectRunFor'],
            export: true,
            render: null,
            functionString: `
            if (!['completed'].includes(row.reportStatus)){
                let filters = JSON.parse(row.params);
                filters = JSON.parse(filters);
                return row?.runFor !== '' ? row?.runFor : ('All ' + filters.category.entities[0].type + 's')
            } else {
                const filters = JSON.parse(row.params);
                return row?.runFor !== '' ? row?.runFor : ('All ' + filters.category.entities[0].type + 's')
            }
            `
        },
        {
            key: 'selectTimeframe',
            show: true,
            sort: false,
            title: messages['reportsMainST_selectTimeframe'],
            export: true,
            render: null,
            functionString: `return ${currentFilterReport.value ? 'row?.dateRangeFilter' : 'row?.timeframeName'}`
        },
        ...addColumnCreated,
        {
            key: 'selectStatus',
            show: true,
            sort: false,
            title: currentFilterReport.value ? messages['reportsMainST_selectStatus'] : messages['reportsMainST_delivery'],
            export: true,
            render: null,
            exportValue: (row) => {
                return row.reportStatus;
            },
            functionString: `return ${currentFilterReport.value ? 'row?.status' : 'row?.formatFilter'}`
        }
    ];
}

const getReport = (id, name, viewOption, url, UIParams, generateReportAgain, dataResultPath, createdOn, userId, entities, messages, dispatch, reportTypeOptions, drivers, units, lang) => {
    if (typeof UIParams === 'string') {
        try {
            UIParams = JSON.parse(UIParams)
        } catch (e) {
        }
    }
    if ((viewOption === 'csv' || viewOption === 'pdf') && !generateReportAgain) {
        const newLink = Array.isArray(url)
            ? url[0]?.url
            : getURLFromItem(url)
        if (newLink) {
            const newUrl = newLink.includes("http") ? newLink : `https://${newLink}`;
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = newUrl;
            a.click();
            document.body.removeChild(a);
        } else {
            // if the file does not have url
            NotificationManager.error(
                messages['reportsMainRT_exportReportMessageFailedNoData'],
                messages['error'],
                3000,
                null,
                null,
                'error'
            );
        }
    } else {

        // generate the report again with the same parameters
        // the end date could change in cases like: This Quarter, this week, this month
        if (UIParams?.timeframeName != "Custom") {
            const newDates = changeDate(UIParams.timeframeName, {})
            UIParams.filters.endDate = newDates.endDate?.utc().format();
            UIParams.filters.startDate = newDates.startDate?.utc().format();
        }
        // get entity label from  filterArray or entities
        const newEntities = getEntities(UIParams.filters.filterArray, entities);
        const allTag = newEntities?.find(tag => {
            return tag.id == "-99";
        });

        const includeAllTag = !!allTag;
        let allEntities = []

        if (includeAllTag) {
            allEntities = newEntities?.map(e => e.type).includes('Tag') ? UIParams.allEntities : getDataForEntityTags(UIParams.filters.filterEntity, drivers, units)
        }

        dispatch(reportsMainGenerateReport(
            allEntities,
            includeAllTag,
            UIParams.timeZone,
            UIParams.filters.filterEntity,
            UIParams.type, //reportType
            UIParams.filters.filterType, //entity to filter
            newEntities,
            UIParams.filters.startRange,
            UIParams.filters.endRange,
            UIParams.filters.otherFilters,
            UIParams.export,
            UIParams.exportType,
            UIParams.sendByEmail,
            UIParams.emailsTo,
            reportTypeOptions.getNameReportType(UIParams.type) || name || UIParams.name || '',
            UIParams.categoryName || '',
            UIParams.timeframeName || '',
            UIParams.delayed,
            UIParams.excludeEmptyTables,
            generateReportAgain ? null : dataResultPath,
            createdOn,
            userId,
            UIParams.reportTimeRanges,
            UIParams.durationFormat || '',
            UIParams.filters.includeInactiveDrivers,lang
        ));

    }
}

const getEntities = (filterArray, entities) => {
    if (!filterArray) {
        return entities;
    }

    if (!entities) {
        return filterArray;
    }

    return filterArray.map(item => {
        const newEntity = entities.find(entity => (item?.id == entity?.id && item?.type == entity?.type))
        if (newEntity) {
            return {
                ...newEntity,
                ...item
            }
        }
        return item;
    })
}

const getURLFromItem = (urlItem) => {
    if (urlItem === "[]") {
        return ""
    }
    return urlItem;
}


import { useIntl } from 'react-intl';

export const getInfoReportFilter = (report, { services, customFieldsReports }) => {
    let uIParams = formatParams(report.params, 'UIParams');
    if (!uIParams.filters) {
        uIParams = JSON.parse(uIParams)
    }
    const filters = uIParams.filters.otherFilters

    const { allForms: forms, landmarks, geofences, landmarkViewOptions, driverViewOptions, selectEventTypeOptions, geofenceViewOptions } = customFieldsReports;

    const { messages } = useIntl()

    const getInfoForm = (filters) => {
        let infos = [];

        const label = driverViewOptions?.filter(data => data.id == filters.formView)[0]?.label || ""
        infos.push(
            { title: `Form Filter = `, value: `${label}` },
        )

        if (filters.formView === "single") {
            const label = forms?.filter(data => data.id == filters.form)[0]?.name || ""
            infos.push(
                { title: `Form = `, value: `${label}` },
            )
        }

        return infos;
    }

    let infos = [];
    let label = ""
    switch (report.reportType) {
        case 'speedReport':
            infos.push(
                { title: 'reportReportType_seedOr', value: `${filters.speed} mph` },
                { title: 'reportReportType_overPosted', value: `${filters.speedLimitDelta} mph` },
            )
            break;
        case 'stopReport': case 'movingReport': case 'idleReport':
            infos.push(
                { title: 'reportReportType_minutes', value: `${filters.minute}` },
            )
            break;
        case 'formStopSummaryReportV2':
        case 'formStopSummaryReport':
            infos.push(
                { title: 'reportReportType_minutes', value: `${filters.minute}` },
                ...getInfoForm(filters)
            )
            break;
        case 'driverFormsReport':
            infos.push(...getInfoForm(filters))
            break;
        case 'driverFormsReportV2':
            infos.push(...getInfoForm(filters))
            break;
        case 'eventReport':
            label = selectEventTypeOptions?.filter(data => data.id == filters.event)[0]?.label || ""
            infos.push(
                { title: 'reportReportType_eventFilter', value: `${label}` },
            )
            break;
        case 'serviceItemsReport':
            label = services?.filter(data => data.id == filters.event)[0]?.name || ""
            infos.push(
                { title: 'reportReportType_serviceItemFilter', value: `${label}` },
            )
            break;

        case 'landmarkBasedReport': case 'landmarkSummaryReport':
            label = landmarkViewOptions?.filter(data => data.id == filters.landmarkBaseView)[0]?.label || ""
            infos.push(
                { title: 'reportReportType_landmarkView', value: `${label}` },
            )
            if (filters.landmarkBaseView === "single") {
                const label = landmarks?.filter(landmark => landmark.id == filters.landmark)[0]?.name || ""
                infos.push(
                    { title: 'reportReportType_landmark', value: `${label}` },
                )
            }
            else if (filters.landmarkBaseView === "tags") {
                const label = filters?.landmarkTags.label || ""
                infos.push(
                    { title: 'reportReportType_tags', value: `${label}` },
                )
            }
            break;
        case 'geofenceBasedReport':
            label = geofenceViewOptions?.filter(data => data.id == filters.geofenceBaseView)[0]?.label || ""
            infos.push(
                { title: 'reportReportType_geofenceView', value: `${label}` },
            )
            if (filters.geofenceBaseView === "single") {
                const label = geofences?.filter(geofence => geofence.id == filters.geofence)[0]?.name || ""
                infos.push(
                    { title: 'reportReportType_geofence', value: `${label}` },
                )
            }
            else if (filters.geofenceBaseView === "tags") {
                const label = filters?.geofenceTags.label || ""
                infos.push(
                    { title: 'reportReportType_tags', value: `${label}` },
                )
            }
            break;

    }

    if (infos.length > 0) {
        return (
            <>
                {
                    infos.map((info, index) => (
                        <p key={`${index}-${info}`} className="info-speed-tooltip"> {
                            messages[`${info.title}`]
                        }<strong>{info.value}</strong></p>
                    ))
                }
            </>
        )
    }
    else return '';
}
export default injectIntl(ReportsTab);
