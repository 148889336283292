import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    error: null,
    unit: null,
    open: false,
    loading: false,
    units: [],
    pairing: null,
    unPairing: null
};

const route = 'modules/settings/views/cameraManage/components/edit/redux/slice'

export const cameraEditManageSearchUnits = createAsyncThunk('cameraManage/edit/cameraEditManageSearchUnits', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query searchUnits ($conditions: [UnitSearchInput], $sort: [UnitSortInput], $limit: Int, $offset: Int) {
                data: searchUnits (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        esn
                        imei
                        linkedUnitId
                        linkedUnit {
                            id
                            label
                            esn
                            imei
                        }
                        deviceModel {
                            manufacturer {
                                name
                            }
                        }
                        linkedByUnitId
                        linkedByUnit {
                            id
                            label
                        }
                    }
                    total
                }
            }
            `,
            {
                conditions: [],
                limit: 1000,
                offset: 0,
                sort: {
                    field: "label",
                    order: "ASC"
                }
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data || { items: [], total: 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'cameraManageSearchUnits');
        return rejectWithValue(exc)
    }
});

export const cameraEditBulkPairing = createAsyncThunk('cameraManage/edit/cameraEditBulkPairing', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `mutation bulkPairing ($pairing: [BulkPairingInput]) {
                result: bulkPairing (pairing: $pairing) {
                    unitIdCamera
                    unitIdGps
                    errorMessage
                    errorCode
                }
            }`,
            {
                pairing: payload
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.result
    } catch (exc) {
        ExceptionManager(exc, route, 'cameraEditBulkPairing');
        return rejectWithValue(exc)
    }
});

export const cameraEditBulkUnPairing = createAsyncThunk('cameraManage/edit/cameraEditBulkUnPairing', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `mutation bulkUnpairing ($unitIds: [Int]) {
                result: bulkUnpairing (unitIds: $unitIds)
            }`,
            {
                unitIds: payload
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return { result: response?.result }
    } catch (exc) {
        ExceptionManager(exc, route, 'cameraEditBulkUnPairing');
        return rejectWithValue(exc)
    }
});

const settingsCameraManagePairingRedux = createSlice({
    name: 'cameraManage/edit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        openModalEditPairing(state, action) {
            return { ...state, open: action?.payload?.open, unit: action?.payload?.unit }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //cameraEditManageSearchUnits
        builder.addCase(cameraEditManageSearchUnits.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(cameraEditManageSearchUnits.fulfilled, (state, action) => {
            state.units = action.payload.items || []
            state.loading = false;
        });
        builder.addCase(cameraEditManageSearchUnits.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        //cameraEditBulkPairing
        builder.addCase(cameraEditBulkPairing.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(cameraEditBulkPairing.fulfilled, (state, action) => {
            state.pairing = action.payload
            state.loading = false;
        });
        builder.addCase(cameraEditBulkPairing.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        //cameraEditBulkUnPairing
        builder.addCase(cameraEditBulkUnPairing.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(cameraEditBulkUnPairing.fulfilled, (state, action) => {
            state.unPairing = action.payload
            state.loading = false;
        });
        builder.addCase(cameraEditBulkUnPairing.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = settingsCameraManagePairingRedux;

// Extract and export each action creator by name
export const { reset, openModalEditPairing } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;