//imports
import {
  LOGIN,
  LOGIN_SM,
  LOGIN_PM,
  LOGIN_RESULT,
  LOGIN_CONFIRM_CLOSE,
  LOGIN_CONFIRM,
  LOGIN_CONFIRM_RESULT,
  CHECK_LOGIN_USER_BY_CODE,
  CHECK_MIGRATE_USER,
  CHECK_MIGRATE_USER_RESULT,
  GET_LOGIN_PAGE,
  GET_LOGIN_PAGE_RESULT,
  NEW_PASSWORD_REQUIRED_RESULT,
  TERMS_AND_CONDITION,
  UPDATE_TERMS_AND_CONDITION
} from 'Redux/actionTypes';

/* ACTIONS */

export const login = (username, password, navigate) => ({
  type: LOGIN,
  payload: { username, password, navigate },
});


export const LoginWithPM = (username, token, navigate) => ({
  type: LOGIN_PM,
  payload: { username, token, navigate }
});

export const loginSM = (sm, navigate) => ({
  type: LOGIN_SM,
  payload: { sm, navigate }
});

export const loginResult = (cognitoValues, showConfirm, error) => ({
  type: LOGIN_RESULT,
  payload: { cognitoValues, showConfirm, error }
});

export const loginConfimrClose = () => ({
  type: LOGIN_CONFIRM_CLOSE,
  //payload: { user, showConfirm, error }
});

export const loginConfirm = (username, code, cognitoValues, navigate, messages) => ({
  type: LOGIN_CONFIRM,
  payload: { username, code, cognitoValues, navigate, messages }
});

export const checkLoginUserByCode = (code, navigate) => ({
  type: CHECK_LOGIN_USER_BY_CODE,
  payload: {
    code,
    navigate
  }
});

export const loginConfirmResult = (error) => ({
  type: LOGIN_CONFIRM_RESULT,
  payload: { error }
});

export const checkMigrate = (username, navigate) => ({
  type: CHECK_MIGRATE_USER,
  payload: { username, navigate }
});

export const checkMigrateResult = (showPassword) => ({
  type: CHECK_MIGRATE_USER_RESULT,
  payload: { showPassword }
});

export const getLoginPage = (fileKey, preview, language, navigate) => ({
  type: GET_LOGIN_PAGE,
  payload: { fileKey, preview, language, navigate }

});

export const getLoginPageResult = (response) => ({
  type: GET_LOGIN_PAGE_RESULT,
  payload: { response }
});

export const newPasswordRequiredResult = (data) => ({
  type: NEW_PASSWORD_REQUIRED_RESULT,
  payload: data
})

export const termsAndCondition = (username, password, navigate) => ({
  type: TERMS_AND_CONDITION,
  payload: { username, password, navigate },
});

export const updateTermsAndCondition = (username, password) => ({
  type: UPDATE_TERMS_AND_CONDITION,
  payload: { username, password }
});