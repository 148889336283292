export default {
    settingsCameraManageEdit_title: "Camera Paring",
    settingsCameraManageEdit_select: "Select a unit to pair with Camera",
    settingsCameraManageEdit_confirmText: "Are you sure to paring/unparing this Camera?",
    settingsCameraManageEdit_confirmTitle: "Paring/Unparing Camera",
    settingsCameraManageEdit_pairingSuccessSave: "The camera has been successfully paired",
    settingsCameraManageEdit_unPairingSuccessSave: "The camera has been successfully unpaired",

    settingsCameraManageEdit_changeConfirmMessage: "The camera already has a paired unit. Are you sure you want to change the pairing?",
    settingsCameraManageEdit_pairingConfirmMessage: "Are you sure to paring this camera?",
    settingsCameraManageEdit_unpairingConfirmMessage: "Are you sure to unparing this camera?",

    settingsCameraManageEdit_changeConfirmTitle: "Paring/Unparing Camera",
    settingsCameraManageEdit_pairingConfirmTitle: "Paring Camera",
    settingsCameraManageEdit_unpairingConfirmTitle: "Unparing Camera",
    settingsCameraManageEdit_warningMessageFirst: "This unit is currently paired with",
    settingsCameraManageEdit_warningMessageSecond: "If you continue this unit will be removed from",
    settingsCameraManageEdit_warningMessageThird: "and assigned to",
};