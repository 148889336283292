export default {
  vidFleet_wakingCamera: 'Estableciando conexión con la cámara...',
  vidFleet_stopingCamera: 'Finalizando transmisión en vivo...',
  vidFleet_stoppedCamera: 'Transmisión finalizada',
  vidFleet_errorApi: 'No se pudo cargar el video, por favor intente de nuevo.',
  vidFleet_retryMessage: 'El video no se pudo cargar, ya sea porque el servidor o la red fallaron, por favor intente de nuevo.',
  vidFleet_cameraOffline: 'La cámara está desconectada',
  vidFleet_360Message: 'Utilice el mouse para arrastrar y soltar el video',
  vidFleet_errorAccessDenied: 'No se pudo cargar el video, acceso denegado',
  vidFleet_timeout: "Se agotó el tiempo de espera del video en vivo.",
  vidFleet_unableToLiveStream: 'El vehículo está actualmente fuera de línea: no se puede transmitir en vivo',
  vidFleet_uploading: "Cargando fotos en vivo...",
  vidFleet_firmwareNotSupport: "Funcionalidad no suportada",
  vidFleet_sdCardError: "Error de tarjeta SD",
}